<form [formGroup]="orderFormGroup" autocomplete="off" (keydown.enter)="$event.preventDefault()">

  <div class="padding-1">
    <ng-container *ngIf="!isPageLoading">

      <div fxLayout="row" class="padding-top-1" fxHide.xs>
        <div fxFlex>
          <button mat-raised-button class="button-green" (click)="goBack()">
            <mat-icon class="button-icon">chevron_left</mat-icon>
            {{ 'common.back' | translate}}
          </button>
        </div>
      </div>


      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between stretch" fxFill class="padding-top-1" fxLayoutGap="0px">
        <div  fxFlex
              [fxFlexOrder]="(createTransport.serviceType === 'DELIVERY' && isStoreAsDefaultLoading) || (createTransport.serviceType === 'PICKUP' && !isStoreAsDefaultLoading) ? 1 : 3">
          <mat-card class="shop-card">
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon>room</mat-icon>
              </div>
              <mat-card-title *ngIf="createTransport.serviceType === 'DELIVERY' && isStoreAsDefaultLoading">{{ 'orders.details.loading' | translate }}: {{ 'orders.details.shop.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="createTransport.serviceType === 'PICKUP' && isStoreAsDefaultLoading">{{ 'orders.details.unloading' | translate }}: {{ 'orders.details.shop.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="createTransport.serviceType === 'DELIVERY' && !isStoreAsDefaultLoading">{{ 'orders.details.delivery' | translate }}: {{ 'orders.details.shop.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="createTransport.serviceType === 'PICKUP' && !isStoreAsDefaultLoading">{{ 'orders.details.pickup' | translate }}: {{ 'orders.details.shop.title' | translate }}</mat-card-title>
            </mat-card-header>

            <mat-divider></mat-divider>

            <mat-card-content class="padding-top-1">
              <div fxLayout="row">
                  <mat-form-field fxFlex>
                    <input matInput placeholder="{{'settings.shops.search' | translate}}" [matAutocomplete]="auto"
                      formControlName='shop' required>
                  </mat-form-field>
                  <mat-autocomplete #auto="matAutocomplete" [displayWith]="warehouseDisplayFn">
                    <mat-option *ngIf="searching" class="is-loading">
                      <mat-spinner diameter="50"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!searching">
                      <mat-option *ngFor="let warehouse of warehouses" [value]="warehouse">
                        <span>{{ warehouse.name }}</span>
                        <small *ngIf="displayField(DisplayField.WAREHOUSE_ADDRESS)"> | {{warehouse.address}}</small>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
              </div>

              <ng-container *ngIf="selectedWarehouse?.warehouseId">
                <div fxLayout="row" fxLayoutGap="2em" *ngIf="displayField(DisplayField.WAREHOUSE_ADDRESS)">
                  <mat-form-field fxFlex>
                    <input matInput placeholder="{{ 'orders.details.shop.address' | translate }}"
                      formControlName="shopAddress" [attr.disabled]="true">
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="2em" *ngIf="displayField(DisplayField.WAREHOUSE_TIMESLOT)">
                  <mat-form-field fxFlex>
                    <mat-select formControlName="warehouseTimeSlotId"
                      placeholder="{{ (createTransport.serviceType === 'DELIVERY' ? 'model.shop.loading-timeslot' : 'model.shop.unloading-timeslot') | translate }}"
                      required>
                      <mat-option *ngFor="let timeslot of timeSlots" value="{{timeslot.id}}">
                        {{timeslot.from | slice:0:5}} - {{timeslot.to | slice:0:5}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('warehouseTimeSlotId', 'required')">{{'common.error.required' |
                      translate}}
                    </mat-error>
                  </mat-form-field>
                </div>
              </ng-container>

            </mat-card-content>
          </mat-card>
        </div>

        <div fxFlex="35px" fxFlexOrder="2" style="text-align: center; vertical-align: middle;">
          <mat-icon class="swap" (click)="changeServiceType()">swap_horiz</mat-icon>
        </div>

        <div fxFlex [fxFlexOrder]="(createTransport.serviceType === 'DELIVERY' && isStoreAsDefaultLoading) || (createTransport.serviceType === 'PICKUP' && !isStoreAsDefaultLoading) ? 3 : 1">
          <mat-card class="customer-card">
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon>room</mat-icon>
              </div>
              <mat-card-title *ngIf="createTransport.serviceType === 'DELIVERY' && isStoreAsDefaultLoading">{{ 'orders.details.delivery' | translate }}: {{ 'orders.details.customer.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="createTransport.serviceType === 'PICKUP' && isStoreAsDefaultLoading">{{ 'orders.details.pickup' | translate}}: {{ 'orders.details.customer.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="createTransport.serviceType === 'DELIVERY' && !isStoreAsDefaultLoading">{{ 'orders.details.loading' | translate }}: {{ 'orders.details.customer.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="createTransport.serviceType === 'PICKUP' && !isStoreAsDefaultLoading">{{ 'orders.details.unloading' | translate}}: {{ 'orders.details.customer.title' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="padding-top-1">

              <div fxLayout="row" fxLayoutGap="2em">
                <ng-container>
                  <mat-form-field fxFlex>
                    <input matInput placeholder="{{ 'orders.details.customer.search' | translate }}"
                      [matAutocomplete]="customerAutoComplete" formControlName="customer" required>
                  </mat-form-field>
                  <mat-autocomplete #customerAutoComplete="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngIf="searching" class="is-loading">
                      <mat-spinner diameter="50"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!searching">
                      <mat-option *ngFor="let customer of customers" [value]="customer">
                        <span>{{ customer.firstName }} {{ customer.lastName}}</span>
                        <small> | {{customer.address}}</small>
                      </mat-option>
                      <mat-option [disabled]="true">

                        <button mat-raised-button class="margin-right-2 blue-color" style="width:100%"
                          (click)="openCreateCustomerModal()">
                          <mat-icon class="button-icon">person_add</mat-icon>{{ 'customers.add-customer' | translate}}
                        </button>

                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </ng-container>

              </div>

              <ng-container *ngIf="selectedCustomer?.customerId">

                <div fxLayout="row" fxLayoutGap="2em">
                  <mat-form-field fxFlex>
                    <input matInput placeholder="{{ 'orders.details.customer.address' | translate }}"
                      formControlName="customerAddress" [attr.disabled]="true">
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="2em">
                  <mat-form-field fxFlex>
                    <input matInput placeholder="{{ 'orders.details.customer.mobile-phone' | translate }}"
                      formControlName="customerMobilePhone" [attr.disabled]="true"> <!-- required ? /// json -->
                  </mat-form-field>
                  <mat-form-field fxFlex>
                    <input matInput placeholder="{{ 'orders.details.customer.mail' | translate }}"
                      formControlName="customerMail" [attr.disabled]="true"> <!-- required ? /// json -->
                  </mat-form-field>
                </div>

                <div fxLayout="row" fxLayoutGap="2em">
                  <mat-form-field fxFlex>
                    <input matInput placeholder="{{ 'orders.details.customer.comment' | translate }}"
                      formControlName="customerComment" [attr.disabled]="true"> <!-- required ? /// json -->
                  </mat-form-field>
                </div>
              </ng-container>

            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <ng-container *ngIf="selectedCustomer?.customerId && selectedWarehouse?.warehouseId && !displayOrderPanel">
        <button class="add-order-button" mat-fab (click)="clickOnAddOrder()">
          <mat-icon>add</mat-icon>
        </button>
        <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
          <p class="add-order-text">{{ 'orders.details.add-order-text' | translate }}</p>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedCustomer?.customerId && selectedWarehouse?.warehouseId && displayOrderPanel">
        <div fxLayout="row" class="padding-top-1">
          <div fxFlex>
            <mat-card class="transport-card">
              <mat-card-header>
                <div mat-card-avatar>
                  <mat-icon>local_shipping</mat-icon>
                </div>
                {{ 'orders.details.title' | translate }}
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em" style="margin-top: 1em;">
                  <div fxFlex>
                    <div fxLayout="column" fxLayoutGap="1em">
                      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em">
                        <mat-form-field fxFlex
                          *ngIf="(createTransport.serviceType === 'DELIVERY' && displayField(DisplayField.CUSTOMER_DELIVERY_DURATION) ) || (createTransport.serviceType === 'PICKUP' && displayField(DisplayField.CUSTOMER_PICKUP_DURATION) )">
                          <input matInput
                            placeholder="{{ (createTransport.serviceType === 'DELIVERY' ? 'orders.details.delivery-time' : 'orders.details.pickup-time') | translate }}"
                            formControlName="customerDuration" required pattern="[0-9]*">
                          <span matSuffix>min</span>
                          <mat-error *ngIf="hasError('customerDuration', 'required')">{{'common.error.required' |
                            translate}}
                          </mat-error>
                          <mat-error *ngIf="hasError('customerDuration', 'pattern')">{{'common.error.integer' |
                            translate}}
                          </mat-error>
                        </mat-form-field>
                        <mat-form-field fxFlex *ngIf="displayField(DisplayField.WAREHOUSE_DURATION)">
                          <input matInput
                            placeholder="{{ (createTransport.serviceType === 'DELIVERY' ? 'orders.details.loading-time' : 'orders.details.unloading-time') | translate }}"
                            formControlName="warehouseDuration" required pattern="[0-9]*">

                          <span matSuffix>min</span>
                          <mat-error *ngIf="hasError('warehouseDuration', 'required')">{{'common.error.required' |
                            translate}}
                          </mat-error>
                          <mat-error *ngIf="hasError('warehouseDuration', 'pattern')">{{'common.error.integer' |
                            translate}}
                          </mat-error>
                        </mat-form-field>
                      </div>

                      <div fxLayout="row wrap" fxLayoutAlign="flex-start" fxLayoutGap="2em"
                        *ngIf="orderFields.length > 0">

                        <ng-container *ngFor="let orderField of orderFields; let i = index;">

                          <ng-container [ngSwitch]="orderField.type">
                            <mat-form-field fxFlex="0 1 calc(50% - 2em)" *ngSwitchCase="CustomFieldType.STRING">
                              <input matInput placeholder="{{orderField.name}}"
                                formControlName="{{'field'+orderField.id}}"
                                (change)="onChangeStringOrderField(orderField, $event)">
                            </mat-form-field>

                            <mat-form-field fxFlex="0 1 calc(50% - 2em)" *ngSwitchCase="CustomFieldType.DATE">
                              <input matInput [matDatepicker]="picker" placeholder="{{orderField.name}}"
                                formControlName="{{'field'+orderField.id}}"
                                (dateChange)="onChangeDateOrderField(orderField, $event)">
                              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                              <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field fxFlex="0 1 calc(50% - 2em)" *ngSwitchCase="CustomFieldType.INTEGER">
                              <input matInput placeholder="{{orderField.name}}"
                                formControlName="{{'field'+orderField.id}}"
                                (change)="onChangeStringOrderField(orderField, $event)" pattern="[0-9]*">
                              <mat-error *ngIf="hasError('field'+orderField.id, 'pattern')">{{'common.error.integer' |
                                translate}}
                              </mat-error>
                            </mat-form-field>

                            <div fxLayoutAlign="start center" fxFlex="0 1 calc(50% - 2em)"
                              *ngSwitchCase="CustomFieldType.BOOLEAN">
                              <mat-checkbox formControlName="{{'field'+orderField.id}}"
                                (change)="onChangeBooleanOrderField(orderField, $event)">
                                {{orderField.name}}
                              </mat-checkbox>
                            </div>

                            <!-- Custom field Select -->
                            <mat-form-field fxFlex="0 1 calc(50% - 2em)"
                              *ngSwitchCase="orderField.type === CustomFieldType.SELECT_UNIQUE || orderField.type === CustomFieldType.SELECT_MULTIPLE ? orderField.type : ''">
                              <mat-label>{{orderField.name}}</mat-label>
                              <mat-select formControlName="{{'field'+orderField.id}}"
                                [multiple]="orderField.type === CustomFieldType.SELECT_MULTIPLE"
                                (selectionChange)="onChangeSelectOrderField(orderField, $event)">
                                <mat-option *ngIf="orderField.type === CustomFieldType.SELECT_UNIQUE">—</mat-option>
                                <mat-option *ngFor="let option of orderField.selectOptions" [value]="option">
                                  {{ option }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>

                          </ng-container>
                        </ng-container>

                      </div>

                      <mat-form-field fxFlex>
                        <textarea matInput placeholder="{{ 'orders.details.comment' | translate }}"
                          formControlName="comment"></textarea>
                        <mat-error *ngIf="hasError('comment', 'maxlength')">{{'common.error.maxlength' |
                          translate:{"value" : "1000"} }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxFlex>
                    <div fxLayout="row" fxLayoutGap="2em" *ngIf="skills.length > 0 || orderTypes.length > 0">
                      <div fxFlex *ngIf="orderTypes.length > 0 && displayField(DisplayField.ORDER_TYPE)">
                        <mat-form-field fxFlex>
                          <mat-label>{{'orders.details.order-type' | translate}}<span
                              *ngIf="isRequired(DisplayField.ORDER_TYPE)"> *</span></mat-label>
                          <mat-select formControlName="orderTypeId">
                            <mat-option value="-1">{{'orders.details.no-order-type' | translate}}</mat-option>
                            <mat-option *ngFor="let orderType of orderTypes" value="{{orderType.id}}">{{orderType.name}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="hasError('orderTypeId', 'requiredOrderType')">{{'common.error.required' |
                            translate}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                      <div fxFlex *ngIf="skills.length > 0">
                        <mat-form-field fxFlex>
                          <mat-label>{{'orders.details.skill' | translate}}</mat-label>
                          <mat-select formControlName="skillId">
                            <mat-option value="-1">{{'orders.details.no-skill' | translate}}</mat-option>
                            <mat-option *ngFor="let skill of skills" value="{{skill.id}}">{{skill.name}}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </div>

                    <div fxLayout="row wrap" fxLayoutGap="2em" fxLayoutAlign="flex-start">
                      <ng-container *ngFor="let capacityType of capacityTypes">
                        <div fxFlex="0 1 calc(50% - 2em)">
                          <mat-form-field>
                            <mat-label>{{capacityType.name}}</mat-label>
                            <input matInput placeholder="{{capacityType.name}}" formControlName="cap{{capacityType.id}}"
                              pattern="[0-9]*" [required]="capacityType.validationMode === 'REQUIRED'">
                            <span matSuffix>{{capacityType.unit}}</span>
                            <mat-error *ngIf="hasError('cap'+capacityType.id, 'required')">{{'common.error.required' |
                              translate}}
                            </mat-error>
                            <mat-error *ngIf="hasError('cap'+capacityType.id, 'pattern')">{{'common.error.integer' |
                              translate}}
                            </mat-error>
                          </mat-form-field>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>

              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="selectedCustomer?.customerId && selectedWarehouse?.warehouseId && displayOrderPanel && isItemsEnable">
        <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
          <div fxFlex>
            <mat-card class="transport-card">
              <mat-card-header>
                <div mat-card-avatar>
                  <mat-icon>list</mat-icon>
                </div>
                <span>Articles</span>
              </mat-card-header>
              <mat-divider></mat-divider>
              <mat-card-content class="padding-top-1">
                <div fxLayout="column" fxLayoutGap="1em">
                  <ng-container *ngFor="let item of items; let index = index;">
                    <div>
                      {{item.quantity}} x {{item.itemType.label}} ({{item.itemType.reference}})
                    </div>
                    <mat-divider></mat-divider>
                  </ng-container>

                  <mat-form-field fxFlex>
                    <input matInput placeholder="Chercher un article"
                      [matAutocomplete]="itemAutoComplete" formControlName="itemType">
                  </mat-form-field>
                  <mat-autocomplete #itemAutoComplete="matAutocomplete" [displayWith]="displayItemTypeFn">
                    <mat-option *ngIf="searching" class="is-loading">
                      <mat-spinner diameter="50"></mat-spinner>
                    </mat-option>
                    <ng-container *ngIf="!searching">
                      <mat-option *ngFor="let itemType of itemTypes" [value]="itemType">
                        <span>{{ itemType.label }} ({{ itemType.reference}})</span>
                      </mat-option>
                    </ng-container>
                  </mat-autocomplete>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </ng-container>



    <div *ngIf="(selectedCustomer?.customerId && selectedWarehouse?.warehouseId && displayOrderPanel)" fxLayout="row"
     fxLayoutAlign="end" fxLayoutGap="2em" class="padding-top-1 text-right">
      <div fxFlex fxLayoutAlign.xs="center center">
        <button mat-raised-button class="button-blue" (click)="plan(orderFormGroup.value)"
          [disabled]="!orderFormGroup.valid || !(selectedCustomer?.customerId && selectedWarehouse?.warehouseId && displayOrderPanel) ">
          <mat-icon class="button-icon">calendar_today</mat-icon>{{ 'orders.book' | translate }}
        </button>
      </div>
    </div>

    </ng-container>

    <ng-container *ngIf="isPageLoading">
      <mat-progress-spinner mode="indeterminate" style="position:fixed;top:50%;left:50%"></mat-progress-spinner>
    </ng-container>
  </div>
</form>
