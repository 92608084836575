<h2 *ngIf="!isUpdate" mat-dialog-title>{{'settings.shops.add' | translate}}</h2>
<h2 *ngIf="isUpdate" mat-dialog-title>{{'settings.shops.update' | translate}}</h2>

<mat-divider></mat-divider>
<form [formGroup]='shopForm' (ngSubmit)="save(shopForm.value)">

  <mat-dialog-content>
    <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
      <div fxFlex>
        <mat-form-field style="min-width: 600px;">
          <input matInput placeholder="{{'settings.shops.search' | translate}}" [matAutocomplete]="auto" formControlName='customerInput' required>
        </mat-form-field>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngIf="isLoading" class="is-loading">
            <mat-spinner diameter="50"></mat-spinner>
          </mat-option>
          <ng-container *ngIf="!isLoading">
            <mat-option *ngFor="let customer of filteredCustomers" [value]="customer">
              <span>{{ customer.lastName }} {{ customer.firstName }}</span>
              <small> | {{customer.address}}</small>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1" *ngIf="customerSelected">
      <div fxFlex>
          <mat-form-field style="min-width: 600px;">
              <input matInput placeholder="{{'common.table.address' | translate}}" formControlName="address" required>
            </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1" *ngIf="customerSelected">
        <div fxFlex>
          <mat-form-field style="min-width: 300px;">
            <input type="number" matInput placeholder="{{'model.shop.loading-time' | translate}}" formControlName="defaultLoadingTime" required
            pattern="[0-9]*">
            <mat-hint>{{'common.minutes' | translate}}</mat-hint>
            <mat-error *ngIf="hasError('defaultLoadingTime', 'required')">{{'common.error.required' | translate}}</mat-error>
            <mat-error *ngIf="hasError('defaultLoadingTime', 'pattern')">{{'common.error.integer' | translate}}</mat-error>

          </mat-form-field>
        </div>

        <div fxFlex>
            <mat-form-field style="min-width: 300px;">
              <mat-label>{{'model.shop.loading-timeslot' | translate}}</mat-label>
              <mat-select formControlName="defaultLoadingTimeSlotId" required>
                <mat-option *ngFor="let timeslot of timeslots" value="{{timeslot.id}}">{{ timeslot.from | slice:0:5}} - {{ timeslot.to | slice:0:5}}</mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('defaultLoadingTimeSlotId', 'required')">{{'common.error.required' | translate}}</mat-error>
            </mat-form-field>
          </div>
      </div>

      <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1" *ngIf="customerSelected">
          <div fxFlex>
            <mat-form-field style="min-width: 300px;">
              <input type="number" matInput placeholder="{{'model.shop.unloading-time' | translate}}" formControlName="defaultUnloadingTime" required
              pattern="[0-9]*">
              <mat-hint>{{'common.minutes' | translate}}</mat-hint>
              <mat-error *ngIf="hasError('defaultUnloadingTime', 'required')">{{'common.error.required' | translate}}</mat-error>
              <mat-error *ngIf="hasError('defaultUnloadingTime', 'pattern')">{{'common.error.integer' | translate}}</mat-error>

            </mat-form-field>
          </div>

          <div fxFlex>
              <mat-form-field style="min-width: 300px;">
                <mat-label>{{'model.shop.unloading-timeslot' | translate}}</mat-label>
                <mat-select formControlName="defaultUnloadingTimeSlotId" required>
                  <mat-option *ngFor="let timeslot of timeslots" value="{{timeslot.id}}">{{ timeslot.from | slice:0:5}} - {{ timeslot.to | slice:0:5}}</mat-option>
                </mat-select>
                <mat-error *ngIf="hasError('defaultUnloadingTimeSlotId', 'required')">{{'common.error.required' | translate}}</mat-error>
              </mat-form-field>
            </div>
        </div>


  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="warn" mat-dialog-close class="margin-right-2">{{'common.cancel' | translate}}</button>
    <button mat-raised-button *ngIf="!isUpdate" class="button-blue" type="submit" [disabled]="!shopForm.valid || !customerSelected" [promiseBtn]="createWarehouseSubscription">
      {{'settings.shops.add-button' | translate}}
    </button>
    <button mat-raised-button *ngIf="isUpdate" class="button-blue" type="submit" [disabled]="!shopForm.valid || !customerSelected" [promiseBtn]="createWarehouseSubscription">
      {{'settings.shops.update-button' | translate}}
    </button>
  </mat-dialog-actions>
</form>
