import {Component, OnInit, OnDestroy, NgZone, EventEmitter} from '@angular/core';
import {CustomerView} from 'src/app/shared/customers/customer.model';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomersService} from 'src/app/shared/customers/customers.service';
import { MatDialogRef } from '@angular/material/dialog';
import {AlertService} from 'src/app/shared/utils/alert.service';
import {Observable, Subscription} from 'rxjs';
import {E164Number, parsePhoneNumber} from 'libphonenumber-js';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-customers-add-edit',
  templateUrl: './customers-add-edit.component.html',
  styleUrls: ['./customers-add-edit.component.scss']
})
export class CustomersAddEditComponent implements OnInit, OnDestroy {

  currentCustomer = new CustomerView();
  isUpdate: boolean;
  customerAddressSaved: string;
  isAddressCorrectlySelected = true;
  invalidNumber: string;
  customerFormGroup: UntypedFormGroup;
  addCustomerSubscription: Subscription;

  locationModelChanged = new Subject<string>();
  locationModelChangedSubscription: Subscription;
  locations: google.maps.places.AutocompletePrediction[] = [];
  opened = new EventEmitter<void>();
  closed = new EventEmitter<void>();
  address: string;

  constructor(private formBuilder: UntypedFormBuilder,
              private customersService: CustomersService,
              public dialogRef: MatDialogRef<CustomersAddEditComponent>,
              private alert: AlertService, private zone: NgZone) {
  }

  ngOnInit() {
    // In the case of the update, the customerId attribute isn't empty because the customer object parameters is passed to this component
    this.currentCustomer.customerId ? this.isUpdate = true : this.isUpdate = false;
    this.customerAddressSaved = this.currentCustomer.address;

    this.customerFormGroup = this.formBuilder.group({
      customerId: new UntypedFormControl(this.currentCustomer.customerId),
      lastName: new UntypedFormControl(this.currentCustomer.lastName, [Validators.required]),
      firstName: new UntypedFormControl(this.currentCustomer.firstName),
      address: new UntypedFormControl(this.currentCustomer.address, [Validators.required]),
      mobileNumber: new UntypedFormControl(this.initializePhoneNumber(this.currentCustomer.mobileNumber)),
      phoneNumber: new UntypedFormControl(this.currentCustomer.phoneNumber),
      email: new UntypedFormControl(this.currentCustomer.email, [Validators.email]),
      comments: new UntypedFormControl(this.currentCustomer.comments),
      longitude: new UntypedFormControl(this.currentCustomer.longitude),
      latitude: new UntypedFormControl(this.currentCustomer.latitude)
    });

    this.customerFormGroup.controls['address'].valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((input) => {
      if (this.customerAddressSaved !== input) {
        this.customerFormGroup.get('address').setErrors({'badAddress': true});
      }
      if (input.length >= 3) {
        this.getPredictions(input);
      } else {
        this.locations = [];
      }
    });
  }

  private initializePhoneNumber(phone: string): E164Number | null {
    if (phone && phone !== '') {
      const phoneNumber = parsePhoneNumber(phone, 'FR');
      this.invalidNumber = (!phoneNumber.isValid()) ? phone : null;
      return phoneNumber.number;
    } else {
      return null;
    }
  }


  hasError(controlName: string, errorName: string): boolean {
    return this.customerFormGroup.controls[controlName].hasError(errorName);
  }

  save(customerFormGroupValue: CustomerView) {
    let request: Observable<any>;
    if (!this.isUpdate) {
      request = this.customersService.addCustomer(customerFormGroupValue);
    } else {
      request = this.customersService.editCustomer(customerFormGroupValue);
    }

    this.addCustomerSubscription = request.subscribe(res => {
        if (res) {
          const location = res.headers.get('Location');
          const id = location.substring(location.lastIndexOf('/') + 1);
          this.dialogRef.close(id);
        } else {
          this.dialogRef.close(true);
        }
      },
      error => {
        this.alert.error(error);
      });
  }

  ngOnDestroy(): void {
    if (this.addCustomerSubscription) {
      this.addCustomerSubscription.unsubscribe();
    }
  }

  updateLocation(place: google.maps.places.AutocompletePrediction): void {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ placeId: place.place_id }, (responses, status) => {
      if (status === google.maps.GeocoderStatus.OK) {
        if (!responses[0].formatted_address) {
          this.customerFormGroup.get('address').setErrors({'badAddress': true});
        } else {
          this.customerAddressSaved = responses[0].formatted_address;
          this.customerFormGroup.get('address').setValue(responses[0].formatted_address);
          this.customerFormGroup.get('latitude').setValue(responses[0].geometry.location.lat());
          this.customerFormGroup.get('longitude').setValue(responses[0].geometry.location.lng());
          this.isAddressCorrectlySelected = true;
        }
      }
    });
  }

  private getPredictions(input: string): void {
    const autocompleteService = new google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      {
        input
      },
      (predictions, status) => {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          this.zone.run(() => {
            this.locations = predictions;
          });
        }
      }
    );
  }

  eventOpened(): void {
    this.opened.emit();
  }

  eventClosed(): void {
    this.closed.emit();
  }

}
