import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSelectChange } from '@angular/material/select';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { OrderService } from '../../shared/orders/orders.service';
import { LightOrder, PLANNED, UNASSIGNED, CONFIRMED } from '../../shared/orders/light-order.model';
import { merge, of as observableOf} from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

import * as _moment from 'moment';
import { OrderUtilsService } from '../../shared/utils/order-utils.service';
import { Router } from '@angular/router';
import { DATE_FORMATS } from '../../app.component';
import { WarehouseService } from '../../shared/warehouse/warehouse.service';
import { State, PENDING, CREATED, DONE, CANCELED } from '../../shared/orders/light-order.model';
import { WarehouseView } from '../../shared/warehouse/warehouse.model';
import {
  ConfigurationTableModalComponent,
  ConfigurationTable } from '../../shared/configuration-table-modal/configuration-table-modal.component';
import { TableService, TRANSPORT_TABLE } from '../../shared/configuration-table-modal/table.service';
import { CustomFieldsService, CustomField } from '../../shared/resources/custom-fields.service';
import { PdfService } from '../../shared/pdf/pdf.service';
import { FullOrderView } from '../../shared/orders/base-order.model';
import { DeviceDetectorService } from 'ngx-device-detector';
const moment = _moment;

export type FilterState = 'FILTER_CREATED' | 'FILTER_PLANNED' | 'FILTER_PROGRESS' | 'FILTER_DONE' | 'FILTER_CANCELED' | 'FILTER_CONFIRMED';
export const FILTER_CREATED: FilterState = 'FILTER_CREATED';
export const FILTER_PLANNED: FilterState = 'FILTER_PLANNED';
export const FILTER_PROGRESS: FilterState = 'FILTER_PROGRESS';
export const FILTER_DONE: FilterState = 'FILTER_DONE';
export const FILTER_CANCELED: FilterState = 'FILTER_CANCELED';
export const FILTER_CONFIRMED: FilterState = 'FILTER_CONFIRMED';


@Component({
  selector: 'app-home',
  templateUrl: './transport.component.html',
  styleUrls: ['./transport.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})

export class TransportComponent implements OnInit, AfterViewInit {
  displayedColumns = this.deviceDetectorService.isMobile() ? ['status', 'details'] : ['status', 'date', 'pickup', 'pickup-time', 'delivery', 'delivery-time', 'actions'];
  data: MatTableDataSource<LightOrder> = new MatTableDataSource<LightOrder>();
  resultsLength = 0;
  isLoadingResults = true;

  @ViewChild('title', {read: ElementRef}) tableHtmlRef: ElementRef;
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  minDate = new UntypedFormControl(moment().subtract(15, 'days'));
  maxDate = new UntypedFormControl(moment().add(15, 'days'));
  warehouses = new UntypedFormControl();
  states = new UntypedFormControl();
  warehouseList: Array<WarehouseView> = [];
  stateList: Array<FilterState> = [];

  customFields: Array<CustomField> = [];

  constructor(
    private ordersService: OrderService, public dialog: MatDialog,
    private orderUtils: OrderUtilsService, private router: Router, private warehouseService: WarehouseService,
    private tableService: TableService, private customFieldService: CustomFieldsService, private pdfService: PdfService,
    private deviceDetectorService: DeviceDetectorService) {
  }

  ngOnInit() {
    this.customFieldService.findCustomFields().subscribe(categories => {
      this.customFields = [];
      categories.forEach(category => {
        this.customFields.push(...category.customFields);
      });
      if (! this.deviceDetectorService.isMobile()) {
        this.displayedColumns = this.tableService.getTransportHeadersTables(TRANSPORT_TABLE, this.customFields).selected;
      }
    });
    this.isLoadingResults = true;
    this.getAllStates();
    this.warehouseService.findWarehouses(0, 10000).subscribe((res) => {
      this.warehouseList = res.elements;
      this.warehouses.setValue(this.warehouseList.map(w => w.warehouseId));
    });
  }

  ngAfterViewInit() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          const states = this.getSelectedStates();
          return this.ordersService.findAllLightOrdersByState(
            this.paginator.pageIndex, this.paginator.pageSize,
            this.getDate(this.minDate), this.getDate(this.maxDate), states, this.warehouses.value);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.totalElements;

          return data.elements;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => {
        this.data.data = data;
        this.scrollToTop();
      });
  }

  getDate(control: UntypedFormControl): string  {
    if (!control.value || control.value === '') {
      return null;
    } else {
      return control.value.format('YYYY-MM-DD');
    }
  }

  minDateChange() {
    if (this.minDate.value.isAfter(this.maxDate.value)) {
      this.maxDate.setValue(this.minDate.value);
    }
    this.paginator.firstPage();
    this.updateData();
  }

  maxDateChange() {
    if (this.maxDate.value.isBefore(this.minDate.value)) {
      this.minDate.setValue(this.maxDate.value);
    }
    this.paginator.firstPage();
    this.updateData();
  }

  public warehouseChange(): void {
    this.paginator.firstPage();
    this.updateData();
  }

  public stateChange(event: MatSelectChange): void {
    this.paginator.firstPage();
    this.applyStateOnDateField(this.states.value);
    this.updateData();
  }

  private applyStateOnDateField(state: FilterState[]) {
    if (state && state.findIndex(s => s === FILTER_CREATED) != -1) {
      this.minDate.disable();
      this.maxDate.disable();
      this.minDate.setValue('');
      this.maxDate.setValue('');
    }
    if (state && state.findIndex(s => s === FILTER_PROGRESS) != -1) {
      this.minDate.setValue(moment());
      this.maxDate.setValue(moment());
      if (state.length == 1) {
        this.minDate.disable();
        this.maxDate.disable();
      }
    }
    if (state && state.findIndex( state => (state === FILTER_PLANNED || state === FILTER_CONFIRMED)) != -1) {
      if (state.length == 1) {
        this.minDate.setValue(moment());
      }

      this.maxDate.setValue(moment().add(15, 'days'));
      this.minDate.enable();
      this.maxDate.enable();
    }
    if (state && state.findIndex( state => ( state === FILTER_DONE || state === FILTER_CANCELED)) != -1) {
      this.minDate.setValue(moment().subtract(15, 'days'));
      if (state.length == 1) {
        this.maxDate.setValue(moment());
      }
      this.minDate.enable();
      this.maxDate.enable();
    }
  }

  private updateData() {
    const states = this.getSelectedStates();
    this.ordersService.findAllLightOrdersByState(
      this.paginator.pageIndex, this.paginator.pageSize,
      this.getDate(this.minDate), this.getDate(this.maxDate), states, this.warehouses.value).subscribe(res => {
        this.data.data = res.elements;
        this.resultsLength = res.totalElements;
        this.scrollToTop();
      });
  }

  getStateClass(order: LightOrder): string {
    return this.orderUtils.getStateClass(order);
  }

  getStateTooltip(order: LightOrder): string {
    return this.orderUtils.getStateTooltip(order);
  }

  goToDetails(order: any) {
    this.router.navigate([this.router.url + '/' + order.orderId]);
  }

  getFieldValue(lightOrder: LightOrder, field: CustomField): string {
    if (lightOrder.fields.findIndex(f => f.field.id === field.id) !== -1) {
      return lightOrder.fields.find(f => f.field.id === field.id).value;
    }
    return '';
  }

  goToCreate() {
    this.router.navigate(['/transport-add']);
  }

  configureTable() {
    const configureTable = new ConfigurationTable();
    configureTable.tableType =  TRANSPORT_TABLE;
    configureTable.columns = this.tableService.getTransportHeadersTables(TRANSPORT_TABLE, this.customFields);
    const dialogRef = this.dialog.open(ConfigurationTableModalComponent, {
      width: '350px',
      disableClose: true,
      data: configureTable
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.displayedColumns = result;
      }
    });
  }

  private getAllStates(): void {
    this.stateList = [FILTER_CREATED, FILTER_PLANNED, FILTER_CONFIRMED, FILTER_PROGRESS, FILTER_DONE, FILTER_CANCELED];
    this.states.setValue([FILTER_PROGRESS]);
    this.applyStateOnDateField([FILTER_PROGRESS]);
  }

  private getSelectedStates(): Array<State> {
    const states: State[] = [];
    if (this.states.value.findIndex( s => s  === FILTER_CREATED) != -1 ) {
      states.push(CREATED);
    }
    if (this.states.value.findIndex( s => s === FILTER_PLANNED || s === FILTER_PROGRESS) != -1 ) {
      states.push(PLANNED, UNASSIGNED, PENDING, CONFIRMED, PENDING);
    }
    if (this.states.value.findIndex( s => s === FILTER_DONE) != -1 ) {
      states.push(DONE);
    }
    if (this.states.value.findIndex( s => s === FILTER_CANCELED) != -1 ) {
      states.push(CANCELED);
    }
    if (this.states.value.findIndex( s => s === FILTER_CONFIRMED)!= -1 ) {
      states.push(CONFIRMED);
    }
    return states;
  }

  scrollToTop() {
    this.tableHtmlRef.nativeElement.scrollIntoView();
  }

  public downloadPdf(order: FullOrderView): void {
    this.pdfService.generatePurchaseOrder(order);
  }
}
