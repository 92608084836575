import { Configuration } from '../warehouse/configuration.model';
import { WarehouseId } from '../warehouse/warehouse.model';

export interface User {
  email: string;
  token: string;
  role: string;
  language: string;
  configured: boolean;
  icon: string;
  firstName?: string;
  lastName?: string;
  configuration: Configuration;
}

export type Role = 'USER' | 'ADMIN';
export const USER_ROLE: Role = 'USER';
export const USER_ADMIN: Role = 'ADMIN';

export interface CreateUser {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  role: Role;
  warehouseIds: number[];
  language: string;
}

export interface UserView {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  role: Role;
  language: string;
  warehouses: WarehouseId[];
}
