import { WarehouseService } from './../warehouse/warehouse.service';
import { Injectable } from '@angular/core';
import { DisplaySetting, DisplayField } from '../warehouse/configuration.model';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CustomDisplayService {

  displaySettings: Array<DisplaySetting> = [];

  constructor(private authService: AuthService) {
    this.loadDisplaySettings();
  }

  loadDisplaySettings() {
    this.displaySettings = this.authService.currentUserValue.configuration.displaySettings;
  }

  display(displayField: DisplayField) {
    return this.getSetting(displayField).visible.value;
  }

  isRequired(displayField: DisplayField) {
    return this.getSetting(displayField).required.value;
  }

  hasDefaultValue(displayField: DisplayField) {
    return this.getSetting(displayField).defaultValue.value !== null
      && this.getSetting(displayField).defaultValue.value !== '-1';
  }

  getDefaultValue(displayField: DisplayField): string {
    return this.getSetting(displayField).defaultValue.value;
  }

  private getSetting(displayField: DisplayField) {
    return this.displaySettings.find(c => c.field === displayField);
  }
}
