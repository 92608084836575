import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UserView } from 'src/app/shared/auth/user';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CreateUserComponent } from './create/createuser.component';
import { merge, of as observableOf } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/shared/auth/user.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/shared/utils/alert.service';
import { WarehouseService } from 'src/app/shared/warehouse/warehouse.service';
import { WarehouseView } from 'src/app/shared/warehouse/warehouse.model';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, AfterViewInit {

  displayedColumns = ['name', 'role', 'email', 'phone', 'shops', 'actions'];
  data: MatTableDataSource<UserView> = new MatTableDataSource<UserView>();
  resultsLength = 0;
  isLoadingResults = true;
  warehouses: WarehouseView[] = [];

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private userService: UserService, public dialog: MatDialog, private translate: TranslateService,
    private alert: AlertService, private wharehouseService: WarehouseService) { }

  ngOnInit() {
    this.wharehouseService.findWarehouses(0, 100).subscribe(
      res => {
        this.warehouses = res.elements;
      }
    );
  }

  ngAfterViewInit() {
    merge(this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return this.userService.findUsers(
          this.paginator.pageIndex, this.paginator.pageSize);
      }),
      map(data => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.resultsLength = data.totalElements;
        return data.elements;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        return observableOf([]);
      })
    ).subscribe(data => this.data.data = data);
  }
  openCreateModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(CreateUserComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateData();
        this.alert.info('settings.users.create-success');
      }
    });
  }

  openUpdateModal(accountEmail: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {email: accountEmail};

    const dialogRef = this.dialog.open(CreateUserComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateData();
        this.alert.info('settings.users.update-success');
      }
    });
  }

  deleteUser(user: UserView) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this.translate.instant('model.user.confirm-deletion')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userService.deleteUser(user).subscribe(
          res => {
            this.updateData();
            this.alert.info('settings.users.delete-success');
          },
          error => {
            this.alert.error(error);
          }
        );
      }
    });
  }

  private updateData() {
    this.userService.findUsers(
      this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(res => {
        this.data.data = res.elements;
        this.resultsLength = res.totalElements;
      });
  }

  getWarehouseName(id: number) {
    const found = this.warehouses.find(warehouse => warehouse.warehouseId === id);
    if (found) {
      return found.name;
    }
    return '';
  }

}

