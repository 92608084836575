import {Injectable} from '@angular/core';
import {Skill} from './skill.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export class OrderType {
  id: number;
  name: string;
  duration: number;
  skill: Skill;
  timeSlotStart: string;
  timeSlotEnd: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrderTypeService {

  constructor(private http: HttpClient) {
  }

  public findOrderTypes(): Observable<Array<OrderType>> {
    return this.http.get<Array<OrderType>>('warehouse/api/order-types');
  }
}
