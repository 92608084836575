<h2 *ngIf="!isUpdate" mat-dialog-title>{{'settings.users.add' | translate}}</h2>
<h2 *ngIf="isUpdate" mat-dialog-title>{{'settings.users.update' | translate}}</h2>
<mat-divider></mat-divider>
<form [formGroup]="createUser" autocomplete="off" novalidate (ngSubmit)="save(createUser.value)">

  <mat-dialog-content>
    <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput placeholder="{{'model.user.first-name' | translate}}" formControlName="firstName" required>
          <mat-error *ngIf="hasError('firstName', 'required')">{{'common.error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput placeholder="{{'model.user.last-name' | translate}}" formControlName="lastName" required>
          <mat-error *ngIf="hasError('lastName', 'required')">{{'common.error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput placeholder="{{'model.user.phone-number' | translate}}" formControlName="phone">
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput placeholder="{{'model.user.email' | translate}}" formControlName="email" required>
          <mat-error *ngIf="hasError('email', 'required')">{{'common.error.required' | translate}}</mat-error>
          <mat-error *ngIf="hasError('email', 'email')">{{'common.error.email' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput type="password" placeholder="{{'model.user.password' | translate}}" formControlName="password"
            required>
          <mat-error *ngIf="hasError('password', 'required')">{{'common.error.required' | translate}}</mat-error>
          <mat-error *ngIf="hasError('password', 'mustMatch')">{{'common.error.confirm-password' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput type="password" placeholder="{{'model.user.confirm-password' | translate}}"
            formControlName="confirmPassword" required>
          <mat-error *ngIf="hasError('confirmPassword', 'required')">{{'common.error.required' | translate}}</mat-error>
          <mat-error *ngIf="hasError('confirmPassword', 'mustMatch')">{{'common.error.confirm-password' | translate}}
          </mat-error>

        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <mat-label>{{'model.user.role' | translate}}</mat-label>
          <mat-select formControlName="role" required>
            <mat-option value="USER">{{'model.user.USER' | translate}}</mat-option>
            <mat-option value="ADMIN">{{'model.user.ADMIN' | translate}}</mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('role', 'required')">{{'common.error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em" *ngIf="createUser.get('role').value === 'USER'">
      <div fxFlex>
        <mat-form-field style="min-width: 100%;">
          <mat-label>{{'model.user.shops' | translate}}</mat-label>
          <mat-select formControlName="warehouseIds" multiple>
            <mat-option *ngFor="let warehouse of warehouses" value="{{warehouse.warehouseId}}">{{warehouse.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('role', 'required')">{{'common.error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex>
        <mat-radio-group formControlName="language" class="language-radio-group">
          <mat-radio-button class="language-radio-button" value="fr">{{'model.user.fr' | translate}}</mat-radio-button>
          <mat-radio-button class="language-radio-button" value="en">{{'model.user.en' | translate}}</mat-radio-button>
        </mat-radio-group>
      </div>
    </div>



  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="warn" mat-dialog-close
      class="margin-right-2">{{'common.cancel' | translate}}</button>
    <button *ngIf="!isUpdate" mat-raised-button class="button-blue" type="submit"
      [disabled]="!createUser.valid" [promiseBtn]="createUserSubscription">{{'settings.users.add-button' | translate}}</button>

    <button *ngIf="isUpdate" mat-raised-button class="button-blue" type="submit"
      [disabled]="!createUser.valid" [promiseBtn]="createUserSubscription">{{'settings.users.update-button' | translate}}</button>
  </mat-dialog-actions>

</form>
