<div class="content-header">
  <div fxLayout="row" class="buttons">
    <div fxFlex>
      <button mat-raised-button class="button-green" (click)="clickOnBackButton()">
        <mat-icon class="button-icon">chevron_left</mat-icon>{{ 'common.back' | translate}}
      </button>
    </div>
    <div fxFlex fxLayoutAlign="end">
      <button mat-raised-button class="margin-right-2 blue-color" (click)="openEditModal()">
        <mat-icon class="button-icon">edit</mat-icon> <span fxHide.xs>{{ 'customers.edit-customer' | translate}}</span>
      </button>
      <button mat-raised-button class="button-blue" (click)="addTransport()">
        <mat-icon class="button-icon">calendar_today</mat-icon><span fxHide.xs>{{ 'orders.book-transport' | translate }}</span>
      </button>
    </div>
  </div>
</div>

<div style="clear:both;"></div>
<mat-divider></mat-divider>
<div class="padding-1">
  <mat-card>
    <mat-card-title>
      {{ currentCustomer.firstName }} {{ currentCustomer.lastName }}
    </mat-card-title>
    <mat-card-content>
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2em" fxLayoutGap.lt-md="0" class="padding-top-1">
        <div fxFlex>
          <div fxLayout="row" fxLayoutGap="2em">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'common.table.address' | translate }}</mat-label>
              <input matInput value='{{currentCustomer.address}}' disabled="true">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="2em">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'common.table.email' | translate }}</mat-label>
              <input matInput value='{{currentCustomer.email}}' disabled="true">
            </mat-form-field>
          </div>
        </div>
        <div fxFlex>
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="2em" fxLayoutGap.lt-md="0">
            <div fxFlex="50">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'common.table.mobile-phone' | translate }}</mat-label>
                <input matInput value='{{currentCustomer.mobileNumber}}' disabled="true">
              </mat-form-field>
            </div>
            <div fxFlex="50">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'common.table.landline-phone' | translate }}</mat-label>
                <input matInput value='{{currentCustomer.phoneNumber}}' disabled="true">
              </mat-form-field>
            </div>
          </div>
          <div fxLayout="row" fxLayoutGap="2em">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'common.table.comment' | translate }}</mat-label>
              <input matInput value='{{currentCustomer.comments}}' disabled="true">
            </mat-form-field>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <table mat-table [dataSource]="data" class="mat-elevation-z8">
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.status' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element">
        <div [matTooltip]="getStateTooltip(element)"
            matTooltipPosition="above" [className]="getStateClass(element)"></div>
      </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.date' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{element.scheduledDate}} </td>
    </ng-container>

    <ng-container matColumnDef="pickup">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.pickup' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element">
        <app-customer-table [order]="element" [serviceType]="'PICKUP'"></app-customer-table>
      </td>
    </ng-container>

    <ng-container matColumnDef="pickup-time">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.time-slot' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.serviceType ==='PICKUP'  && element.timeSlot"> {{element.timeSlot.from | slice:0:5}} - {{element.timeSlot.to | slice:0:5}} </span>
        <span *ngIf="element.child.serviceType ==='PICKUP'   && element.child.timeSlot"> {{element.child.timeSlot.from | slice:0:5}} -
          {{element.child.timeSlot.to | slice:0:5}} </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="delivery">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.delivery' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element">
        <app-customer-table [order]="element" [serviceType]="'DELIVERY'"></app-customer-table>
      </td>
    </ng-container>

    <ng-container matColumnDef="delivery-time">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.time-slot' | translate | uppercase }}
      </th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf="element.serviceType ==='DELIVERY' && element.timeSlot"> {{element.timeSlot.from | slice:0:5}} - {{element.timeSlot.to | slice:0:5}} </span>
        <span *ngIf="element.child.serviceType ==='DELIVERY' && element.child.timeSlot"> {{element.child.timeSlot.from | slice:0:5}} -
          {{element.child.timeSlot.to | slice:0:5}} </span>
      </td>
    </ng-container>

    <ng-container *ngFor="let customField of customFields" matColumnDef="{{customField.name}}">
        <th mat-header-cell *matHeaderCellDef> {{ customField.name | uppercase }} </th>
        <td mat-cell *matCellDef="let element">
            {{getFieldValue(element, customField)}}
        </td>
      </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>
        {{ 'common.table.action' | translate | uppercase }}
        <button mat-icon-button (click)="configureTable()">
            <mat-icon>settings_applications</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element">
        <button mat-mini-fab class="button-blue" (click)="goToDetails(element)">
          <mat-icon>visibility</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>

  <mat-paginator class="mat-elevation-z8" [length]="resultsLength"  [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons></mat-paginator>
</div>
