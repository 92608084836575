import {BaseOrder} from './base-order.model';


export type ServiceType = 'SERVICE' | 'PICKUP' | 'DELIVERY';
export const SERVICE: ServiceType = 'SERVICE';
export const PICKUP: ServiceType = 'PICKUP';
export const DELIVERY: ServiceType = 'DELIVERY';

export type State = 'PLANNED' | 'UNASSIGNED' | 'CREATED' | 'DELETED' | 'CANCELED' | 'DONE' | 'REPROGRAMMED' | 'DEPROGRAMMED'
  | 'PENDING' | 'CONFIRMED';
export const PLANNED: State = 'PLANNED';
export const UNASSIGNED: State = 'UNASSIGNED';
export const CREATED: State = 'CREATED';
export const CANCELED: State = 'CANCELED';
export const DONE: State = 'DONE';
export const REPROGRAMMED: State = 'REPROGRAMMED';
export const DEPROGRAMMED: State = 'DEPROGRAMMED';
export const DELETED: State = 'DELETED';
export const CONFIRMED: State = 'CONFIRMED';
export const PENDING: State = 'PENDING';

export class LightOrder extends BaseOrder {

  child: LightOrder;

}
