import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CreateShopComponent } from './create/createshop.component';
import { WarehouseView } from 'src/app/shared/warehouse/warehouse.model';
import { merge, of as observableOf } from 'rxjs';
import { startWith, switchMap, map, catchError } from 'rxjs/operators';
import { WarehouseService } from 'src/app/shared/warehouse/warehouse.service';
import { AlertService } from 'src/app/shared/utils/alert.service';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-shops',
  templateUrl: './shops.component.html',
  styleUrls: ['./shops.component.scss']
})
export class ShopsComponent implements AfterViewInit {

  displayedColumns = ['name', 'address', 'phone', 'actions'];
  data: MatTableDataSource<WarehouseView> = new MatTableDataSource<WarehouseView>();
  resultsLength = 0;
  isLoadingResults = true;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private warehouseService: WarehouseService, public dialog: MatDialog, private alert: AlertService,
    private translate: TranslateService) { }

  ngAfterViewInit() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.warehouseService.findWarehouses(
            this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.totalElements;

          return data.elements;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.data.data = data);
  }

  openCreateModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(CreateShopComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.alert.info('settings.shops.create-success');
        this.updateData();
      }
    });
  }

  openUpdateModal(warehouse: WarehouseView) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {id: warehouse.warehouseId};

    const dialogRef = this.dialog.open(CreateShopComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.alert.info('settings.shops.update-success');
        this.updateData();
      }
    });
  }

  deleteWarehouse(warehouse: WarehouseView) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this.translate.instant('settings.shops.confirm-deletion')
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.warehouseService.deleteWarehouse(warehouse.warehouseId).subscribe(
          () => {
            this.alert.info('settings.shops.delete-success');
            this.updateData();
          },
          err => {
            this.alert.error(err);
          }
        );
      }
    });
  }

  private updateData() {
    this.warehouseService.findWarehouses(
      this.paginator.pageIndex, this.paginator.pageSize)
      .subscribe(res => {
        this.data.data = res.elements;
        this.resultsLength = res.totalElements;
      });
  }


}
