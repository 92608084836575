<div class="content-header" fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 2em;margin-bottom: 1em;">
  <div>
    <h1 style="margin: Opx !important;">{{'settings.my-account.title' | translate}}</h1>
  </div>
  <div>
    <button mat-raised-button class="button-blue" (click)="save(update.value)">
      <mat-icon class="button-icon">save</mat-icon>{{'common.save' | translate}}
    </button>
  </div>
</div>
<div style="clear:both;"></div>
<mat-divider></mat-divider>
<div class="padding-1">
  <form [formGroup]="update" autocomplete="off" novalidate (ngSubmit)="save(update.value)">

  <mat-card class="my-account">
    <mat-card-header>
      <h3>{{'settings.my-account.information' | translate}}</h3>
    </mat-card-header>
    <mat-card-content class="my-account">
      <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
        <div fxFlex="300px">
          <mat-form-field style="min-width: 300px;">
            <input matInput placeholder="{{'model.user.first-name' | translate}}" formControlName="firstName" required>
            <mat-error *ngIf="hasError('firstName', 'required')">{{'common.error.required' | translate}}</mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="300px">
          <mat-form-field style="min-width: 300px;">
            <input matInput placeholder="{{'model.user.last-name' | translate}}" formControlName="lastName" required>
            <mat-error *ngIf="hasError('lastName', 'required')">{{'common.error.required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="2em">
        <div fxFlex="300px">
          <mat-form-field style="min-width: 300px;">
            <input matInput placeholder="{{'model.user.phone-number' | translate}}" formControlName="phone">
          </mat-form-field>
        </div>
        <div fxFlex="300px">
          <mat-form-field style="min-width: 300px;">
            <input matInput placeholder="{{'model.user.email' | translate}}" formControlName="email" required>
            <mat-error *ngIf="hasError('email', 'required')">{{'common.error.required' | translate}}</mat-error>
            <mat-error *ngIf="hasError('email', 'email')">{{'common.error.email' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="2em">
        <div fxFlex="300px">
          <mat-form-field style="min-width: 300px;">
            <mat-label>{{'model.user.role' | translate}}</mat-label>
            <mat-select formControlName="role" required>
              <mat-option value="USER">{{'model.user.USER' | translate}}</mat-option>
              <mat-option value="ADMIN">{{'model.user.ADMIN' | translate}}</mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('role', 'required')">{{'common.error.required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="2em" *ngIf="update.get('role').value === 'USER'">
        <div fxFlex="300px">
          <mat-form-field style="min-width: 100%;">
            <mat-label>{{'model.user.shops' | translate}}</mat-label>
            <mat-select formControlName="warehouseIds" multiple>
              <mat-option *ngFor="let warehouse of warehouses" value="{{warehouse.warehouseId}}">{{warehouse.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('role', 'required')">{{'common.error.required' | translate}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="2em">
        <div fxFlex="300px">
          <mat-radio-group formControlName="language" class="language-radio-group">
            <mat-radio-button class="language-radio-button" value="fr">{{'model.user.fr' | translate}}</mat-radio-button>
            <mat-radio-button class="language-radio-button" value="en">{{'model.user.en' | translate}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </mat-card-content>
  </mat-card>


  <mat-card class="my-account margin-top-2">
    <mat-card-header>
      <h3>{{'settings.my-account.password' | translate}}</h3>
    </mat-card-header>
    <mat-card-content class="my-account">
    <div fxLayout="row" fxLayoutGap="2em">
        <div fxFlex="300px">
          <mat-form-field style="min-width: 300px;">
            <input matInput type="password" placeholder="{{'model.user.password' | translate}}" formControlName="password"
              required>
            <mat-error *ngIf="hasError('password', 'required')">{{'common.error.required' | translate}}</mat-error>
            <mat-error *ngIf="hasError('password', 'mustMatch')">{{'common.error.confirm-password' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
        <div fxFlex="300px">
          <mat-form-field style="min-width: 300px;">
            <input matInput type="password" placeholder="{{'model.user.confirm-password' | translate}}"
              formControlName="confirmPassword" required>
            <mat-error *ngIf="hasError('confirmPassword', 'required')">{{'common.error.required' | translate}}</mat-error>
            <mat-error *ngIf="hasError('confirmPassword', 'mustMatch')">{{'common.error.confirm-password' | translate}}
            </mat-error>

          </mat-form-field>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
    <div fxFlex>
      <button mat-raised-button class="button-blue" (click)="save(update.value)" [promiseBtn]="accountSubscription">
        <mat-icon class="button-icon">save</mat-icon>{{'common.save' | translate}}
      </button>
    </div>
  </div>

</form>


</div>
