import { AbstractControl } from '@angular/forms';

/** custom validator to check that order type is required even if input value is -1
 *  when user has made it required in display settings
 */
export function RequiredOrderTypeValidator(control: AbstractControl) {
    if (control.value === '-1') {
      return {requiredOrderType: true};
    } else {
      return null;
    }
}
