<h2 mat-dialog-title>Ajouter un article</h2>
<mat-divider></mat-divider>
<form [formGroup]="quantityFormGroup" autocomplete="off" novalidate (ngSubmit)="save(quantityFormGroup.value)">

  <mat-dialog-content>

    <div fxLayout="column" fxLayoutGap="2em" class="padding-top-1">
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput placeholder="Article" required [value]="itemType.label" readonly>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput type="number" placeholder="Quantité" formControlName="quantity">
          <mat-error *ngIf="hasError('quantity', 'required')">{{'common.error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="warn" mat-dialog-close
            class="margin-right-2">{{'common.cancel' | translate}}</button>

    <button mat-raised-button [disabled]="!quantityFormGroup.valid" class="button-blue" type="submit">Ajouter l'article</button>

  </mat-dialog-actions>

</form>
