import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { User, CreateUser, USER_ROLE } from 'src/app/shared/auth/user';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UserService } from 'src/app/shared/auth/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WarehouseView } from 'src/app/shared/warehouse/warehouse.model';
import { WarehouseService } from 'src/app/shared/warehouse/warehouse.service';
import { MustMatch } from 'src/app/shared/utils/must-match.validator';
import { AlertService } from 'src/app/shared/utils/alert.service';
import { Observable, Subscription } from 'rxjs';

export interface UpdateAccount {
  email: string;
}

@Component({
  selector: 'app-user-create',
  templateUrl: './createuser.component.html',
  styleUrls : ['./createuser.component.scss']
})
export class CreateUserComponent implements OnInit, OnDestroy {

  createUser: UntypedFormGroup;
  warehouses: WarehouseView[] = new Array();
  isUpdate = false;
  createUserSubscription: Subscription;
  constructor(
    public dialogRef: MatDialogRef<CreateUserComponent>,
    private userService: UserService,
    private warehouseService: WarehouseService,
    private formBuilder: UntypedFormBuilder,
    private alert: AlertService,
    @Inject(MAT_DIALOG_DATA) public data: UpdateAccount) {
     }

  ngOnInit() {

    this.warehouseService.findWarehouses(0, 1000).subscribe(res => {
      this.warehouses = res.elements;
    });
    this.createUser = this.formBuilder.group({
      phone: new UntypedFormControl(''),
      email : new UntypedFormControl('', [Validators.required, Validators.email]),
      firstName : new UntypedFormControl('', [Validators.required]),
      lastName : new UntypedFormControl('', [Validators.required]),
      password : new UntypedFormControl('', [Validators.required]),
      confirmPassword : new UntypedFormControl('', [Validators.required]),
      role : new UntypedFormControl('USER', [Validators.required]),
      warehouseIds : new UntypedFormControl('', [Validators.required]),
      language : new UntypedFormControl('fr')
    }, {
      validators: [ MustMatch('password', 'confirmPassword')]
    });

    if (this.data && this.data.email) {
      this.isUpdate = true;
      this.userService.getUser(this.data.email).subscribe(user => {
          this.createUser.get('email').setValue(user.email);
          this.createUser.get('email').disable();
          this.createUser.get('firstName').setValue(user.firstName);
          this.createUser.get('lastName').setValue(user.lastName);
          this.createUser.get('phone').setValue(user.phone);
          this.createUser.get('password').setValue('NOCHANGE');
          this.createUser.get('confirmPassword').setValue('NOCHANGE');
          this.createUser.get('role').setValue(user.role);
          this.createUser.get('language').setValue(user.language);
          this.createUser.get('warehouseIds').setValue(user.warehouses.map(warehouseId => String(warehouseId.id)));
      });
    }

    this.createUser.get('role').valueChanges.subscribe(val => {
      if (val === 'USER') {
        this.createUser.get('warehouseIds').enable();
      } else {
        this.createUser.get('warehouseIds').disable();
      }
    });

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.createUser.controls[controlName].hasError(errorName);
  }

  save(user: CreateUser) {
    let request: Observable<any>;
    if (this.isUpdate) {
      if (this.createUser.get('password').value === 'NOCHANGE') {
        user.password = undefined;
      }
      request = this.userService.updateUser(this.data.email, user);
    } else {
      request = this.userService.createUser(user);
    }

    this.createUserSubscription = request.subscribe( () => {
      this.dialogRef.close(true);
    },
    error => {
      this.alert.error(error);
    });
  }

  ngOnDestroy(): void  {
    if (this.createUserSubscription) {
      this.createUserSubscription.unsubscribe();
    }
  }
}
