<div class="content-header">
  <div fxLayout="row" fxLayoutAlign="space-between center"  style="width: 100%; padding: 1em;">
    <div #title class="ml-2">
      <h1>{{ 'orders.title' | translate}}</h1>
    </div>
    <div class="mr-2">
        <button mat-raised-button class="button-blue" (click)="goToCreate()"><mat-icon class="button-icon">calendar_today</mat-icon>
          <span fxHide.xs>{{ 'orders.book-transport' | translate }}</span>
        </button>
    </div>
  </div>
</div>
<div style="clear:both;"></div>
<mat-divider></mat-divider>
<div class="padding-1">


    <mat-form-field class="margin-right-2">
      <mat-label>{{ 'common.table.status' | translate}}</mat-label>
      <mat-select [formControl]="states" (selectionChange)="stateChange($event)" [multiple]="true">
        <mat-option *ngFor="let state of stateList" [value]="state">{{'orders.filter.' + state | translate}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="margin-right-2" *ngIf="warehouseList.length > 1">
      <mat-label>{{ 'settings.shops.title' | translate}}</mat-label>
      <mat-select [formControl]="warehouses" multiple (selectionChange)="warehouseChange()">
        <mat-option *ngFor="let warehouse of warehouseList" [value]="warehouse.warehouseId">{{warehouse.name}}</mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="margin-right-2">
      <input matInput [matDatepicker]="min" placeholder="{{ 'common.from' | translate }}" [formControl]="minDate" (dateChange)="minDateChange()">
      <mat-datepicker-toggle matSuffix [for]="min"></mat-datepicker-toggle>
      <mat-datepicker #min></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <input matInput [matDatepicker]="max" placeholder="{{ 'common.to' | translate }}" [formControl]="maxDate" (dateChange)="maxDateChange()">
        <mat-datepicker-toggle matSuffix [for]="max"></mat-datepicker-toggle>
        <mat-datepicker #max></mat-datepicker>
    </mat-form-field>


    <table mat-table [dataSource]="data" class="mat-elevation-z8">

        <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="status" >
          <th mat-header-cell *matHeaderCellDef> {{ 'common.table.status' | translate | uppercase }} </th>
          <td mat-cell *matCellDef="let element"> <div [matTooltip]="getStateTooltip(element)"
            matTooltipPosition="above" [className]="getStateClass(element)"></div> </td>
        </ng-container>

        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef> {{ 'common.table.date' | translate | uppercase }} </th>
          <td mat-cell *matCellDef="let element"> {{element.scheduledDate | date: 'dd/MM/yyyy'}} </td>
        </ng-container>

        <ng-container matColumnDef="details">
          <th mat-header-cell *matHeaderCellDef> {{ 'common.table.details' | translate | uppercase }} </th>
          <td mat-cell *matCellDef="let element">
            <div fxLayout="column">
              <p>Livraison pour: <b>{{element.customer.lastName}} {{element.customer.firstName}}</b></p>
              <p>Date de plannification: <b>{{element.scheduledDate | date: 'dd/MM/yyyy'}}</b></p>
              <p>{{ 'common.table.time-slot' | translate }}: {{element.timeSlot.from | slice:0:5}} - {{element.timeSlot.to | slice:0:5}}<b></b></p>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="pickup">
          <th mat-header-cell *matHeaderCellDef> {{ 'common.table.pickup' | translate | uppercase }} </th>
          <td mat-cell *matCellDef="let element">
              <app-customer-table [order]="element" [serviceType]="'PICKUP'"></app-customer-table>
          </td>
        </ng-container>

        <ng-container matColumnDef="pickup-time">
          <th mat-header-cell *matHeaderCellDef> {{ 'common.table.time-slot' | translate | uppercase }} </th>
          <td mat-cell *matCellDef="let element">
              <span *ngIf="element.serviceType ==='PICKUP' && element.timeSlot"> {{element.timeSlot.from | slice:0:5}} - {{element.timeSlot.to | slice:0:5}} </span>
              <span *ngIf="element.child.serviceType ==='PICKUP' && element.child.timeSlot"> {{element.child.timeSlot.from | slice:0:5}} - {{element.child.timeSlot.to | slice:0:5}} </span>
              <ng-container>
                <span class="terminated-date" *ngIf="element.terminatedDate && element.serviceType ==='PICKUP'"><br>{{ 'model.order.terminated-date' | translate }}: {{element.terminatedDate | date: 'HH:mm'}}</span>
                <span  class="terminated-date" *ngIf="element.child.terminatedDate && element.child.serviceType ==='PICKUP'"><br>{{ 'model.order.terminated-date' | translate }}: {{element.child.terminatedDate | date: 'HH:mm'}} </span>
              </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="delivery">
          <th mat-header-cell *matHeaderCellDef> {{ 'common.table.delivery' | translate | uppercase }} </th>
          <td mat-cell *matCellDef="let element">
              <app-customer-table [order]="element" [serviceType]="'DELIVERY'"></app-customer-table>
          </td>
        </ng-container>

        <ng-container matColumnDef="delivery-time">
          <th mat-header-cell *matHeaderCellDef> {{ 'common.table.time-slot' | translate | uppercase }} </th>
          <td mat-cell *matCellDef="let element">
              <span *ngIf="element.serviceType ==='DELIVERY'  && element.timeSlot"> {{element.timeSlot.from | slice:0:5}} - {{element.timeSlot.to | slice:0:5}} </span>
              <span *ngIf="element.child.serviceType ==='DELIVERY'  && element.child.timeSlot"> {{element.child.timeSlot.from | slice:0:5}} - {{element.child.timeSlot.to | slice:0:5}} </span>
              <ng-container>
                  <br><span class="terminated-date" *ngIf="element.terminatedDate && element.serviceType ==='DELIVERY'">{{ 'model.order.terminated-date' | translate }}: {{element.terminatedDate | date: 'HH:mm'}}</span>
                  <br> <span  class="terminated-date" *ngIf="element.child.terminatedDate && element.child.serviceType ==='DELIVERY'">{{ 'model.order.terminated-date' | translate }}: {{element.child.terminatedDate | date: 'HH:mm'}} </span>
                </ng-container>
          </td>
        </ng-container>

        <ng-container *ngFor="let customField of customFields" matColumnDef="{{customField.name}}">
            <th mat-header-cell *matHeaderCellDef> {{ customField.name | uppercase }} </th>
            <td mat-cell *matCellDef="let element">
                {{getFieldValue(element, customField)}}
            </td>
          </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>
            {{ 'common.table.action' | translate | uppercase }}
            <button mat-icon-button (click)="configureTable()">
                <mat-icon>settings_applications</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element">
              <button mat-mini-fab class="button-blue" (click)="goToDetails(element)"><mat-icon>visibility</mat-icon></button>
              <button mat-mini-fab *ngIf="element.state !== 'CREATED'" class="button-blue button-pdf" (click)="$event.stopPropagation();downloadPdf(element)"><mat-icon>cloud_download</mat-icon></button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetails(row)"></tr>
      </table>
      <mat-paginator class="mat-elevation-z8"  [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>
