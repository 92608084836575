import { CustomDisplayService } from './shared/utils/custom-display.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CountdownModule  } from 'ngx-countdown';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StarRatingModule } from 'angular-star-rating';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatStepperModule } from '@angular/material/stepper';

import { LoginComponent } from './login/login.component';
import { LoginLayoutComponent } from './login/login-layout.component';
import { HomeLayoutComponent } from './warehouse/home-layout.component';
import { HeaderComponent } from './warehouse/header/header.component';
import { TransportComponent } from './warehouse/transport/transport.component';
import { SettingsComponent } from './warehouse/settings/settings.component';

import { AuthService } from './shared/auth/auth.service';
import { AuthGuard } from './shared/auth/auth.guard';
import { OrderService } from './shared/orders/orders.service';
import {CustomerTableDisplayComponent} from './shared/customers/customer-table-display.component';
import { ErrorInterceptor } from './shared/utils/error.interceptor';
import { JwtInterceptor } from './shared/utils/jwt.interceptor';
import { UsersComponent } from './warehouse/settings/users/users.component';
import { CreateUserComponent } from './warehouse/settings/users/create/createuser.component';
import { UserService } from './shared/auth/user.service';
import { ConfirmationDialogComponent } from './shared/confirmation-dialog/confirmation-dialog.component';
import {ConfigurationTableModalComponent} from './shared/configuration-table-modal/configuration-table-modal.component';
import { ImageModalComponent } from './shared/image-modal/image-modal.component';
import { MatPaginatorI18nService } from './shared/utils/mat-paginator-i18n.service';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { ShopsComponent } from './warehouse/settings/shops/shops/shops.component';
import { CustomersComponent } from './warehouse/customers/customers.component';
import { CustomersDetailsComponent } from './warehouse/customers/customers-details/customers-details.component';
import { CreateShopComponent } from './warehouse/settings/shops/shops/create/createshop.component';
import { WarehouseService } from './shared/warehouse/warehouse.service';
import { AlertService } from './shared/utils/alert.service';
import { MyAccountComponent } from './warehouse/settings/my-account/my-account.component';
import { SaveToBasketComponent } from './warehouse/transport/transport-add-edit/save-to-basket/save-to-basket.component';

import { OrderUtilsService } from './shared/utils/order-utils.service';
import { CustomersAddEditComponent } from './warehouse/customers/customers-add-edit/customers-add-edit.component';
import { ReportsComponent } from './warehouse/reports/reports.component';
import { DetailsComponent } from './shared/details/details.component';
import { StepperComponent } from './shared/stepper/stepper.component';
import { TransportAddEditComponent } from './warehouse/transport/transport-add-edit/transport-add-edit.component';
import { ConfigurationModalComponent } from './shared/configuration-modal/configuration-modal.component';
import { TableService } from './shared/configuration-table-modal/table.service';
import { PdfService } from './shared/pdf/pdf.service';
import { ConfigurationComponent } from './warehouse/settings/configuration/configuration.component';
import { ScoreAndSaveComponent } from './warehouse/transport/transport-add-edit/score-and-save/score-and-save.component';
import { ChartComponent } from './shared/chart/chart.component';
import { ChartService } from './shared/chart/chart.services';
import { NgChartsModule } from 'ng2-charts';
import { PromiseBtnDirective } from './shared/directives/promise-btn.directive';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { AddItemComponent } from './warehouse/transport/add-item/add-item.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    LoginComponent,
    TransportComponent,
    HeaderComponent,
    SettingsComponent,
    CustomerTableDisplayComponent,
    CustomersComponent,
    UsersComponent,
    CreateUserComponent,
    CustomerTableDisplayComponent,
    ConfirmationDialogComponent,
    ConfigurationTableModalComponent,
    ImageModalComponent,
    ShopsComponent,
    CustomersDetailsComponent,
    CreateShopComponent,
    SaveToBasketComponent,
    ScoreAndSaveComponent,
    MyAccountComponent,
    ConfigurationComponent,
    CustomersAddEditComponent,
    ReportsComponent,
    DetailsComponent,
    StepperComponent,
    TransportAddEditComponent,
    ConfigurationModalComponent,
    ChartComponent,
    AddItemComponent,
    PromiseBtnDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FlexLayoutModule,
    MatToolbarModule,
    MatCardModule,
    MatButtonModule,
    MatInputModule,
    MatCheckboxModule,
    MatListModule,
    MatFormFieldModule,
    MatGridListModule,
    MatSidenavModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatDividerModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatRadioModule,
    MatTooltipModule,
    DragDropModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient]
      }
    }),
    StarRatingModule.forRoot(),
    MatStepperModule,
    CountdownModule,
    NgxMatIntlTelInputComponent,
    NgChartsModule
  ],
  exports: [
    CustomerTableDisplayComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    OrderService,
    UserService,
    WarehouseService,
    AlertService,
    OrderUtilsService,
    TableService,
    PdfService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorI18nService,
    },
    CustomDisplayService,
    ChartService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
