import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { LightOrder, PLANNED, CONFIRMED, PENDING, DONE, CANCELED, CREATED, UNASSIGNED } from '../orders/light-order.model';

@Injectable()
export class OrderUtilsService {

  constructor(private translate: TranslateService) {
  }

  isPlanned(order: LightOrder): boolean {
    return (order.state === PLANNED || order.state === PENDING || order.state === UNASSIGNED);
  }

  getStateClass(order: LightOrder): string {
    if (order.state === DONE && order.child.state === DONE) {
      return 'done-state';
    } else if (order.state === DONE || order.child.state === DONE) {
      return 'pending-state';
    } else if (this.isPlanned(order) && this.isPlanned(order.child)) {
      return 'planned-state';
    } else if (order.state === CANCELED) {
      return 'canceled-state';
    } else if (order.state === CREATED) {
      return 'to-plan-state';
    } else if (order.state === CONFIRMED) {
      return 'confirmed-state';
    }
  }

  getStateTooltip(order: LightOrder): string {
    if (order.state === DONE && order.child.state === DONE) {
      return this.translate.instant('model.order.state.done');
    } else if (order.state === DONE || order.child.state === DONE) {
      return this.translate.instant('model.order.state.pending');
    } else if (this.isPlanned(order) && this.isPlanned(order.child)) {
      return this.translate.instant('model.order.state.planned');
    } else if (order.state === CANCELED) {
      return this.translate.instant('model.order.state.canceled');
    } else if (order.state === CREATED) {
      return this.translate.instant('model.order.state.to-plan');
    } else if (order.state === CONFIRMED) {
      return this.translate.instant('model.order.state.confirmed');
    }
  }
}
