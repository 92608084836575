<div mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
    <div fxFlex>
        <img [src]="url" class="image" >
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-button (click)="download()" cdkFocusInitial class="button-blue">{{'common.download' | translate}}</button>
</div>
