import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {CustomerView} from 'src/app/shared/customers/customer.model';
import {CustomersService} from 'src/app/shared/customers/customers.service';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import {startWith, switchMap, map, catchError} from 'rxjs/operators';
import {merge, of as observableOf} from 'rxjs';
import {LightOrder} from 'src/app/shared/orders/light-order.model';
import {OrderUtilsService} from 'src/app/shared/utils/order-utils.service';
import {CustomersAddEditComponent} from '../customers-add-edit/customers-add-edit.component';
import {AlertService} from 'src/app/shared/utils/alert.service';
import { CustomField, CustomFieldsService } from 'src/app/shared/resources/custom-fields.service';
import { TableService, TRANSPORT_TABLE } from 'src/app/shared/configuration-table-modal/table.service';
import { ConfigurationTableModalComponent, ConfigurationTable } from 'src/app/shared/configuration-table-modal/configuration-table-modal.component';

@Component({
  selector: 'app-customers-details',
  templateUrl: './customers-details.component.html',
  styleUrls: ['./customers-details.component.scss']
})
export class CustomersDetailsComponent implements OnInit, AfterViewInit {

  customerId = Number(this.route.snapshot.paramMap.get('customerId'));
  currentCustomer = new CustomerView();

  displayedColumns = ['status', 'date', 'pickup', 'pickup-time', 'delivery', 'delivery-time', 'actions'];
  data: MatTableDataSource<LightOrder> = new MatTableDataSource<LightOrder>();

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  resultsLength = 0;
  customFields: Array<CustomField> = [];

  constructor(private route: ActivatedRoute,
              private customersService: CustomersService,
              private router: Router,
              private orderUtils: OrderUtilsService,
              public dialog: MatDialog,
              private alert: AlertService,
              private tableService: TableService, private customFieldService: CustomFieldsService) {
  }

  ngOnInit() {
    this.customFieldService.findCustomFields().subscribe(categories => {
      this.customFields = [];
      categories.forEach(category => {
        this.customFields.push(...category.customFields);
      });
      this.displayedColumns = this.tableService.getTransportHeadersTables(TRANSPORT_TABLE, this.customFields).selected;
    });
  }

  ngAfterViewInit() {
    this.updateData();
  }

  clickOnBackButton() {
    this.router.navigate(['/customers']);
  }

  openEditModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(CustomersAddEditComponent, dialogConfig);
    dialogRef.componentInstance.currentCustomer = this.currentCustomer;

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.alert.info('customers.update-success');
        this.updateData();
      }
    });
  }

  addTransport() {
      this.router.navigate(['/transport-add'],  { queryParams: { c: this.customerId } });
  }

  getStateClass(order: LightOrder): string {
    return this.orderUtils.getStateClass(order);
  }

  getStateTooltip(order: LightOrder): string {
    return this.orderUtils.getStateTooltip(order);
  }

  updateData() {
    this.customersService.findCustomer(this.customerId).subscribe(res => {
      this.currentCustomer = res;
    });

    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          return this.customersService.findAllLightOrdersForACustomer(this.customerId,
            this.paginator.pageIndex, this.paginator.pageSize);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.resultsLength = data.totalElements;

          return data.elements;
        }),
        catchError(() => {
          return observableOf([]);
        })
      ).subscribe(data => this.data.data = data);
  }

  goToDetails(order: any) {
    this.router.navigate(['customers/' + this.customerId + '/order/' + order.orderId]);
  }

  getFieldValue(lightOrder: LightOrder, field: CustomField): string {
    if (lightOrder.fields.findIndex(f => f.field.id === field.id) !== -1) {
      return lightOrder.fields.find(f => f.field.id === field.id).value;
    }
    return '';
  }

  configureTable() {
    const configureTable = new ConfigurationTable();
    configureTable.tableType =  TRANSPORT_TABLE;
    configureTable.columns = this.tableService.getTransportHeadersTables(TRANSPORT_TABLE, this.customFields);
    const dialogRef = this.dialog.open(ConfigurationTableModalComponent, {
      width: '350px',
      disableClose: true,
      data: configureTable
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.displayedColumns = result;
      }
    });
  }

}
