<h2 mat-dialog-title>Paramétrage</h2>
<mat-divider></mat-divider>
<mat-dialog-content>
  <ng-container [ngSwitch]="currentStep">

    <div *ngSwitchCase="'ANTSROUTE_AUTH'">
      <form [formGroup]="antsrouteAuth" autocomplete="off">
        <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
          <div fxFlex class="description">
            <mat-icon>settings</mat-icon><span class="aligned-with-icon">Veuillez vous authentifier avec votre compte
              Antsroute afin de lier vos comptes.</span>
          </div>
        </div>

        <div fxLayout="column" fxLayoutGap="1em" class="padding-top-1">
          <mat-form-field fxFlex class="full-width-input">
            <input matInput placeholder="{{ 'login.user' | translate }}" formControlName="userName" required>
            <mat-error *ngIf="firstStepHasError('userName', 'required')">{{'common.error.required' | translate}}
            </mat-error>
          </mat-form-field>
          <mat-form-field fxFlex class="full-width-input">
            <input matInput type="password" placeholder="{{ 'login.password' | translate }}" formControlName="password"
              required>
            <mat-error *ngIf="firstStepHasError('password', 'required')">{{'common.error.required' | translate}}
            </mat-error>
          </mat-form-field>
        </div>
      </form>
    </div>

    <div *ngSwitchCase="'ANTSROUTE_SITE'">
      <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
        <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
          <div fxFlex class="description">
            <mat-icon>settings</mat-icon><span class="aligned-with-icon">Merci de sélectionner le site Antsroute que
              vous souhaitez utiliser.</span>
          </div>
        </div>
      </div>

      <div fxLayout="column" fxLayoutGap="1em" class="padding-top-1">
        <mat-form-field fxFlex>
          <mat-label>Site Antsroute</mat-label>
          <mat-select [formControl]="antsrouteSiteId">
            <mat-option *ngFor="let site of sites" value="{{site.id}}">{{site.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="secondStepHasError('required')">{{'common.error.required' | translate}}
          </mat-error>
        </mat-form-field>
      </div>
    </div>

  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button *ngIf="currentStep === 'ANTSROUTE_AUTH'" mat-button cdkFocusInitial (click)="testAntsrouteAuth()" [disabled]="!antsrouteAuth.valid"
    class="button-blue">Suivant</button>

  <button *ngIf="currentStep === 'ANTSROUTE_SITE'" mat-button cdkFocusInitial (click)="setAntsrouteSiteId()" [disabled]="!antsrouteSiteId.valid"
    class="button-blue">Termine</button>
</mat-dialog-actions>
