import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';

import { AuthService } from '../shared/auth/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { AlertService } from '../shared/utils/alert.service';
import { ConfigurationModalComponent } from '../shared/configuration-modal/configuration-modal.component';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  private formSubmitAttempt: boolean;
  public loginSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private alert: AlertService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.form = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  onSubmit() {
    if (this.form.valid) {
      this.loginSubscription = this.authService.login(this.form.get('userName').value, this.form.get('password').value)
        .pipe(first())
        .subscribe(
          data => {
            if (this.authService.currentUserValue && ! this.authService.currentUserValue.configured) {
              const dialogConfig = new MatDialogConfig();
              dialogConfig.disableClose = true;
              dialogConfig.autoFocus = true;
              const dialogRef = this.dialog.open(ConfigurationModalComponent, dialogConfig);
            }
            this.router.navigate(['transport']);
          },
          error => {
            console.log(error);
            this.alert.error(error);
          });
    }
    this.formSubmitAttempt = true;
  }

  ngOnDestroy(): void {
    if (this.loginSubscription) {
      this.loginSubscription.unsubscribe();
    }
  }
}
