import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import {  CustomField } from '../resources/custom-fields.service';
import { CustomDisplayService } from '../utils/custom-display.service';
import { DisplayField } from '../warehouse/configuration.model';

export class Column {
  selected: string[];
  available: string[];
}

export class Columns {
  selected: string[];
  available: string[];
}

export type TABLE_TYPE = 'TRANSPORT_TABLE' | 'REPORT_TABLE';
export const TRANSPORT_TABLE: TABLE_TYPE = 'TRANSPORT_TABLE';
export const REPORT_TABLE: TABLE_TYPE = 'REPORT_TABLE';

@Injectable()
export class TableService {

  private defaultTransportHeaders = ['status', 'date', 'pickup', 'delivery', 'delivery-time', 'actions'];
  private defaultReportColumns = ['date', 'type', 'customer', 'address', 'satisfaction', 'time', 'actions'];

  constructor(private translate: TranslateService, private customDisplayService: CustomDisplayService) {
  }

  getTransportHeadersTables(tableType: TABLE_TYPE, customFields: CustomField[]): Columns {
    const transportHeaders = new BehaviorSubject<Columns>(JSON.parse(localStorage.getItem(tableType)));
    if (!transportHeaders.value) {
        const columns =  new Columns();
        if (tableType === TRANSPORT_TABLE) {
          columns.selected = Object.assign([], this.filterDefaultTransportHeaders());
        } else {
          columns.selected =  Object.assign([], this.defaultReportColumns);
        }

        columns.available = customFields.map(f => f.name);
        return columns;
    } else {
        let allPossibleColumns = [];
        if (tableType === TRANSPORT_TABLE) {
          allPossibleColumns = this.filterDefaultTransportHeaders();
        } else {
          allPossibleColumns = this.defaultReportColumns;
        }
        allPossibleColumns = allPossibleColumns.concat(customFields.map(f => f.name));

        transportHeaders.value.selected =
          transportHeaders.value.selected.filter(c => allPossibleColumns.findIndex(possible => possible === c ) !== -1);
        transportHeaders.value.available =
          allPossibleColumns.filter(c => transportHeaders.value.selected.findIndex(selected => selected === c) === -1);

        return transportHeaders.value;

    }
  }

  setTransportHeaders(tableType: TABLE_TYPE, columns: Columns) {
    localStorage.setItem(tableType, JSON.stringify(columns));
  }

  getHeaderName(column: string): string {
    const allDefaultHeaders = this.defaultTransportHeaders.concat(this.defaultReportColumns);
    if (allDefaultHeaders.findIndex(c => c === column) !== -1) {
      return this.translate.instant('common.table.' + column);
    } else {
      return column;
    }
  }

  filterDefaultTransportHeaders() {
    if (!this.customDisplayService.display(DisplayField.WAREHOUSE_TIMESLOT)) {
      this.defaultTransportHeaders = this.defaultTransportHeaders.filter(c => c !== 'pickup-time');
    }
    return this.defaultTransportHeaders;
  }
}
