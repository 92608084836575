import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserView, CreateUser, User } from './user';
import { HttpResourcesResponse } from '../utils/HttpResourcesResponse.model';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';

@Injectable()
export class UserService {

  constructor(private http: HttpClient, private translate: TranslateService, private authService: AuthService) {
  }

  createUser(createUser: CreateUser): Observable<UserView> {
    return this.http.post<UserView>('warehouse/api/accounts', createUser);
  }

  updateUser(email: string, updateUser: CreateUser): Observable<UserView> {
    return this.http.put<UserView>('warehouse/api/accounts/' + email, updateUser);
  }

  deleteUser(user: UserView): Observable<void> {
    return this.http.delete<void>('warehouse/api/accounts/' + encodeURI(user.email));
  }

  findUsers(page: number, size: number): Observable<HttpResourcesResponse<UserView>> {
    let  p = new HttpParams();
    p = p.append('page', String(page));
    p = p.append('size', String(size));
    return this.http.get<HttpResourcesResponse<UserView>>('warehouse/api/accounts', { params: p });
  }

  getUser(email: string): Observable<UserView> {
    return this.http.get<UserView>('warehouse/api/accounts/' + email);
  }

  getMyAccount(): Observable<UserView> {
    return this.http.get<UserView>('warehouse/api/my-account' );
  }

  updateMyAccount(updateUser: CreateUser): Observable<void> {
    return this.http.put<void>('warehouse/api/my-account', updateUser );
  }

  applyLanguage(language: string) {
    const user = this.authService.currentUserValue;
    if (user.configuration.terminology) {
      this.translate.use(user.configuration.terminology + "-" + language);
    } else {
      this.translate.use(language);
    }
  }

  public getAccountLabel(account: User): string {
    if (account.firstName || account.lastName) {
      let name = '';
      if (account.firstName) {
        name += account.firstName;
      }
      if (account.lastName) {
        if (name.length > 0) {
          name += ' ';
        }
        name += account.lastName;
      }
      return name;
    } else {
      return account.email;
    }
  }
}
