<div mat-dialog-content>
    <div fxLayout="column" fxLayoutGap="1em" class="margin-bottom-1">
        <div fxLayout="column" fxLayoutGap="1em" cdkDropList (cdkDropListDropped)="drop($event)">
              <mat-card *ngFor="let column of configurationTable.columns.selected" cdkDrag fxFlex class="column"
              [ngClass]="{
                'selected': isSelected(column),
                'not-selected': !isSelected(column)
              }" >
              <div fxLayout="row" *ngIf="column !== 'actions'">
                <div fxFlex class="name">
                    {{getName(column) | uppercase}}
                </div>
                <div fxFlex>
                    <button mat-icon-button (click)="changeColumn(column)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
              </div>
              </mat-card>
        </div>
        <ng-container *ngFor="let column of configurationTable.columns.available">
            <mat-card *ngIf="column !== 'actions'" fxFlex class="column"
            [ngClass]="{
              'selected': isSelected(column),
              'not-selected': !isSelected(column)
            }">
              <div fxLayout="row">
                <div fxFlex class="name">
                    {{getName(column) | uppercase}}
                </div>
                <div fxFlex>
                    <button mat-icon-button (click)="changeColumn(column)">
                        <mat-icon>add</mat-icon>
                    </button>
                </div>
              </div>
            </mat-card>
        </ng-container>
  </div>
</div>
<div mat-dialog-actions align="end">
    <button mat-button cdkFocusInitial class="button-blue" (click)="save()"><mat-icon class="button-icon">save</mat-icon>{{'common.save' | translate}}</button>
</div>
