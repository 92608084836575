import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { User, USER_ROLE, USER_ADMIN } from './user';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Site } from '../resources/site.model';

@Injectable()
export class AuthService {

  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient, private translate: TranslateService) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  login(email: string, password: string) {
    return this.http.post<User>('warehouse/auth', { email, password }).pipe(map(user => {
      if (user && user.token) {
        this.translate.setDefaultLang('fr');
        if (user.configuration && user.configuration.terminology) {
          this.translate.use(user.configuration.terminology + "-" + user.language);
        } else {
          this.translate.use(user.language);
        }
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
      }
      return user;
    }));
  }

  configureAntsrouteAuth(email: string, password: string): Observable<void> {
    return this.http.put<void>('warehouse/api/configure/antsroute-auth', { email, password });
  }

  getAntsrouteSites(): Observable<Site[]> {
    return this.http.get<Site[]>('warehouse/api/configure/antsroute-sites');
  }

  configureAntsrouteSite(site: Site): Observable<void> {
    return this.http.put<void>('warehouse/api/configure/antsroute-site', site);
  }

  configurationDone() {
    this.currentUserValue.configured = true;
    localStorage.setItem('currentUser', JSON.stringify(this.currentUserValue));

  }

  getIcon() {
    return this.currentUserValue.icon;
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  isAdmin(): boolean {
    return this.currentUserValue.role === USER_ADMIN;
  }

  isConfigured(): boolean {
    return this.currentUserValue.configured;
  }

  isStoreAsDefaultLoading(): boolean {
    return this.currentUserValue.configuration.storeAsDefaultLoading;
  }

  isItemsEnable(): boolean {
    return this.currentUserValue.configuration.enableItems;
  }
}
