import {Component, OnInit, ViewChild, AfterViewInit} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import * as _moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {merge, of as observableOf} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import {OrderService} from 'src/app/shared/orders/orders.service';
import { MomentDateAdapter} from '@angular/material-moment-adapter';
import {DONE, LightOrder} from '../../shared/orders/light-order.model';
import {Router} from '@angular/router';
import { DATE_FORMATS } from 'src/app/app.component';
import { TableService, REPORT_TABLE } from 'src/app/shared/configuration-table-modal/table.service';
import { CustomFieldsService, CustomField } from 'src/app/shared/resources/custom-fields.service';
import { Chart, ChartService } from '../../shared/chart/chart.services';
import {
  ConfigurationTable,
  ConfigurationTableModalComponent
} from 'src/app/shared/configuration-table-modal/configuration-table-modal.component';
import { WarehouseService } from 'src/app/shared/warehouse/warehouse.service';

const moment = _moment;

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class ReportsComponent implements OnInit, AfterViewInit {
  displayedColumns = ['date', 'type', 'customer', 'address', 'satisfaction', 'time', 'actions'];
  data: MatTableDataSource<LightOrder> = new MatTableDataSource<LightOrder>();
  resultsLength = 0;
  isLoadingResults = true;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  minDate = new UntypedFormControl(moment().subtract(1, 'months'));
  maxDate = new UntypedFormControl(moment());
  customFields: Array<CustomField> = [];

  charts: Chart[] = [];
  numberCharts: Chart[] = [];
  cubeJsToken: string;
  loadingChart: boolean;

  constructor(
    private orderService: OrderService, private router: Router, public dialog: MatDialog,
    private tableService: TableService, private customFieldService: CustomFieldsService,
    private chartService: ChartService, private warehouseService: WarehouseService) {
  }

  ngOnInit() {
    this.loadingChart = true;
    this.chartService.getCubeJsToken().subscribe(cubeJsToken => {
      this.cubeJsToken = cubeJsToken;
    });

    this.customFieldService.findCustomFields().subscribe(categories => {
      this.customFields = [];
      categories.forEach(category => {
        this.customFields.push(...category.customFields);
      });
      this.displayedColumns = this.tableService.getTransportHeadersTables(REPORT_TABLE, this.customFields).selected;
    });
  }

  ngAfterViewInit() {
    merge(this.paginator.page)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;
          return this.orderService.findAllLightOrdersByState(
            this.paginator.pageIndex, this.paginator.pageSize,
            this.minDate.value.format('YYYY-MM-DD'), this.maxDate.value.format('YYYY-MM-DD'), [DONE]);
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.resultsLength = data.totalElements;
          this.renderChart();
          return data.elements;
        }),
        catchError(() => {
          this.isLoadingResults = false;
          return observableOf([]);
        })
      ).subscribe(data => this.data.data = data);
  }

  renderChart(): void {
    this.warehouseService.findWarehouses(0, 1000).subscribe(warehouses => {
      const warehouseCustomerIds = warehouses.elements.map(w => String(w.customerId));
      this.charts = [];
      this.numberCharts = [];
      this.charts.push(this.chartService.getPlannedOrderByDateAndState(this.minDate.value.format('YYYY-MM-DD'),
        this.maxDate.value.format('YYYY-MM-DD'), warehouseCustomerIds));
      this.numberCharts.push(this.chartService.getTotalNumberOfDoneOrder(this.minDate.value.format('YYYY-MM-DD'),
        this.maxDate.value.format('YYYY-MM-DD'), warehouseCustomerIds));
      this.loadingChart = false;
    });
  }

  minDateChange() {
    if (this.minDate.value.isAfter(this.maxDate.value)) {
      this.maxDate.setValue(this.minDate.value);
    }
    this.paginator.firstPage();
    this.updateData();
  }

  maxDateChange() {
    if (this.maxDate.value.isBefore(this.minDate.value)) {
      this.minDate.setValue(this.maxDate.value);
    }
    this.paginator.firstPage();
    this.updateData();
  }

  private updateData() {
    this.orderService.findAllLightOrdersByState(
      this.paginator.pageIndex, this.paginator.pageSize,
      this.minDate.value.format('YYYY-MM-DD'), this.maxDate.value.format('YYYY-MM-DD'), [DONE])
      .subscribe(res => {
        this.data.data = res.elements;
        this.resultsLength = res.totalElements;
        this.renderChart();
      });
  }

  goToDetails(order: any) {
    this.router.navigate([this.router.url + '/' + order.orderId]);
  }

  getFieldValue(lightOrder: LightOrder, field: CustomField): string {
    if (lightOrder.fields.findIndex(f => f.field.id === field.id) !== -1) {
      return lightOrder.fields.find(f => f.field.id === field.id).value;
    }
    return '';
  }

  configureTable() {
    const configureTable = new ConfigurationTable();
    configureTable.tableType =  REPORT_TABLE;
    configureTable.columns = this.tableService.getTransportHeadersTables(REPORT_TABLE, this.customFields);
    const dialogRef = this.dialog.open(ConfigurationTableModalComponent, {
      width: '350px',
      disableClose: true,
      data: configureTable
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.displayedColumns = result;
      }
    });
  }
}
