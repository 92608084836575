import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class AlertService {

  constructor(private snackBar: MatSnackBar, private translate: TranslateService) {
  }

  info(translationKey: string) {
    this.snackBar.open(
      this.translate.instant(translationKey),
      null, { panelClass: ['snackbar-info'],
      duration: 3000,
      verticalPosition: 'top'});
  }

  error(error: any) {
    let message = this.translate.instant('common.error.common');
    if (error.status && error.status === 409) {
        message = this.translate.instant('common.error.errorCode.' + error.error.errorCode);
    } else if (error.status && error.status === 403) {
        message = this.translate.instant('common.error.forbidden');
    } else if (error.status && error.status === 401) {
        message = this.translate.instant('common.error.unauthorized');
    }
    this.snackBar.open(
      message,
      null, { panelClass: ['snackbar-error'],
      duration: 3000,
      verticalPosition: 'top'});
  }
}
