<div class="card std-shadow">
	<div class="card-body">
		<h5 class="card-title">{{ getTitle() }}</h5>
		<div class="card-text">
			<div *ngIf="ready === false" class="d-flex justify-content-center text-dark">
				<div class="spinner-border" role="status">
					<span class="sr-only">{{'reports.chart.chart-loading' | translate }}</span>
				</div>
			</div>
			<canvas *ngIf="ready && showChart && chartData.length !== 0" baseChart height="200" [datasets]="chartData" [labels]="chartLabels" [options]="chartOptions"
			   [chartType]="chartType" [colors]="chartColors" >
      </canvas>
      <h1 *ngIf="ready && showChart && chartData.length === 0" height="200">{{'reports.chart.no-data' | translate }}</h1>
			<h1 *ngIf="ready && !showChart" height="150">{{ chartData }}</h1>
		</div>
	</div>
</div>
