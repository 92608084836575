import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { User, CreateUser, USER_ROLE } from 'src/app/shared/auth/user';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/auth/auth.service';
import { UntypedFormControl, Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UserService } from 'src/app/shared/auth/user.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomersService } from 'src/app/shared/customers/customers.service';
import { CustomerView } from 'src/app/shared/customers/customer.model';
import { debounceTime, switchMap, finalize, tap } from 'rxjs/operators';
import { CreateWarehouse } from 'src/app/shared/warehouse/warehouse.model';
import { WarehouseService } from 'src/app/shared/warehouse/warehouse.service';
import { AlertService } from 'src/app/shared/utils/alert.service';
import { TimeSlot } from 'src/app/shared/orders/timeslot.model';
import { OrderService } from 'src/app/shared/orders/orders.service';
import { Subscription } from 'rxjs';

export interface UpdateWarehouse {
  id: number;
}

@Component({
  selector: 'app-shop-create',
  templateUrl: './createshop.component.html',
})
export class CreateShopComponent implements OnInit, OnDestroy {

  timeslots: TimeSlot[] = [];
  filteredCustomers: CustomerView[] = [];
  shopForm: UntypedFormGroup;
  customerSelected = false;
  isLoading = false;
  isUpdate = false;
  createWarehouseSubscription: Subscription;

  constructor(
    private fb: UntypedFormBuilder, public dialogRef: MatDialogRef<CreateShopComponent>, private customersService: CustomersService,
    private warehouseService: WarehouseService, private alert: AlertService, private orderService: OrderService,
    @Inject(MAT_DIALOG_DATA) public data: UpdateWarehouse) { }

  ngOnInit() {
    this.shopForm = this.fb.group({
      customerInput: null,
      address: null,
      defaultLoadingTime: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      defaultLoadingTimeSlotId: new UntypedFormControl('', [Validators.required]),
      defaultUnloadingTime: new UntypedFormControl('', [Validators.required, Validators.pattern('[0-9]*')]),
      defaultUnloadingTimeSlotId: new UntypedFormControl('', [Validators.required])
    });
    this.shopForm.get('address').disable();
    this.shopForm
      .get('customerInput')
      .valueChanges
      .pipe(
        debounceTime(300),
        tap(() => this.isLoading = true),
        switchMap(value => this.customersService.findAllCustomers(0, 20, value)
          .pipe(
            finalize(() => this.isLoading = false)
          )
        )
      )
      .subscribe(results => this.filteredCustomers = results.elements);

    this.orderService.findTimeslots().subscribe(res => {
      this.timeslots = res;
    });

    if (this.data && this.data.id) {
      this.isUpdate = true;
      this.warehouseService.findWarehouse(this.data.id).subscribe(
        warehouse => {
          this.customersService.findCustomer(warehouse.customerId).subscribe(customer => {
            this.customerSelected = true;
            this.shopForm.get('customerInput').setValue(customer);
            this.shopForm.get('customerInput').disable();
            this.shopForm.get('address').setValue(warehouse.address);
            this.shopForm.get('address').disable();
            this.shopForm.get('defaultLoadingTime').setValue(warehouse.defaultLoadingTime);
            this.shopForm.get('defaultLoadingTimeSlotId').setValue(String(warehouse.defaultLoadingTimeSlotId));
            this.shopForm.get('defaultUnloadingTime').setValue(warehouse.defaultUnloadingTime);
            this.shopForm.get('defaultUnloadingTimeSlotId').setValue(String(warehouse.defaultUnLoadingTimeSlotId));
          });
        }
      );
    }

    this.shopForm.get('customerInput').valueChanges.subscribe(
      val => {
        if (val.customerId) {
          this.customerSelected = true;
          this.shopForm.get('address').setValue(val.address);
        } else {
          this.customerSelected = false;
        }
      });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.shopForm.controls[controlName].hasError(errorName);
  }

  displayFn(customer: CustomerView) {
    if (customer) { return customer.lastName + ' ' + (customer.firstName !== null ? customer.firstName : ''); }
  }

  save(createWarehouse: CreateWarehouse) {
    if (! this.isUpdate) {
      createWarehouse.customerId = this.shopForm.get('customerInput').value.customerId;
      this.createWarehouseSubscription = this.warehouseService.createWarehouse(createWarehouse).subscribe(res => {
        this.dialogRef.close(true);
      }, err => {
        this.alert.error(err);
      });
    } else {
      this.createWarehouseSubscription = this.warehouseService.updateWarehouse(this.data.id, createWarehouse).subscribe(res => {
        this.dialogRef.close(true);
      }, err => {
        this.alert.error(err);
      });
    }
  }

  ngOnDestroy(): void {
    if (this.createWarehouseSubscription) {
      this.createWarehouseSubscription.unsubscribe();
    }
  }
}
