import {Injectable} from '@angular/core';
import {OrderType, OrderTypeService} from './order-type.service';
import {CapacityType, CapacityTypeService} from './capacity-type.service';
import {Skill, SkillService} from './skill.service';
import {TimeSlot, TimeSlotService} from './time-slot.service';
import {forkJoin, Observable} from 'rxjs';
import { CustomFieldsService, CustomField, CustomFieldCategory } from './custom-fields.service';

@Injectable({
  providedIn: 'root'
})
export class ResourcesService {

  constructor(private orderTypeService: OrderTypeService, private capacityTypeService: CapacityTypeService,
              private skillService: SkillService, private timeSlotService: TimeSlotService, private customFields: CustomFieldsService) {
  }

  getAllResources(): Observable<[OrderType[], Skill[], CapacityType[], TimeSlot[], CustomFieldCategory[]]> {
    return forkJoin<OrderType[], Skill[], CapacityType[], TimeSlot[], CustomFieldCategory[]>([
      this.orderTypeService.findOrderTypes(),
      this.skillService.findSkills(),
      this.capacityTypeService.findCapacityTypes(),
      this.timeSlotService.findTimeSlots(),
      this.customFields.findCustomFields()]
    );
  }
}
