import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { AuthService } from '../auth/auth.service';
import { AlertService } from '../utils/alert.service';
import { Site } from '../resources/site.model';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';

export type Step = 'ANTSROUTE_AUTH' | 'ANTSROUTE_SITE';
export const ANTSROUTE_AUTH: Step = 'ANTSROUTE_AUTH';
export const ANTSROUTE_SITE: Step = 'ANTSROUTE_SITE';

@Component({
  selector: 'app-configuration-modal',
  templateUrl: './configuration-modal.component.html',
  styleUrls: ['./configuration-modal.component.scss']
})
export class ConfigurationModalComponent implements OnInit {

  antsrouteAuth: UntypedFormGroup;
  currentStep: Step = ANTSROUTE_AUTH;
  sites: Site[] = [];

  antsrouteSiteId = new UntypedFormControl('', Validators.required);

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private alert: AlertService,
    private router: Router,
    public dialogRef: MatDialogRef<ConfigurationModalComponent>) { }

  ngOnInit() {
    this.antsrouteAuth = this.fb.group({
      userName: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  public firstStepHasError = (controlName: string, errorName: string) => {
    return this.antsrouteAuth.controls[controlName].hasError(errorName);
  }

  public secondStepHasError = (errorName: string) => {
    return this.antsrouteSiteId.hasError(errorName);
  }

  testAntsrouteAuth() {
    this.authService.configureAntsrouteAuth(this.antsrouteAuth.get('userName').value, this.antsrouteAuth.get('password').value).subscribe(
      () => {
        this.authService.getAntsrouteSites().subscribe(res => {
          this.sites = res;
          this.currentStep = ANTSROUTE_SITE;
        });
      },
      error => {
        this.alert.error(error);
      }
    );
  }

  setAntsrouteSiteId() {
    const antsrouteSite = this.sites.find(s => s.id === Number(this.antsrouteSiteId.value));
    this.authService.configureAntsrouteSite(antsrouteSite).subscribe(() => {
      this.authService.configurationDone();
      this.router.navigate(['/settings/shops']);
      this.dialogRef.close();
    },
    err => {
      this.alert.error(err);
    });
  }
}
