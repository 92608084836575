export class CustomerView {

  customerId?: number;

  siteId?: number;

  externalId?: string;

  firstName?: string;

  lastName?: string;

  phoneNumber?: string;

  mobileNumber?: string;

  email?: string;

  comments?: string;

  privateComments?: string;

  address?: string;

  latitude?: number;

  longitude?: number;

  altitude?: number;

  openingHours: [];
}
