import { CustomDisplayService } from './../utils/custom-display.service';
import { DisplayField } from 'src/app/shared/warehouse/configuration.model';
import { Component, Input, OnInit } from '@angular/core';
import { CustomerView } from './customer.model';
import { LightOrder } from '../orders/light-order.model';

@Component({
  selector: 'app-customer-table',
  templateUrl: './customer-table-display.component.html',
  styleUrls: ['./customer-table-display.component.scss']
})
export class CustomerTableDisplayComponent implements OnInit {

  @Input() order: LightOrder;
  @Input() serviceType: string;

  customer: CustomerView = new CustomerView();

  constructor(private customDisplayService: CustomDisplayService) {
  }

  ngOnInit() {
    if (this.order.serviceType === this.serviceType) {
      this.customer = this.order.customer;
    } else {
      this.customer = this.order.child.customer;
    }
  }

  getPhoneNumber(): string {
    if (this.customer.mobileNumber && this.customer.mobileNumber !== '') {
      return this.customer.mobileNumber;
    } else if (this.customer.phoneNumber && this.customer.phoneNumber !== '') {
      return this.customer.phoneNumber;
    } else {
      return '';
    }
  }

  displayAddress(): boolean {
    if (this.order.serviceType === this.serviceType) {
      return true;
    } else {
      return this.customDisplayService.display(DisplayField.WAREHOUSE_ADDRESS);
    }
  }
}
