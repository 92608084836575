<h2 *ngIf="!isUpdate" mat-dialog-title>{{'customers.add-customer' | translate}}</h2>
<h2 *ngIf="isUpdate" mat-dialog-title>{{'customers.edit-customer' | translate}}</h2>
<mat-divider></mat-divider>
<form [formGroup]="customerFormGroup" autocomplete="off" novalidate (ngSubmit)="save(customerFormGroup.value)">

  <mat-dialog-content>

    <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput placeholder="{{'model.user.last-name' | translate}}" formControlName="lastName" required>
          <mat-error *ngIf="hasError('lastName', 'required')">{{'common.error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput placeholder="{{'model.user.first-name' | translate}}" formControlName="firstName">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex>
        <mat-form-field style="width: 100%;">

          <input
            matInput
            id="location"
            name="location"
            class="form-control"
            required="true"
            formControlName="address"
            (ngModelChange)="locationModelChanged.next($event)"
            placeholder="{{'common.table.address' | translate}}"
            [matAutocomplete]="auto"
            maxlength="255"
          />
          <mat-autocomplete #auto="matAutocomplete" (opened)="eventOpened()" (closed)="eventClosed()">
            <mat-option *ngFor="let location of locations" [value]="location.description" (onSelectionChange)="updateLocation(location)">
              <mat-icon>location_on</mat-icon>
              <span>{{ location.description }}</span>
            </mat-option>
          </mat-autocomplete>

          <mat-error *ngIf="hasError('address', 'required')">{{'common.error.required' | translate}}</mat-error>
          <mat-error *ngIf="hasError('address', 'badAddress')">{{'common.error.required' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <ngx-mat-intl-tel-input
            name="mobileNumber"
            [onlyCountries]="['fr']"
            placeholder="{{'common.table.mobile-phone' | translate}}"
            formControlName="mobileNumber">
          </ngx-mat-intl-tel-input>
          <mat-error *ngIf="hasError('mobileNumber', 'validatePhoneNumber') && !invalidNumber">
            {{'common.error.phone-number-format' | translate}}
          </mat-error>
          <mat-error *ngIf="hasError('mobileNumber', 'validatePhoneNumber') && invalidNumber">
            {{'common.error.phone-number-incorrect' | translate : {value: invalidNumber} }}
          </mat-error>
        </mat-form-field>
      </div>
      <div fxFlex>
        <mat-form-field style="min-width: 300px;">
          <input matInput placeholder="{{'common.table.landline-phone' | translate}}" formControlName="phoneNumber">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex>
        <mat-form-field style="width: 100%;">
          <input matInput placeholder="{{'model.user.email' | translate}}" formControlName="email">
          <mat-error *ngIf="hasError('email', 'required')">{{'common.error.required' | translate}}</mat-error>
          <mat-error *ngIf="hasError('email', 'email')">{{'common.error.email' | translate}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayoutGap="2em">
      <div fxFlex>
        <mat-form-field style="width: 100%;">
          <input matInput placeholder="{{'common.table.comment' | translate}}" formControlName="comments">
        </mat-form-field>
      </div>
    </div>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="warn" mat-dialog-close
            class="margin-right-2">{{'common.cancel' | translate}}</button>

    <button mat-raised-button *ngIf="!isUpdate" class="button-blue" type="submit" [promiseBtn]="addCustomerSubscription"
            [disabled]="!customerFormGroup.valid || !isAddressCorrectlySelected">{{'customers.add-button' | translate}}</button>
    <button mat-raised-button *ngIf="isUpdate" class="button-blue" type="submit" [promiseBtn]="addCustomerSubscription"
            [disabled]="!customerFormGroup.valid || !isAddressCorrectlySelected">{{'customers.edit-button' | translate}}</button>
  </mat-dialog-actions>

</form>
