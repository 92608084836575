<h2 mat-dialog-title>{{'orders.book-transport' | translate }}</h2>
<mat-divider></mat-divider>
<mat-dialog-content>

  <ng-container [ngSwitch]="currentStep">
    <div *ngSwitchCase="'SCORING_IN_PROGRESS'">
        <div fxLayout="row" fxLayoutGap="2em" class="scoring padding-top-1">
            <div fxFlex="0 1 calc(33.3% - 2em)"></div>
            <div fxFlex="0 1 calc(33.3% - 2em)">
                <mat-spinner></mat-spinner>
            </div>
            <div fxFlex="0 1 calc(33.3% - 2em)"></div>
        </div>

    </div>
    <div *ngSwitchCase="'CHOOSE_SCORE'">
      <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
        <div fxFlex>
          <mat-card-header class="description">
            <div mat-card-avatar>
              <mat-icon>calendar_today</mat-icon>
            </div>
            <mat-card-title>{{'orders.score-and-save.choose-time-slot' | translate}}</mat-card-title>
          </mat-card-header>
        </div>
      </div>
      <div fxLayout="row wrap" class="padding-bottom-1">
          <div fxFlex class="score-timeout">
              {{'orders.score-and-save.timeout' | translate}}
              <span class="timeout"><countdown [config]="countdownConfig" (finished)="discardScoreOrder()"></countdown></span>
              {{'orders.score-and-save.seconds' | translate}}
          </div>
      </div>
      <div fxLayout="row wrap" fxLayoutGap="2em" class="padding-bottom-1">
          <div fxFlex class="margin-top-1">
              <mat-icon *ngIf="page !== 0" (click)="previousPage()" class="score-back">arrow_back_ios</mat-icon>
          </div>
        <ng-container *ngFor="let key of objectKeys(scores)">
          <div fxFlex class="margin-top-1">
            <div fxLayout="column" fxLayoutGap="1em">
              <div fxFlex class="scoreDate">
                  <span > {{key}}</span>
              </div>
              <ng-container *ngFor="let score of scores[key]">
                <div fxFlex  class="time-slot" [class.selected]="score.selected" (click)="selectScore(score)">
                  <span style="white-space: nowrap;"> {{score.timeSlot.from | slice:0:5}} - {{score.timeSlot.to | slice:0:5}}</span>
                </div>
              </ng-container>
              <div fxFlex *ngIf="scores[key] === undefined || scores[key].length === 0" style="text-align: center;">
                {{'orders.score-and-save.no-availabilities' | translate}}
              </div>
            </div>
          </div>
        </ng-container>
        <div fxFlex class="margin-top-1">
            <mat-icon class="score-next"  (click)="nextPage()">arrow_forward_ios</mat-icon>
        </div>
      </div>
    </div>



  </ng-container>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="warn" (click)="close()" mat-dialog-close
    class="margin-right-2">{{'common.cancel' | translate}}</button>

  <button *ngIf="currentStep === 'CHOOSE_SCORE'" mat-raised-button [disabled]="!this.selectedScore" class="button-blue" type="button"
    [promiseBtn]="saveTransportSubscription" (click)="saveTransport()">{{'common.confirm' | translate}}</button>

</mat-dialog-actions>
