import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';


export class TimeSlot {
  id: number;
  from: string;
  to: string;
}

@Injectable({
  providedIn: 'root'
})
export class TimeSlotService {

  constructor(private http: HttpClient) {
  }

  public findTimeSlots(): Observable<Array<TimeSlot>> {
    return this.http.get<Array<TimeSlot>>('warehouse/api/time-slots');
  }
}
