import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

export enum CustomFieldType {
  STRING = "STRING",
  DATE = "DATE",
  INTEGER = "INTEGER",
  BOOLEAN = "BOOLEAN",
  SELECT_UNIQUE = "SELECT_UNIQUE",
  SELECT_MULTIPLE = "SELECT_MULTIPLE",
  PHOTO = "PHOTO",
}

export enum CustomFieldObject {
  CUSTOMER = "CUSTOMER",
  ORDER = "ORDER",
  MOBILE = "MOBILE",
}

export class CustomField {
  id: number;
  name: string;
  type: CustomFieldType;
  object: CustomFieldObject;
  category: CustomFieldCategory;
  selectOptions: string[];

  // not use in API, just for Form
  value: any;
  dateValue: any;
}

export class CustomFieldValue {
  field: CustomField;
  type: CustomFieldType;
  value: any;
}

export class CustomFieldCategory {
  id: number;
  name: string;
  object: CustomFieldObject;
  customFields: CustomField[];
}

export class OrderCustomFields {
  fields?: CustomFieldValue[];
}

@Injectable({
  providedIn: "root",
})
export class CustomFieldsService {
  constructor(private http: HttpClient) {}

  public findCustomFields(): Observable<Array<CustomFieldCategory>> {
    return this.http.get<Array<CustomFieldCategory>>(
      "warehouse/api/custom-fields"
    );
  }

  onChangeOrderField(
    order: OrderCustomFields,
    customerField: CustomField,
    value: string | number | string[]
  ): void {
    if (value) {
      let customerFieldValue = order.fields.find(
        (item) => item.field.id === customerField.id
      );
      if (!customerFieldValue) {
        customerFieldValue = new CustomFieldValue();
        customerFieldValue.field = customerField;
        customerFieldValue.value = value;
        customerFieldValue.type = customerField.type;
        order.fields.push(customerFieldValue);
      } else {
        customerFieldValue.value = value;
      }
    } else {
      order.fields = order.fields.filter(
        (item) => item.field.id !== customerField.id
      );
    }
  }

  onChangeDateOrderField(
    order: OrderCustomFields,
    customerField: CustomField,
    event: Date
  ): void {
    if (event) {
      let customerFieldValue = order.fields.find(
        (item) => item.field.id === customerField.id
      );
      if (!customerFieldValue) {
        customerFieldValue = new CustomFieldValue();
        customerFieldValue.field = customerField;
        customerFieldValue.value = this.formatDate(event);
        customerFieldValue.type = customerField.type;
        order.fields.push(customerFieldValue);
      } else {
        customerFieldValue.value = this.formatDate(event);
      }
    } else {
      order.fields = order.fields.filter(
        (item) => item.field.id !== customerField.id
      );
    }
  }

  onChangeBooleanOrderField(
    order: OrderCustomFields,
    orderField: CustomField,
    checked: boolean
  ) {
    if (checked) {
      let orderFieldValue = order.fields.find(
        (item) => item.field.id === orderField.id
      );
      if (!orderFieldValue) {
        orderFieldValue = new CustomFieldValue();
        orderFieldValue.field = orderField;
        orderFieldValue.value = checked;
        orderFieldValue.type = orderField.type;
        order.fields.push(orderFieldValue);
      } else {
        orderFieldValue.value = checked;
      }
    } else {
      order.fields = order.fields.filter(
        (item) => item.field.id !== orderField.id
      );
    }
  }

  private formatDate(date: Date): string {
    const options: Intl.NumberFormatOptions = { minimumIntegerDigits: 2 };
    return (
      date.getFullYear() +
      "-" +
      (date.getMonth() + 1).toLocaleString("en-Us", options) +
      "-" +
      date.getDate().toLocaleString("en-Us", options)
    );
  }
}
