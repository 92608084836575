<div class="padding-1">
  <ng-container *ngIf="!isLoading">
    <form [formGroup]="orderDetailsFormGroup" autocomplete="off">

      <div fxLayout="row" class="padding-top-1">
        <div fxFlex>
          <button mat-raised-button class="button-green" (click)="goBack()">
            <mat-icon class="button-icon">chevron_left</mat-icon>
            {{ 'common.back' | translate}}
          </button>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="1em" fxFill class="padding-top-1">
        <div fxFlex>
          <mat-card>
            <mat-card-content>
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em">
                <div fxFlex="40">
                  <div *ngIf="isEditable" fxLayout="row" fxLayoutGap="1em" class="padding-top-1">
                    <mat-form-field fxFlex="33">
                      <input matInput [min]="today" [matDatepicker]="min" placeholder="{{ 'common.from' | translate }}"
                        [formControl]="minDate" (dateChange)="minDateChange()">
                      <mat-datepicker-toggle matSuffix [for]="min"></mat-datepicker-toggle>
                      <mat-datepicker #min></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field fxFlex="33">
                      <input matInput [min]="today" [matDatepicker]="max" placeholder="{{ 'common.to' | translate }}"
                        [formControl]="maxDate" (dateChange)="maxDateChange()">
                      <mat-datepicker-toggle matSuffix [for]="max"></mat-datepicker-toggle>
                      <mat-datepicker #max></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field fxFlex="33" [appearance]="appearance">
                      <mat-label>{{ 'orders.details.time-slot' | translate }}</mat-label>
                      <mat-select formControlName="customerTimeSlotId">
                        <mat-option *ngFor="let timeslot of timeSlots" value="{{timeslot.id}}">
                          {{timeslot.from | slice:0:5}} - {{timeslot.to | slice:0:5}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div *ngIf="!isEditable" fxLayout="row" fxLayoutGap="1em" class="padding-top-1">
                    <mat-form-field fxFlex="33" [appearance]="appearance">
                      <mat-label>{{'orders.details.date' | translate}}</mat-label>
                      <input matInput [matDatepicker]="scheduleDate" placeholder="{{'orders.details.date' | translate}}"
                        formControlName="scheduledDate">
                      <mat-datepicker-toggle matSuffix [for]="scheduleDate"></mat-datepicker-toggle>
                      <mat-datepicker #scheduleDate></mat-datepicker>
                    </mat-form-field>

                    <mat-form-field fxFlex="33" [appearance]="appearance">
                      <mat-label>{{ 'orders.details.time-slot' | translate }}</mat-label>
                      <mat-select formControlName="customerTimeSlotId">
                        <mat-option *ngFor="let timeslot of timeSlots" value="{{timeslot.id}}">
                          {{timeslot.from | slice:0:5}} - {{timeslot.to | slice:0:5}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>

                <div fxFlex="60">
                  <app-stepper [order]="orderSaved"></app-stepper>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em" fxFill class="padding-top-1">
        <div fxFlex [fxFlexOrder]="(orderSaved.serviceType === 'DELIVERY' && isStoreAsDefaultLoading) || (orderSaved.serviceType === 'PICKUP' && !isStoreAsDefaultLoading) ? 1 : 3">
          <mat-card class="shop-card">
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon>room</mat-icon>
              </div>
              <mat-card-title *ngIf="orderSaved.serviceType === 'DELIVERY' && isStoreAsDefaultLoading">{{ 'orders.details.loading' | translate }}: {{ 'orders.details.shop.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="orderSaved.serviceType === 'PICKUP' && isStoreAsDefaultLoading">{{ 'orders.details.unloading' | translate }}: {{ 'orders.details.shop.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="orderSaved.serviceType === 'DELIVERY' && !isStoreAsDefaultLoading">{{ 'orders.details.delivery' | translate }}: {{ 'orders.details.shop.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="orderSaved.serviceType === 'PICKUP' && !isStoreAsDefaultLoading">{{ 'orders.details.pickup' | translate }}: {{ 'orders.details.shop.title' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="padding-top-1">
              <div fxLayout="row" fxLayoutGap="2em">
                <div fxFlex>
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{ 'orders.details.shop.name' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'orders.details.shop.name' | translate }}"
                      formControlName="shopName">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="2em" *ngIf="displayField(DisplayField.WAREHOUSE_ADDRESS)">
                <div fxFlex>
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{ 'orders.details.shop.address' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'orders.details.shop.address' | translate }}"
                      formControlName="shopAddress">
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="2em"
                *ngIf="displayField(DisplayField.WAREHOUSE_TIMESLOT)">
                <mat-form-field fxFlex [appearance]="appearance">
                  <mat-label>{{ (orderSaved.serviceType === 'DELIVERY' ? 'model.shop.loading-timeslot' :
                    'model.shop.unloading-timeslot') | translate }}</mat-label>
                  <mat-select formControlName="warehouseTimeSlotId">
                    <mat-option *ngFor="let timeslot of timeSlots" value="{{timeslot.id}}">
                      {{timeslot.from | slice:0:5}} - {{timeslot.to | slice:0:5}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div fxFlex="24px" fxFlexOrder="2">
          <mat-icon class="swap">swap_horiz</mat-icon>
        </div>

        <div fxFlex [fxFlexOrder]="(orderSaved.serviceType === 'DELIVERY' && isStoreAsDefaultLoading) || (orderSaved.serviceType === 'PICKUP' && !isStoreAsDefaultLoading) ? 3 : 1">
          <mat-card class="customer-card">
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon>room</mat-icon>
              </div>
              <mat-card-title *ngIf="orderSaved.serviceType === 'DELIVERY' && isStoreAsDefaultLoading">{{ 'orders.details.delivery' | translate }}: {{ 'orders.details.customer.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="orderSaved.serviceType === 'PICKUP' && isStoreAsDefaultLoading">{{ 'orders.details.pickup' | translate}}: {{ 'orders.details.customer.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="orderSaved.serviceType === 'DELIVERY' && !isStoreAsDefaultLoading">{{ 'orders.details.loading' | translate }}: {{ 'orders.details.customer.title' | translate }}</mat-card-title>
              <mat-card-title *ngIf="orderSaved.serviceType === 'PICKUP' && !isStoreAsDefaultLoading">{{ 'orders.details.unloading' | translate}}: {{ 'orders.details.customer.title' | translate }}</mat-card-title>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="padding-top-1">
              <div fxLayout="row" fxLayoutGap="2em">
                <div fxFlex>
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{ 'orders.details.customer.name' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'orders.details.customer.name' | translate }}"
                      formControlName="customerName">
                  </mat-form-field>
                </div>
              </div>
              <div fxLayout="row" fxLayoutGap="2em">
                <div fxFlex>
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{ 'orders.details.customer.address' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'orders.details.customer.address' | translate }}"
                      formControlName="customerAddress">
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayoutGap="2em">
                <div fxFlex>
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{ 'common.table.mobile-phone' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'common.table.mobile-phone' | translate }}"
                      formControlName="customerMobilePhone">
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{ 'orders.details.customer.mail' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'orders.details.customer.mail' | translate }}"
                      formControlName="customerMail">
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row">
                <div fxFlex>
                  <mat-form-field [appearance]="appearance">
                    <mat-label>{{ 'orders.details.customer.comment' | translate }}</mat-label>
                    <input matInput placeholder="{{ 'orders.details.customer.comment' | translate }}"
                      formControlName="customerComment">
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
        <div fxFlex>
          <mat-card class="transport-card">
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon>local_shipping</mat-icon>
              </div>
              {{ 'orders.details.title' | translate }}
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="padding-top-1">
              <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em">
                <div fxFlex>
                  <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="1em">
                    <mat-form-field fxFlex [appearance]="appearance"
                      *ngIf="(orderSaved.serviceType === 'DELIVERY' && displayField(DisplayField.CUSTOMER_DELIVERY_DURATION) ) || (orderSaved.serviceType === 'PICKUP' && displayField(DisplayField.CUSTOMER_PICKUP_DURATION) )">
                      <mat-label>{{ (orderSaved.serviceType === 'DELIVERY' ? 'orders.details.delivery-time' :
                        'orders.details.pickup-time') | translate }}</mat-label>
                      <input matInput
                        placeholder="{{ (orderSaved.serviceType === 'DELIVERY' ? 'orders.details.delivery-time' : 'orders.details.pickup-time') | translate }}"
                        formControlName="customerDuration" required pattern="[0-9]*">
                      <span matSuffix>min</span>
                      <mat-error *ngIf="hasError('customerDuration', 'required')">{{'common.error.required' |
                        translate}}
                      </mat-error>
                      <mat-error *ngIf="hasError('customerDuration', 'pattern')">{{'common.error.integer' | translate}}
                      </mat-error>
                    </mat-form-field>
                    <mat-form-field fxFlex [appearance]="appearance"
                      *ngIf="displayField(DisplayField.WAREHOUSE_DURATION)">
                      <mat-label>{{ (orderSaved.serviceType === 'DELIVERY' ? 'orders.details.loading-time' :
                        'orders.details.unloading-time') | translate }}</mat-label>
                      <input matInput
                        placeholder="{{ (orderSaved.serviceType === 'DELIVERY' ? 'orders.details.loading-time' : 'orders.details.unloading-time') | translate }}"
                        formControlName="warehouseDuration" required pattern="[0-9]*">
                      <span matSuffix>min</span>
                      <mat-error *ngIf="hasError('warehouseDuration', 'required')">{{'common.error.required' |
                        translate}}
                      </mat-error>
                      <mat-error *ngIf="hasError('warehouseDuration', 'pattern')">{{'common.error.integer' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div fxLayout="row wrap" fxLayoutAlign="flex-start" fxLayoutGap="2em" *ngIf="orderFields.length > 0">

                    <ng-container *ngFor="let orderField of orderFields; let i = index;">

                      <ng-container [ngSwitch]="orderField.type">
                        <mat-form-field fxFlex="0 1 calc(50% - 2em)" *ngSwitchCase="CustomFieldType.STRING"
                          [appearance]="appearance">
                          <mat-label>{{orderField.name}}</mat-label>
                          <input matInput placeholder="{{orderField.name}}" formControlName="{{'field'+orderField.id}}"
                            (change)="onChangeStringOrderField(orderField, $event)">
                        </mat-form-field>

                        <mat-form-field fxFlex="0 1 calc(50% - 2em)" *ngSwitchCase="CustomFieldType.DATE"
                          [appearance]="appearance">
                          <mat-label>{{orderField.name}}</mat-label>
                          <input matInput [matDatepicker]="picker" placeholder="{{orderField.name}}"
                            formControlName="{{'field'+orderField.id}}"
                            (dateChange)="onChangeDateOrderField(orderField, $event)">
                          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                          <mat-datepicker #picker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field fxFlex="0 1 calc(50% - 2em)" *ngSwitchCase="CustomFieldType.INTEGER"
                          [appearance]="appearance">
                          <input matInput placeholder="{{orderField.name}}" formControlName="{{'field'+orderField.id}}"
                            (change)="onChangeStringOrderField(orderField, $event)" pattern="[0-9]*">
                          <mat-error *ngIf="hasError('field'+orderField.id, 'pattern')">{{'common.error.integer' |
                            translate}}
                          </mat-error>
                        </mat-form-field>

                        <div class="custom-margin" fxLayoutAlign="start center" fxFlex="0 1 calc(50% - 2em)"
                          *ngSwitchCase="CustomFieldType.BOOLEAN">
                          <mat-checkbox formControlName="{{'field'+orderField.id}}"
                            (change)="onChangeBooleanOrderField(orderField, $event)">
                            {{orderField.name}}
                          </mat-checkbox>
                        </div>

                        <!-- Custom field Select -->
                        <mat-form-field fxFlex="0 1 calc(50% - 2em)"
                          *ngSwitchCase="orderField.type === CustomFieldType.SELECT_UNIQUE || orderField.type === CustomFieldType.SELECT_MULTIPLE ? orderField.type : ''"
                          [appearance]="appearance">
                          <mat-label>{{orderField.name}}</mat-label>
                          <mat-select formControlName="{{'field'+orderField.id}}"
                            [multiple]="orderField.type === CustomFieldType.SELECT_MULTIPLE"
                            (selectionChange)="onChangeSelectOrderField(orderField, $event)">
                            <mat-option *ngIf="orderField.type === CustomFieldType.SELECT_UNIQUE">—</mat-option>
                            <mat-option *ngFor="let option of orderField.selectOptions" [value]="option">
                              {{ option }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                      </ng-container>
                    </ng-container>

                  </div>

                  <div fxLayout="row">
                    <div fxFlex>
                      <mat-form-field [appearance]="appearance">
                        <mat-label>{{ 'orders.details.comment' | translate }}</mat-label>
                        <textarea matInput placeholder="{{ 'orders.details.comment' | translate }}"
                          formControlName="comment" maxlength="1000"></textarea>
                        <mat-error *ngIf="hasError('comment', 'maxlength')">{{'common.error.maxlength' |
                          translate:{"value" : "1000"} }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div fxFlex>
                  <div fxLayout="row" fxLayoutGap="1em" *ngIf="skills.length > 0 || orderTypes.length > 0">
                    <div fxFlex *ngIf="orderTypes.length > 0 && displayField(DisplayField.ORDER_TYPE)">
                      <mat-form-field fxFlex [appearance]="appearance">
                        <mat-label>{{'orders.details.order-type' | translate}}<span
                            *ngIf="isRequired(DisplayField.ORDER_TYPE)"> *</span></mat-label>
                        <mat-select formControlName="orderTypeId">
                          <mat-option value="-1">{{'orders.details.no-order-type' | translate}}</mat-option>
                          <mat-option *ngFor="let orderType of orderTypes" value="{{orderType.id}}">{{orderType.name}}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="hasError('orderTypeId', 'requiredOrderType')">{{'common.error.required' |
                          translate}}
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div fxFlex *ngIf="skills.length > 0">
                      <mat-form-field fxFlex [appearance]="appearance">
                        <mat-label>{{'orders.details.skill' | translate}}</mat-label>
                        <mat-select formControlName="skillId">
                          <mat-option value="-1">{{'orders.details.no-skill' | translate}}</mat-option>
                          <mat-option *ngFor="let skill of skills" value="{{skill.id}}">{{skill.name}}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="row wrap" fxLayoutGap="1em" fxLayoutAlign="flex-start">
                    <ng-container *ngFor="let capacityType of capacityTypes">
                      <div fxFlex="0 1 calc(50% - 2em)">
                        <mat-form-field [appearance]="appearance">
                          <mat-label>{{capacityType.name}}</mat-label>
                          <input matInput placeholder="{{capacityType.name}}" formControlName="cap{{capacityType.id}}"
                            pattern="[0-9]*" [required]="capacityType.validationMode === 'REQUIRED'">
                          <span matSuffix>{{capacityType.unit}}</span>
                          <mat-error *ngIf="hasError('cap'+capacityType.id, 'required')">{{'common.error.required' |
                            translate}}
                          </mat-error>
                          <mat-error *ngIf="hasError('cap'+capacityType.id, 'pattern')">{{'common.error.integer' |
                            translate}}
                          </mat-error>
                        </mat-form-field>
                      </div>
                    </ng-container>
                  </div>
                </div>

              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div fxLayout="row" fxLayoutGap="1em" class="padding-top-1" *ngIf="orderSaved.items.length > 0">
        <div fxFlex>
          <mat-card class="transport-card">
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon>list</mat-icon>
              </div>
              <span>Articles</span>
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="padding-top-1">
              <div fxLayout="column" fxLayoutGap="1em">
                <ng-container *ngFor="let item of orderSaved.items; let index = index;">
                  <div>
                    {{item.quantity}} x {{item.itemType.label}}  ({{item.itemType.reference}})
                  </div>
                  <mat-divider></mat-divider>
                </ng-container>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>

      <div *ngIf="isDone" fxLayout="row" fxLayoutGap="1em" class="padding-top-1">
        <div fxFlex>
          <mat-card class="report-card">
            <mat-card-header>
              <div mat-card-avatar>
                <mat-icon>equalizer</mat-icon>
              </div>
              {{ 'orders.details.report.title' | translate }}
            </mat-card-header>
            <mat-divider></mat-divider>
            <mat-card-content class="padding-top-1">
              <div fxLayout="row" fxLayoutGap="2em">
                <div fxFlex>
                  <div fxLayout="row" fxLayoutGap="2em">
                    <div fxFlex>
                      <mat-form-field [appearance]="appearance">
                        <mat-label>{{ 'orders.details.report.scheduled-time' | translate }}</mat-label>
                        <input matInput placeholder="{{ 'orders.details.report.scheduled-time' | translate }}"
                          formControlName="reportScheduledTime">
                      </mat-form-field>
                    </div>
                    <div fxFlex>
                      <mat-form-field [appearance]="appearance">
                        <mat-label>{{ 'orders.details.report.arrival-time' | translate }}</mat-label>

                        <input matInput placeholder="{{ 'orders.details.report.arrival-time' | translate }}"
                          formControlName="reportArrivalTime">
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="row" fxLayoutGap="2em">
                    <div fxFlex>
                      <mat-form-field [appearance]="appearance">
                        <mat-label>{{ 'orders.details.report.agent' | translate }}</mat-label>
                        <input matInput placeholder="{{ 'orders.details.report.agent' | translate }}"
                          formControlName="reportAgent">
                      </mat-form-field>
                    </div>
                    <div fxFlex>
                      <mat-form-field [appearance]="appearance">
                        <mat-label>{{ 'orders.details.report.vehicle' | translate }}</mat-label>
                        <input matInput placeholder="{{ 'orders.details.report.vehicle' | translate }}"
                          formControlName="reportVehicle">
                      </mat-form-field>
                    </div>
                  </div>

                  <div fxLayout="row">
                    <div fxFlex>
                      <mat-form-field [appearance]="appearance">
                        <mat-label>{{ 'orders.details.report.comment' | translate }}</mat-label>
                        <input matInput placeholder="{{ 'orders.details.report.comment' | translate }}"
                          formControlName="reportComment"> <!-- required ? /// json -->
                      </mat-form-field>
                    </div>
                  </div>
                </div>

                <div fxFlex>
                  <div fxLayout="row" fxLayoutGap="2em">
                    <div *ngIf="signingUrl" fxFlex="100px" class="image">
                      <mat-label>{{ 'orders.details.report.signing' | translate }} </mat-label>
                      <img [src]="signingUrl" (click)="openImage(signingUrl)">
                    </div>

                    <div *ngFor="let photoUrl of photosUrl; let i = index" fxFlex="100px" class="image">
                      <mat-label>{{ 'orders.details.report.photo' | translate }} {{i + 1}} </mat-label>
                      <img [src]="photoUrl" (click)="openImage(photoUrl)">
                    </div>

                  </div>
                  <!-- PICTURES, SIGNATURE, CUSTOMER REVIEW-->
                  <div *ngIf="orderSaved.customerReview" fxLayout="row">
                    <div fxFlex>
                      <p>{{ 'orders.details.report.customer-rating' | translate }} :</p>
                      <star-rating *ngIf="orderSaved.customerReview" [starType]="'svg'" [readOnly]="true"
                        [rating]="orderSaved.customerReview.rating"></star-rating>
                    </div>
                  </div>

                  <div *ngIf="orderSaved.customerReview" fxLayout="row" class="margin-top-2">
                    <div fxFlex>
                      <mat-form-field>
                        <input matInput placeholder="{{ 'orders.details.report.customer-feedback' | translate }}"
                          formControlName="reportCustomerFeedback"><!-- required ? /// json -->
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </form>

  </ng-container>
  <ng-container *ngIf="isLoading">
    <mat-progress-spinner mode="indeterminate" style="position:fixed;top:50%;left:50%"></mat-progress-spinner>
  </ng-container>

  <div *ngIf="isEditable" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="1em" class="padding-top-1 text-right">
    <div fxFlex>
      <button mat-raised-button color="warn" type="button" class="margin-right-2" (click)="deleteTransport()"
        [disabled]="!orderDetailsFormGroup.valid">{{ 'common.delete' | translate }}</button>
      <button mat-raised-button class="button-blue" type="button" (click)="updateTransport(orderDetailsFormGroup.value)"
        [disabled]="!orderDetailsFormGroup.valid" [promiseBtn]="transportSubscription">
        {{ 'orders.details.save' | translate }}
      </button>
    </div>
  </div>
</div>
