<ul class="md-stepper-horizontal" [ngClass]="currentColor()">

  <li class="md-step active" [ngClass]="{mobile: mobile}">
    <div class="md-step-circle">
      <mat-icon>check</mat-icon>
    </div>
    <div class="md-step-title" [ngClass]="{mobile: mobile}">{{'common.state.CREATED'|translate}}</div>
    <div class="md-step-optional"></div>
    <div class="md-step-bar-left" [ngClass]="{mobile: mobile}"></div>
  </li>

  <li class="md-step" [class.active]="stepper.planned" [ngClass]="{mobile: mobile}">
    <div class="md-step-circle">
      <mat-icon>
        <span *ngIf="stepper.planned">check</span>
      </mat-icon>
    </div>
    <div class="md-step-title" [ngClass]="{mobile: mobile}">{{'common.state.PLANNED'|translate}}</div>
    <div class="md-step-optional">{{order.scheduledDate|date:'dd-MM-yyyy'}}</div>
    <div class="md-step-bar-left" [ngClass]="{mobile: mobile}"></div>
  </li>

  <li class="md-step" [class.active]="stepper.confirmed" [ngClass]="{mobile: mobile}">
    <div class="md-step-circle">
      <mat-icon >
        <span *ngIf="stepper.confirmed">check</span>
      </mat-icon>
    </div>
    <div class="md-step-title" [ngClass]="{mobile: mobile}">{{'common.state.CONFIRMED'|translate}}</div>
    <div class="md-step-optional"></div>
    <div class="md-step-bar-left" [ngClass]="{mobile: mobile}"></div>
  </li>

  <li class="md-step" [class.active]="stepper.progress" [ngClass]="{mobile: mobile}">
    <div class="md-step-circle">
      <mat-icon >
        <span *ngIf="stepper.progress">check</span>
      </mat-icon>
    </div>
    <div class="md-step-title" [ngClass]="{mobile: mobile}">{{'common.state.PROGRESS'|translate}}</div>
    <div class="md-step-optional"></div>
    <div class="md-step-bar-left" [ngClass]="{mobile: mobile}"></div>
  </li>

  <li class="md-step" [class.active]="stepper.canceled" *ngIf="stepper.canceled" [ngClass]="{mobile: mobile}">
    <div class="md-step-circle">
      <mat-icon>
        <span *ngIf="stepper.canceled">close</span>
      </mat-icon>
    </div>
    <div class="md-step-title" [ngClass]="{mobile: mobile}">{{'common.state.CANCELED'|translate}}</div>
    <div class="md-step-optional"></div>
    <div class="md-step-bar-left" [ngClass]="{mobile: mobile}"></div>
  </li>

  <li class="md-step" [class.active]="stepper.done" [ngClass]="{mobile: mobile}">
    <div class="md-step-circle">
      <mat-icon>
        <span *ngIf="stepper.done">check</span>
      </mat-icon>
    </div>
    <div class="md-step-title" [ngClass]="{mobile: mobile}">{{'common.state.DONE'|translate}}</div>
    <div class="md-step-optional" *ngIf="stepper.done">{{order.terminatedDate|date:'dd-MM-yyyy HH:mm'}}</div>
    <div class="md-step-bar-left" [ngClass]="{mobile: mobile}"></div>
  </li>
</ul>
