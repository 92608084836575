<div class="content-header">
  <div class="title">
    <h1>{{ 'customers.title' | translate}}</h1>
  </div>
  <div class="actions">
    <button mat-raised-button class="margin-right-2 blue-color" (click)="openCreateModal()">
      <mat-icon class="button-icon">person_add</mat-icon>{{ 'customers.add-customer' | translate}}
    </button>
    <button mat-raised-button class="button-blue" routerLink="/transport-add">
      <mat-icon class="button-icon">calendar_today</mat-icon>{{ 'orders.book-transport' | translate }}
    </button>
  </div>
</div>
<div style="clear:both;"></div>
<mat-divider></mat-divider>
<div class="padding-1">
  <mat-form-field class="filter">
      <span matPrefix class="material-icons">
          search
      </span>
    <input matInput class="customer-search-input" (keyup)="filterChanged()" placeholder="{{'common.table.filter' | translate}}">
  </mat-form-field>

  <table mat-table [dataSource]="data" class="mat-elevation-z8">
    <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="lastname">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.lastname' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}} </td>
    </ng-container>

    <ng-container matColumnDef="firstname">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.firstname' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.email' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.mobile-phone' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{element.mobileNumber}} </td>
    </ng-container>

    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.address' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{element.address}} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.action' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element">
        <a mat-mini-fab class="button-blue" [routerLink]="['/customers/', element.customerId]">
          <mat-icon>visibility</mat-icon>
        </a>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;" [routerLink]="['/customers/', row.customerId]"></tr>
  </table>
  <mat-paginator class="mat-elevation-z8" [length]="resultsLength" [pageSize]="10" [pageSizeOptions]="[10, 25, 50]"
    showFirstLastButtons></mat-paginator>
</div>
