import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export enum ValidationMode {
  REQUIRED = 'REQUIRED',
  OPTIONAL = 'OPTIONAL'
}

export class FullCapacity {
  capacityType: CapacityType;
  value: number;
}


export class Capacity {
  capacityTypeId: number;
  value: number;
}

export class CapacityType {
  id: number;
  name: string;
  unit: string;
  validationMode: ValidationMode;
  defaultValue: number;
}

@Injectable({
  providedIn: 'root'
})
export class CapacityTypeService {

  constructor(private http: HttpClient) {
  }

  public findCapacityTypes(): Observable<Array<CapacityType>> {
    return this.http.get<Array<CapacityType>>('warehouse/api/capacity-types');
  }
}
