<mat-sidenav-container fullscreen>
<mat-toolbar class="mat-elevation-z6">

  <div fxLayout="row" fxLayoutAlign="space-between center"  style="width: 100%;">
    <div fxFlex="nogrow" fxShow.xs fxHide>
      <button mat-icon-button [matMenuTriggerFor]="module">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #module="matMenu" xPosition="before">
        <button mat-menu-item routerLink="/transport">{{ 'layout.orders' | translate }}</button>
        <button mat-menu-item routerLink="/customers">{{ 'layout.customers' | translate }}</button>
        <button mat-menu-item routerLink="/reports">{{ 'layout.reports' | translate }}</button>
      </mat-menu>
    </div>
    <div fxFlex="nogrow" fxFlex.xs="100" style="text-align: center;">
      <img *ngIf="!icon" src="/assets/images/Icone-AntsRoute.png" routerLink="/transport"/>
      <img *ngIf="icon" [src]="icon" routerLink="/transport"/>
      <h1 *ngIf="!icon" fxHide.xs class="margin-left-1">AntsRoute Book</h1>
    </div>
    <div fxFlex fxHide.xs>
      <div fxLayout="row" fxLayoutAlign="center center"  style="width: 100%;">
        <button mat-button class="margin-right-3 menu-button" routerLink="/transport" routerLinkActive="menu-active">{{ 'layout.orders' | translate }}</button>
        <button mat-button class="margin-right-3 menu-button" routerLink="/customers" routerLinkActive="menu-active">{{ 'layout.customers' | translate }}</button>
        <button mat-button class="margin-right-3 menu-button" routerLink="/reports" routerLinkActive="menu-active">{{ 'layout.reports' | translate }}</button>
      </div>
    </div>
    <div fxFlex="nogrow">
      <div fxLayout="row" fxLayoutAlign="end center"  style="width: 100%;">
        <button mat-button class="menu-button" [matMenuTriggerFor]="menu">
          <i class="material-icons">person_outline</i> <span fxHide.xs>{{userService.getAccountLabel(loggedUser)}}</span>
          <i class="material-icons">keyboard_arrow_down</i></button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item [routerLink]="['/settings/my-account']">{{ 'layout.settings' | translate }}</button>
          <button mat-menu-item (click)="onLogout()">{{ 'layout.logout' | translate }}</button>
        </mat-menu>
      </div>
    </div>
  </div>
</mat-toolbar>
</mat-sidenav-container>
