import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { AlertService } from 'src/app/shared/utils/alert.service';
import { MustMatch } from 'src/app/shared/utils/must-match.validator';
import { UserService } from 'src/app/shared/auth/user.service';
import { WarehouseView } from 'src/app/shared/warehouse/warehouse.model';
import { WarehouseService } from 'src/app/shared/warehouse/warehouse.service';
import { CreateUser, User } from 'src/app/shared/auth/user';
import { AuthService } from '../../../shared/auth/auth.service';
import { BehaviorSubject, Subscription } from 'rxjs';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent implements OnInit, OnDestroy {

  update: UntypedFormGroup;
  warehouses: WarehouseView[] = [];
  accountSubscription: Subscription;

  constructor(
    private formBuilder: UntypedFormBuilder, private alert: AlertService, private userService: UserService,
    private warehouseService: WarehouseService, private authService: AuthService) { }

  ngOnInit() {
    this.warehouseService.findWarehouses(0, 1000).subscribe(res => {
      this.warehouses = res.elements;
    });
    this.update = this.formBuilder.group({
      phone: new UntypedFormControl(''),
      email : new UntypedFormControl('', [Validators.required, Validators.email]),
      firstName : new UntypedFormControl('', [Validators.required]),
      lastName : new UntypedFormControl('', [Validators.required]),
      password : new UntypedFormControl('', [Validators.required]),
      confirmPassword : new UntypedFormControl('', [Validators.required]),
      role : new UntypedFormControl('USER', [Validators.required]),
      warehouseIds : new UntypedFormControl('', [Validators.required]),
      language : new UntypedFormControl('fr')
    }, {
      validators: [ MustMatch('password', 'confirmPassword')]
    });

    this.userService.getMyAccount().subscribe(myaccount => {
          this.update.get('email').setValue(myaccount.email);
          this.update.get('email').disable();
          this.update.get('firstName').setValue(myaccount.firstName);
          this.update.get('lastName').setValue(myaccount.lastName);
          this.update.get('phone').setValue(myaccount.phone);
          this.update.get('password').setValue('NOCHANGE');
          this.update.get('confirmPassword').setValue('NOCHANGE');
          this.update.get('role').setValue(myaccount.role);
          this.update.get('role').disable();
          this.update.get('language').setValue(myaccount.language);
          this.update.get('warehouseIds').setValue(myaccount.warehouses.map(warehouseId => String(warehouseId.id)));
          this.update.get('warehouseIds').disable();
    });

  }

  public hasError = (controlName: string, errorName: string) => {
    return this.update.controls[controlName].hasError(errorName);
  }

  save(user: CreateUser) {
    if (user.password === 'NOCHANGE') {
      user.password = undefined;
    }
    this.accountSubscription = this.userService.updateMyAccount(user).subscribe( () => {
      this.userService.applyLanguage(user.language);
      this.authService.currentUserValue.firstName = user.firstName;
      this.authService.currentUserValue.lastName = user.lastName;
      this.alert.info('settings.my-account.update-success');
    },
    error => {
      this.alert.error(error);
    });
  }

  ngOnDestroy(): void {
    if (this.accountSubscription) {
      this.accountSubscription.unsubscribe();
    }
  }

}
