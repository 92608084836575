<h2 mat-dialog-title>{{'orders.book-transport' | translate }}</h2>
<mat-divider></mat-divider>
<mat-dialog-content>

  <ng-container [ngSwitch]="currentStep">
    <div *ngSwitchCase="'CONFIRM_DATE'">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" class="padding-top-1">
        <mat-icon>calendar_today</mat-icon>
        <span>{{'orders.save-to-basket.choose-period' | translate}}</span>
      </div>
      <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
        <div fxFlex>
          <mat-form-field class="margin-right-2">
            <input matInput [min]="today" [matDatepicker]="min" placeholder="{{ 'common.from' | translate }}"
              [formControl]="minDate" (dateChange)="minDateChange()">
            <mat-datepicker-toggle matSuffix [for]="min"></mat-datepicker-toggle>
            <mat-datepicker #min></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <input matInput [min]="today" [matDatepicker]="max" placeholder="{{ 'common.to' | translate }}"
              [formControl]="maxDate" (dateChange)="maxDateChange()">
            <mat-datepicker-toggle matSuffix [for]="max"></mat-datepicker-toggle>
            <mat-datepicker #max></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div *ngSwitchCase="'CONFIRM_TIMESLOT'">
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="1em" class="padding-top-1">
          <mat-icon>schedule</mat-icon>
          <span>{{'orders.save-to-basket.choose-time-slot' | translate:{"from" : minDate.value.format('DD/MM/YYYY'), "to": maxDate.value.format('DD/MM/YYYY')} }}</span>
      </div>

      <div *ngIf="!isMobile" fxLayout="row wrap" fxLayoutWrap fxLayoutGap="2em" fxLayoutAlign="flex-start" class="padding-bottom-1">
        <ng-container *ngFor="let timeSlot of timeSlots">
          <div fxFlex="0 1 calc(33.3% - 2em)" (click)="chooseTimeSlot(timeSlot)" class="time-slot margin-top-1"
            [class.selected]="timeSlot.selected">
            <span> {{timeSlot.from | slice:0:5}} - {{timeSlot.to | slice:0:5}}</span>
          </div>
        </ng-container>
      </div>

      <div *ngIf="isMobile" fxLayout="row wrap" fxLayoutWrap fxLayoutGap="1em" class="padding-bottom-1">
        <mat-form-field>
          <mat-select (selectionChange)="timeSlotSelected($event)">
            <mat-option *ngFor="let timeSlot of timeSlots" value={{timeSlot.id}} >
              {{timeSlot.from | slice:0:5}} - {{timeSlot.to | slice:0:5}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

    <div *ngSwitchCase="'CONFIRM_INFORMATION'">

      <ng-container *ngIf="isStoreDefaultLoading">
        <h3 class="padding-top-1 no-margin-bottom">{{data.transport.customer.lastName}}
          {{data.transport.customer.firstName}}</h3>
        <h4>{{data.transport.customer.address}} </h4>
      </ng-container>

      <ng-container *ngIf="! isStoreDefaultLoading">
        <h3 class="padding-top-1 no-margin-bottom">{{data.transport.shop.name}}</h3>
        <h4>{{data.transport.shop.address}} </h4>
      </ng-container>

      <div fxLayout="column" class="information">
        <div fxFlex>
          <mat-icon>calendar_today</mat-icon><span class="aligned-with-icon">{{ 'common.from' | translate }}
            {{minDate.value.format('DD/MM/YYYY')}} {{ 'common.to' | translate }}
            {{maxDate.value.format('DD/MM/YYYY')}}</span>
        </div>
        <div fxFlex>
          <mat-icon>schedule</mat-icon><span class="aligned-with-icon">{{selectedTimeSlot.from | slice:0:5}} -
            {{selectedTimeSlot.to | slice:0:5}}</span>
        </div>
      </div>
    </div>

  </ng-container>

</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="warn" mat-dialog-close
    class="margin-right-2">{{'common.cancel' | translate}}</button>

  <button *ngIf="currentStep === 'CONFIRM_DATE'" mat-raised-button class="button-blue" type="button"
    (click)="confirmDate()">{{'common.confirm' | translate}}</button>

  <button *ngIf="currentStep === 'CONFIRM_TIMESLOT'" mat-raised-button class="button-blue" type="button"
    (click)="confirmTimeSlot()" [disabled]="!selectedTimeSlot">{{'common.confirm' | translate}}</button>

  <button *ngIf="currentStep === 'CONFIRM_INFORMATION'" mat-raised-button class="button-blue" type="button"
    [promiseBtn]="saveTransportSubscription" (click)="saveTransport()">{{'orders.book-popup' | translate}}</button>
</mat-dialog-actions>
