<div class="content-header" fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 2em;margin-bottom: 1em;">
  <div>
    <h1 style="margin: Opx !important;">{{'settings.configuration.title' | translate}}</h1>
  </div>
  <div>
    <button mat-raised-button class="button-blue" (click)="save(configuration.value)" [promiseBtn]="configSubscription">
      <mat-icon class="button-icon">save</mat-icon>{{'common.save' | translate}}
    </button>
  </div>
</div>
<div style="clear:both;"></div>
<mat-divider></mat-divider>
<div class="padding-1">
  <form [formGroup]="configuration" autocomplete="off" novalidate>
    <div fxLayout.lt-xl="column" fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
      <div fxLayout="column" fxLayoutGap="2em">
        <div fxFlex="100" [fxFlexOrder]="1">

          <mat-card class="my-account">
            <mat-card-header>
              <h3>{{'settings.configuration.general' | translate}}</h3>
            </mat-card-header>
            <mat-card-content class="my-account">
              <div fxLayout="column" fxLayout.xs="column" fxLayoutGap="1em" class="padding-top-1">
                <div fxFlex="50">
                  <mat-form-field style="min-width: 500px;">
                    <input matInput placeholder="{{'settings.configuration.iconUrl' | translate}}"
                      formControlName="iconUrl" pattern="https?://.+">
                    <mat-error *ngIf="hasError('iconUrl', 'pattern')">{{'common.error.bad-url' | translate}}</mat-error>
                  </mat-form-field>
                </div>
                <div fxFlex="50">
                  <mat-form-field style="min-width: 500px;">
                    <mat-label>{{'settings.configuration.customFieldIdForOrderNumber' | translate}}</mat-label>
                    <mat-select formControlName="customFieldIdForOrderNumber">
                      <mat-option value="-1">{{'settings.configuration.no-custom-field' | translate}}</mat-option>
                      <mat-option *ngFor="let customField of customFields" value="{{customField.id}}">
                        {{customField.name}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
        <div fxFlex="100" [fxFlexOrder]="2" *ngIf="process === 'SCORING'">
          <mat-card class="my-account">
            <mat-card-header>
              <h3>{{'settings.configuration.scoring.title' | translate}}</h3>
            </mat-card-header>
            <mat-card-content class="my-account">
              <div fxLayout="row" fxLayoutGap="1em" >
                <mat-form-field fxFlex="100">
                  <mat-label>{{'settings.configuration.scoring.scoringBeginDay' | translate}}</mat-label>
                  <mat-select  formControlName="scoringBeginDay">
                    <mat-option *ngFor="let start of startDaysArray" [value]="start">
                      J + {{start}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field fxFlex="row">
                    <mat-label>{{'settings.configuration.scoring.scoringNbDayToScore' | translate}}</mat-label>
                    <mat-select  formControlName="scoringNbDayToScore">
                      <mat-option *ngFor="let nb of ndDaysArray" [value]="nb">
                        {{nb}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
              </div>
              <div fxLayout="row" fxLayoutGap="1em" >
                <mat-form-field fxFlex="100">
                  <mat-label>{{'settings.configuration.scoring.scoringMinimumScore' | translate}}</mat-label>
                  <input matInput type="number" formControlName="scoringMinimumScore" min="0" max="100">
                  <mat-error *ngIf="hasError('scoringMinimumScore', 'required')">{{'common.error.required' | translate}}</mat-error>
                  <mat-error *ngIf="hasError('scoringMinimumScore', 'min')">{{'common.error.percentage' | translate}}</mat-error>
                  <mat-error *ngIf="hasError('scoringMinimumScore', 'max')">{{'common.error.percentage' | translate}}</mat-error>
                </mat-form-field>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <div fxFlex="100" [fxFlexOrder]="3">
        <mat-card class="my-account">
          <mat-card-header>
            <h3>{{'settings.configuration.display' | translate}}</h3>
          </mat-card-header>
          <mat-card-content class="my-account">
            <div fxLayout="row">
              <div fxFlex="50">{{'settings.configuration.display-options.field-header' | translate}}</div>
              <div fxFlex="10">{{'settings.configuration.display-options.visible-header' | translate}}</div>
              <div fxFlex="10">{{'settings.configuration.display-options.required-header' | translate}}</div>
              <div fxFlex="30">{{'settings.configuration.display-options.default-value-header' | translate}}</div>
            </div>
            <div fxLayout="row" *ngFor="let setting of displaySettings; index as i">

              <div fxFlex="50" style="padding-top: 1em; padding-bottom: 1.25em;">
                {{'settings.configuration.display-options.' + setting.field | translate }}</div>
              <div fxFlex="10" style="padding-top: 1em; padding-bottom: 1.25em;">
                <mat-checkbox [formControl]="arrayVisible.at(i)" (change)="changeVisibility(setting.field, $event)"
                  color="primary" id="visible_{{i}}"></mat-checkbox>
              </div>
              <div fxFlex="10" style="padding-top: 1em; padding-bottom: 1.25em;">
                <mat-checkbox [formControl]="arrayRequired.at(i)" (change)="changeRequired(setting.field, $event)"
                  color="primary" id="required_{{i}}"></mat-checkbox>
              </div>
              <div fxFlex="30">
                <div [ngSwitch]="setting.field">
                  <div *ngSwitchCase="'CUSTOMER_DELIVERY_DURATION'">
                    <mat-form-field>
                      <input matInput [formControl]="arrayDefaultValue.at(i)" [required]="setting.required.value"
                        (change)="changeDefaultValue(setting.field, $event)">
                      <mat-error *ngIf="fieldError(arrayDefaultValue.at(i), 'required')">
                        {{'common.error.required' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'CUSTOMER_PICKUP_DURATION'">
                    <mat-form-field>
                      <input matInput [formControl]="arrayDefaultValue.at(i)" [required]="setting.required.value"
                        (change)="changeDefaultValue(setting.field, $event)">
                      <mat-error *ngIf="fieldError(arrayDefaultValue.at(i), 'required')">
                        {{'common.error.required' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="'ORDER_TYPE'">
                    <mat-form-field *ngIf="orderTypes.length > 0">
                      <mat-select [formControl]="arrayDefaultValue.at(i)" [required]="setting.required.value"
                        (selectionChange)="changeDefaultValue(setting.field, $event)">
                        <mat-option value="-1">{{'orders.details.no-order-type' | translate}}</mat-option>
                        <mat-option *ngFor="let orderType of orderTypes" value="{{orderType.id}}">{{orderType.name}}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="fieldError(arrayDefaultValue.at(i), 'required')">
                        {{'common.error.required' | translate}}
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div *ngSwitchCase="-1">
                    <mat-form-field>
                      <input matInput [formControl]="arrayDefaultValue.at(i)">
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

    </div>

    <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
      <div fxFlex>
        <button mat-raised-button class="button-blue" (click)="save(configuration.value)"
          [disabled]="!configuration.valid" [promiseBtn]="configSubscription">
          <mat-icon class="button-icon">save</mat-icon>{{'common.save' | translate}}
        </button>
      </div>
    </div>

  </form>


</div>
