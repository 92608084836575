<div class="signin-content">
  <mat-card class="login-card">
    <mat-card-content>
      <form [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
        <p>{{ 'login.description' | translate }}</p>
        <mat-form-field class="full-width-input">
          <input matInput placeholder="{{ 'login.user' | translate }}" formControlName="userName" required>
          <!--mat-error *ngIf="isFieldInvalid('userName')">
            Please inform your user name
          </mat-error -->
        </mat-form-field>
        <mat-form-field class="full-width-input">
          <input matInput type="password" placeholder="{{ 'login.password' | translate }}" formControlName="password" required>
          <!--mat-error *ngIf="isFieldInvalid('userName')">
            Please inform your password
          </mat-error -->
        </mat-form-field>
        <button mat-raised-button color="primary" type="submit" [promiseBtn]="loginSubscription">{{ 'login.login' | translate }}</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
