import { CustomDisplayService } from "./../../../shared/utils/custom-display.service";
import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { CreateTransport } from "../../../shared/orders/base-order.model";
import { WarehouseView } from "../../../shared/warehouse/warehouse.model";
import { CustomersService } from "../../../shared/customers/customers.service";
import { WarehouseService } from "../../../shared/warehouse/warehouse.service";
import { ActivatedRoute, Router } from "@angular/router";
import { debounceTime, finalize, switchMap, tap } from "rxjs/operators";
import { CustomerView } from "../../../shared/customers/customer.model";
import * as moment from "moment";
import { OrderType } from "../../../shared/resources/order-type.service";
import { Skill } from "../../../shared/resources/skill.service";
import {
  CapacityType,
  Capacity,
} from "../../../shared/resources/capacity-type.service";
import { TimeSlot } from "../../../shared/resources/time-slot.service";
import { ResourcesService } from "../../../shared/resources/resources.service";
import { DELIVERY, PICKUP } from "../../../shared/orders/light-order.model";
import { MatDialogConfig, MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { SaveToBasketComponent } from "./save-to-basket/save-to-basket.component";
import { CustomersAddEditComponent } from "../../customers/customers-add-edit/customers-add-edit.component";
import { AlertService } from "../../../shared/utils/alert.service";
import {
  CustomField,
  CustomFieldsService,
  CustomFieldType,
} from "../../../shared/resources/custom-fields.service";
import { BUCKET, SCORING } from "../../../shared/warehouse/configuration.model";
import { ScoreAndSaveComponent } from "./score-and-save/score-and-save.component";
import { DisplayField } from "../../../shared/warehouse/configuration.model";
import { RequiredOrderTypeValidator } from "../../../shared/utils/required-order-type.validator";
import { AuthService } from "../../../shared/auth/auth.service";
import { Item, ItemType, ItemTypeService } from "../../../shared/resources/item-types.service";
import { AddItemComponent } from "../add-item/add-item.component";

enum PossibleState {
  DONE = "DONE",
  CREATED = "CREATED",
  PLANNED = "PLANNED",
  CANCELED = "CANCELED",
}

@Component({
  selector: "app-transport-add-edit",
  templateUrl: "./transport-add-edit.component.html",
  styleUrls: ["./transport-add-edit.component.scss"],
})
export class TransportAddEditComponent implements OnInit {
  orderFormGroup: UntypedFormGroup;
  createTransport: CreateTransport;

  isPageLoading = true;
  searching = false;
  isDone = false;
  displayOrderPanel = false;
  isStoreAsDefaultLoading = this.authService.isStoreAsDefaultLoading();
  isItemsEnable = this.authService.isItemsEnable();

  currentDate = moment(new Date());
  warehouses: WarehouseView[] = [];
  customers: CustomerView[];
  itemTypes: ItemType[];
  selectedCustomer: CustomerView;
  selectedWarehouse: WarehouseView;

  orderTypes: OrderType[];
  skills: Skill[];
  capacityTypes: CapacityType[];
  timeSlots: TimeSlot[];
  orderFields: CustomField[];

  capacities: Capacity[] = [];
  CustomFieldType = CustomFieldType;

  items: Item[] = [];

  public DisplayField = DisplayField;

  constructor(
    private customerService: CustomersService,
    private warehouseService: WarehouseService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private resourcesService: ResourcesService,
    private alert: AlertService,
    private activatedRoute: ActivatedRoute,
    private customFieldsService: CustomFieldsService,
    private customDisplayService: CustomDisplayService,
    private itemTypeService: ItemTypeService
  ) {}

  ngOnInit() {
    this.createTransport = new CreateTransport();
    this.createTransport.serviceType = DELIVERY;

    this.buildForms();
    this.setupCustomerSearch();
    this.setupWarehouseSearch();
    this.setupOnChangeOrderType();
    this.setupItemSearch();

    this.activatedRoute.queryParams.subscribe((params) => {
      const customerId = params["c"];
      if (customerId) {
        this.preFillCustomerSelectField(customerId);
      }
    });
    this.resourcesService.getAllResources().subscribe((results) => {
      this.orderTypes = results[0];
      this.skills = results[1];
      this.capacityTypes = results[2];
      this.timeSlots = results[3];
      const customFieldsCategories = results[4];
      this.orderFields = [];
      customFieldsCategories.forEach((category) => {
        this.orderFields.push(...category.customFields);
      });

      if (this.hasDefaultValue(DisplayField.ORDER_TYPE)) {
        this.orderFormGroup
          .get("orderTypeId")
          .setValue(this.getDefaultValue(DisplayField.ORDER_TYPE));
        this.applyOrderType(
          Number(this.getDefaultValue(DisplayField.ORDER_TYPE))
        );
      }

      this.addCapacityFields();
      this.addCustomFields();
      this.isPageLoading = false;
    });
  }

  private getDefaultValue(field: DisplayField): string {
    return this.customDisplayService.getDefaultValue(field);
  }

  private preFillCustomerSelectField(customerId: number) {
    this.customerService.findCustomer(customerId).subscribe((res) => {
      this.orderFormGroup.get("customer").enable();
      this.orderFormGroup.get("customer").setValue(res);
    });
  }

  private addCustomFields() {
    this.orderFields.forEach((orderField) => {
      const control = new UntypedFormControl("");
      this.orderFormGroup.addControl("field" + orderField.id, control);
    });
    this.createTransport.fields = [];
  }

  onChangeStringOrderField(orderField: CustomField, event): void {
    this.customFieldsService.onChangeOrderField(
      this.createTransport,
      orderField,
      event.target.value
    );
  }

  onChangeDateOrderField(orderField: CustomField, event): void {
    this.customFieldsService.onChangeOrderField(
      this.createTransport,
      orderField,
      event.target.value.toDate()
    );
  }

  onChangeBooleanOrderField(orderField: CustomField, event): void {
    this.customFieldsService.onChangeBooleanOrderField(
      this.createTransport,
      orderField,
      event.checked
    );
  }

  onChangeSelectOrderField(
    orderField: CustomField,
    event: MatSelectChange
  ): void {
    this.customFieldsService.onChangeOrderField(
      this.createTransport,
      orderField,
      event.value
    );
  }

  private addCapacityFields() {
    this.capacityTypes.forEach((capacityType) => {
      const control = new UntypedFormControl("");
      control.valueChanges.subscribe((x) => {
        if (!isNaN(x) && x !== "") {
          if (
            this.capacities.findIndex(
              (c) => c.capacityTypeId === capacityType.id
            ) !== -1
          ) {
            this.capacities.find(
              (c) => c.capacityTypeId === capacityType.id
            ).value = x;
          } else {
            const capacity = new Capacity();
            capacity.capacityTypeId = capacityType.id;
            capacity.value = x;
            this.capacities.push(capacity);
          }
        } else {
          const index = this.capacities.findIndex(
            (c) => c.capacityTypeId === capacityType.id
          );
          if (index > -1) {
            this.capacities.splice(index, 1);
          }
        }
      });
      this.orderFormGroup.addControl("cap" + capacityType.id, control);
      if (capacityType.defaultValue) {
        this.orderFormGroup.controls["cap" + capacityType.id].setValue(capacityType.defaultValue);
      }
    });
  }

  private buildForms() {
    this.orderFormGroup = this.formBuilder.group({
      shop: new UntypedFormControl(),
      shopAddress: new UntypedFormControl({ value: "", disabled: true }),
      warehouseTimeSlotId: new UntypedFormControl({ value: "" }),
      warehouseDuration: new UntypedFormControl({ value: "" }),

      customer: new UntypedFormControl({ disabled: true }),

      customerAddress: new UntypedFormControl({ value: "", disabled: true }),
      customerMobilePhone: new UntypedFormControl({ value: "", disabled: true }),
      customerMail: new UntypedFormControl({ value: "", disabled: true }),
      customerDuration: new UntypedFormControl(),
      customerComment: new UntypedFormControl({ value: "", disabled: true }),

      comment: new UntypedFormControl("", Validators.maxLength(1000)),
      skillId: new UntypedFormControl("-1"),
      orderTypeId: new UntypedFormControl(
        "-1",
        this.isRequired(DisplayField.ORDER_TYPE)
          ? [RequiredOrderTypeValidator]
          : null
      ),
      itemType: new UntypedFormControl({ disabled: true }),

    });

    if (this.hasDefaultValue(DisplayField.CUSTOMER_DELIVERY_DURATION)) {
      this.orderFormGroup
        .get("customerDuration")
        .setValue(
          Number(this.getDefaultValue(DisplayField.CUSTOMER_DELIVERY_DURATION))
        );
    } else {
      this.orderFormGroup.get("customerDuration").setValue(10);
    }
  }

  goBack() {
    if (this.router.url.indexOf("reports") > 0) {
      this.router.navigate(["/reports"]);
    } else if (this.router.url.indexOf("transport") > 0) {
      this.router.navigate(["/transport"]);
    } else if (this.router.url.indexOf("customers") > 0) {
      this.router.navigate(["/customers"]);
    }
  }

  displayFn(customer: CustomerView) {
    if (customer && customer.customerId) {
      return (
        customer.lastName +
        " " +
        (customer.firstName !== null ? customer.firstName : "")
      );
    }
  }

  displayItemTypeFn(item: ItemType) {
    if (item && item.id) {
      return item.label + " (" + item.reference + ")";
    }
  }


  warehouseDisplayFn(warehouse: WarehouseView) {
    if (warehouse && warehouse.warehouseId) {
      return warehouse.name;
    }
  }

  fillFormCustomer(customer: CustomerView) {
    this.orderFormGroup
      .get("customerMobilePhone")
      .setValue(customer.mobileNumber);
    this.orderFormGroup.get("customerMail").setValue(customer.email);
    this.orderFormGroup.get("customerAddress").setValue(customer.address);
    this.orderFormGroup.get("customerComment").setValue(customer.comments);
  }

  fillFormShop(warehouse: WarehouseView) {
    this.orderFormGroup.get("shopAddress").setValue(warehouse.address);
    if (this.createTransport.serviceType === DELIVERY) {
      this.orderFormGroup
        .get("warehouseTimeSlotId")
        .setValue(String(warehouse.defaultLoadingTimeSlotId));
      this.orderFormGroup
        .get("warehouseDuration")
        .setValue(warehouse.defaultLoadingTime);
    } else {
      this.orderFormGroup
        .get("warehouseTimeSlotId")
        .setValue(String(warehouse.defaultUnLoadingTimeSlotId));
      this.orderFormGroup
        .get("warehouseDuration")
        .setValue(warehouse.defaultUnloadingTime);
    }
  }

  clickOnAddOrder() {
    this.displayOrderPanel = true;
  }

  private applyOrderType(orderTypeSelected: number) {
    if (Number(orderTypeSelected) === -1) {
      this.orderFormGroup.get("customerDuration").enable();
      this.orderFormGroup.get("skillId").enable();
    } else {
      const orderType = this.orderTypes.find(
        (s) => s.id === Number(orderTypeSelected)
      );
      this.orderFormGroup.get("customerDuration").disable();
      this.orderFormGroup.get("customerDuration").setValue(orderType.duration);
      this.orderFormGroup.get("skillId").disable();
      if (orderType.skill) {
        this.orderFormGroup.get("skillId").setValue(String(orderType.skill.id));
      }
    }
  }

  private setupOnChangeOrderType() {
    this.orderFormGroup
      .get("orderTypeId")
      .valueChanges.subscribe((orderTypeSelected) => {
        this.applyOrderType(Number(orderTypeSelected));
      });
  }

  private setupCustomerSearch() {
    this.orderFormGroup
      .get("customer")
      .valueChanges.pipe(
        debounceTime(300),
        tap(() => (this.searching = true)),
        switchMap((value) =>
          this.customerService
            .findAllCustomers(0, 20, value)
            .pipe(finalize(() => (this.searching = false)))
        )
      )
      .subscribe((results) => (this.customers = results.elements));

    this.orderFormGroup
      .get("customer")
      .valueChanges.subscribe((val: CustomerView) => {
        this.selectedCustomer = val.customerId ? val : null;
        if (val.customerId) {
          this.displayOrderPanel = false;
          this.fillFormCustomer(this.selectedCustomer);
        }
      });
  }

  private setupItemSearch() {
    this.orderFormGroup
      .get("itemType")
      .valueChanges.pipe(
        debounceTime(300),
        tap(() => (this.searching = true)),
        switchMap((value) =>
          this.itemTypeService
            .findAllItemTypes(1, 20, value)
            .pipe(finalize(() => (this.searching = false)))
        )
      )
      .subscribe((results) => (this.itemTypes = results.elements));

    this.orderFormGroup
      .get("itemType")
      .valueChanges.subscribe((val: ItemType) => {
        if (val != null && val.id) {
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.autoFocus = true;
          const dialogRef = this.dialog.open(AddItemComponent, dialogConfig);
          dialogRef.componentInstance.itemType = val;
          dialogRef.afterClosed().subscribe(quantity => {
            if (quantity) {
              const item = new Item();
              item.quantity = quantity;
              item.itemType = val;

              this.items.push(item);
            }
            this.orderFormGroup.get("itemType").setValue(null);
          });
        }
      });
  }

  private setupWarehouseSearch() {
    this.warehouseService
      .findWarehouses(0, 10000)
      .subscribe((warehousesRes) => {
        this.warehouses = warehousesRes.elements;
        if (this.warehouses.length === 1) {
          this.searching = false;
          this.selectedWarehouse = this.warehouses[0];
          this.orderFormGroup.get("shop").setValue(this.selectedWarehouse);
        }
      });

    this.orderFormGroup.get("shop").valueChanges.pipe(
      debounceTime(300),
      tap(() => (this.searching = true)),
      switchMap((value) =>
        this.warehouses.filter((w) => w.name.indexOf(value) > 0)
      ),
      finalize(() => (this.searching = false))
    );
    this.orderFormGroup
      .get("shop")
      .valueChanges.subscribe((val: WarehouseView) => {
        this.selectedWarehouse = val.warehouseId ? val : null;
        if (val.warehouseId) {
          this.displayOrderPanel = false;
          this.fillFormShop(this.selectedWarehouse);
        } else {
          if (this.isStoreAsDefaultLoading) {
            this.selectedCustomer = null;
            this.orderFormGroup.get("customer").setValue("");
          }
        }
      });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.orderFormGroup.controls[controlName].hasError(errorName);
  };

  changeServiceType() {
    if (this.createTransport.serviceType === DELIVERY) {
      this.createTransport.serviceType = PICKUP;
      this.orderFormGroup.get("warehouseDuration").setValue(this.selectedWarehouse.defaultUnloadingTime);
      if (this.hasDefaultValue(DisplayField.CUSTOMER_PICKUP_DURATION)) {
        this.orderFormGroup
          .get("customerDuration")
          .setValue(
            Number(this.getDefaultValue(DisplayField.CUSTOMER_PICKUP_DURATION))
          );
      } else {
        this.orderFormGroup.get("customerDuration").setValue(10);
      }
    } else {
      this.createTransport.serviceType = DELIVERY;
      this.orderFormGroup.get("warehouseDuration").setValue(this.selectedWarehouse.defaultLoadingTime);
      if (this.hasDefaultValue(DisplayField.CUSTOMER_DELIVERY_DURATION)) {
        this.orderFormGroup
          .get("customerDuration")
          .setValue(
            Number(
              this.getDefaultValue(DisplayField.CUSTOMER_DELIVERY_DURATION)
            )
          );
      } else {
        this.orderFormGroup.get("customerDuration").setValue(10);
      }
    }
  }

  openCreateCustomerModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(CustomersAddEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.preFillCustomerSelectField(result);
        this.alert.info("customers.create-success");
      }
    });
  }

  plan(value: CreateTransport) {
    value.serviceType = this.createTransport.serviceType;
    value.fields = this.createTransport.fields;
    value.customerId = value.customer.customerId;
    value.warehouseId = value.shop.warehouseId;
    value.warehouseTimeSlot = this.timeSlots.find(
      (t) => t.id === Number(value.warehouseTimeSlotId)
    );

    if (value.skillId === -1) {
      value.skill = undefined;
    } else {
      value.skill = this.skills.find((s) => s.id === Number(value.skillId));
    }

    if (value.orderTypeId === -1) {
      value.orderType = undefined;
    } else {
      value.orderType = this.orderTypes.find(
        (s) => s.id === Number(value.orderTypeId)
      );
    }

    value.capacities = this.capacities;
    value.items = this.items;

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { transport: value };

    this.warehouseService.getConfiguration().subscribe(
      (configuration) => {
        if (configuration.process === BUCKET) {
          const dialogRef = this.dialog.open(
            SaveToBasketComponent,
            dialogConfig
          );
          dialogRef.afterClosed().subscribe();
        } else if (configuration.process === SCORING) {
          dialogConfig.minWidth = "600px";
          const dialogRef = this.dialog.open(
            ScoreAndSaveComponent,
            dialogConfig
          );
          dialogRef.afterClosed().subscribe();
        }
      },
      (err) => {
        this.alert.error(err);
      }
    );
  }

  private displayField(displayField: DisplayField) {
    return this.customDisplayService.display(displayField);
  }

  private isRequired(displayField: DisplayField) {
    return this.customDisplayService.isRequired(displayField);
  }

  private hasDefaultValue(displayField: DisplayField) {
    return this.customDisplayService.hasDefaultValue(displayField);
  }
}
