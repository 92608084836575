<div mat-dialog-content>
  <div fxLayout="row" fxLayoutGap="2em" class="padding-top-1">
    <div fxFlex>
      <h2>{{message}}</h2>
    </div>
  </div>
</div>
<div mat-dialog-actions align="end">
  <button mat-stroked-button color="warn" (click)="onNoClick()"
    class="margin-right-2">{{'common.no' | translate}}</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="button-blue">{{'common.yes' | translate}}</button>
</div>
