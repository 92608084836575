import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Columns, TableService, TABLE_TYPE } from './table.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

export class ConfigurationTable {
  tableType: TABLE_TYPE;
  columns: Columns;
}

@Component({
  selector: 'app-configuration-table-modal',
  templateUrl: './configuration-table-modal.component.html',
  styleUrls: ['./configuration-table-modal.component.scss']
})
export class ConfigurationTableModalComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfigurationTableModalComponent>,
    @Inject(MAT_DIALOG_DATA) public configurationTable: ConfigurationTable, private tableService: TableService) {
     }

  onNoClick(): void {
    this.dialogRef.close();
  }

  isSelected(column: string) {
    return this.configurationTable.columns.selected.findIndex(c => c === column) !== -1;
  }

  getName(column: string): string {
    return this.tableService.getHeaderName(column);
  }

  changeColumn(column: string) {
    if (this.isSelected(column)) {
      this.configurationTable.columns.selected = this.configurationTable.columns.selected.filter(c => c !== column);
      this.configurationTable.columns.available.push(column);
    } else {
      this.configurationTable.columns.available = this.configurationTable.columns.available.filter(c => c !== column);
      this.configurationTable.columns.selected.splice((this.configurationTable.columns.selected.length - 1), 0, column);
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log(event);
    moveItemInArray(
       this.configurationTable.columns.selected,
       event.previousIndex,
       event.currentIndex
      );
  }

  save() {
    this.tableService.setTransportHeaders(this.configurationTable.tableType, this.configurationTable.columns);
    this.dialogRef.close(this.configurationTable.columns.selected);
  }
}
