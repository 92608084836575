import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {LightOrder, State} from './light-order.model';
import {HttpResourcesResponse} from '../utils/HttpResourcesResponse.model';
import {TimeSlot} from './timeslot.model';
import {FullOrderView, CreateTransport, UpdateTransport, ScoreOrderReport, ScoreOrderValidation} from './base-order.model';

@Injectable()
export class OrderService {

  constructor(private http: HttpClient) {
  }

  findAllLightOrders(page: number, size: number, minDate: string, maxDate: string): Observable<HttpResourcesResponse<LightOrder>> {
    return this.findAllLightOrdersByState(page, size, minDate, maxDate, [], []);
  }

  findAllLightOrdersByState(page: number, size: number, minDate: string, maxDate: string, states?: Array<State>,
                            warehouses?: Array<number>):
    Observable<HttpResourcesResponse<LightOrder>> {
    let p = new HttpParams();
    p = p.append('page', String(page));
    p = p.append('size', String(size));
    if (minDate) {
      p = p.append('minDate', minDate);
    }
    if (maxDate) {
      p = p.append('maxDate', maxDate);
    }
    if (states && states.length !== 0) {
      p = p.append('state', states.toString());
    }
    if (warehouses && warehouses.length !== 0) {
      p = p.append('warehouses', warehouses.toString());
    }
    return this.http.get<HttpResourcesResponse<LightOrder>>('warehouse/api/orders', { params: p });
  }

  findTimeslots(): Observable<Array<TimeSlot>> {
    return this.http.get<Array<TimeSlot>>('warehouse/api/time-slots');
  }

  getById(id: number): Observable<FullOrderView> {
    return this.http.get<FullOrderView>('warehouse/api/orders/' + id);
  }

  getSigning(id: number): Observable<Blob> {
    return this.http.get('warehouse/api/orders/' + id + '/signing', { responseType: 'blob' });
  }

  getPhoto(id: number, index: number): Observable<Blob> {
    return this.http.get('warehouse/api/orders/' + id + '/photos/' + index, { responseType: 'blob' });
  }

  scoreOrder(createOrder: CreateTransport, from: string, to: string): Observable<ScoreOrderReport> {
    let p = new HttpParams();
    p = p.append('from', from);
    p = p.append('to', to);
    return this.http.put<ScoreOrderReport>('warehouse/api/orders/score', createOrder, { params: p });
  }

  validateScoreOrder(scoreOrderValidation: ScoreOrderValidation) {
    return this.http.post<void>('warehouse/api/orders/score/validate', scoreOrderValidation);
  }

  cancelScoreOrder(uniqueOrderScoreIdentifier: string) {
    let p = new HttpParams();
    p = p.append('uniqueOrderScoreIdentifier', uniqueOrderScoreIdentifier);
    return this.http.delete<void>('warehouse/api/orders/score/cancel',  { params: p });
  }

  createOrder(createOrder: CreateTransport): Observable<HttpResponse<void>> {
    return this.http.post<void>('warehouse/api/orders', createOrder, {observe: 'response'});
  }

  updateOrder(id: number, updateOrder: UpdateTransport): Observable<void> {
    return this.http.put<void>('warehouse/api/orders/' + id, updateOrder);
  }

  deleteOrder(id: number) {
    return this.http.delete('warehouse/api/orders/' + id);
  }
}
