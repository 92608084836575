import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { merge, of as observableOf} from 'rxjs';
import { startWith, switchMap, catchError, map } from 'rxjs/operators';
import { CustomerView } from 'src/app/shared/customers/customer.model';
import { CustomersService } from 'src/app/shared/customers/customers.service';
import { AlertService } from 'src/app/shared/utils/alert.service';
import { CustomersAddEditComponent } from './customers-add-edit/customers-add-edit.component';
import { DeviceDetectorService } from 'ngx-device-detector';
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements AfterViewInit, OnInit {
  displayedColumns = ['lastname', 'firstname', 'email', 'phone', 'address', 'actions'];
  data: MatTableDataSource<CustomerView> = new MatTableDataSource<CustomerView>();
  resultsLength = 0;
  isLoadingResults = true;

  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatInput) filter: MatInput;

  constructor(private customersService: CustomersService,
              public dialog: MatDialog,
              private alert: AlertService,
              private deviceDetectorService: DeviceDetectorService) { }

  ngOnInit() {
    if (this.deviceDetectorService.isMobile()) {
      this.displayedColumns = ['lastname', 'address', 'actions'];
    }
  }

  ngAfterViewInit() {
    merge(this.paginator.page)
    .pipe(
      startWith({}),
      switchMap(() => {
        this.isLoadingResults = true;
        return this.customersService.findAllCustomers(this.paginator.pageIndex, this.paginator.pageSize, this.filter.value);
      }),
      map(data => {
        // Flip flag to show that loading has finished.
        this.isLoadingResults = false;
        this.resultsLength = data.totalElements;

        return data.elements;
      }),
      catchError(() => {
        this.isLoadingResults = false;
        return observableOf([]);
      })
    ).subscribe(data => this.data.data = data);
  }


  filterChanged() {
    if (this.filter.value.length >= 3) {
      this.paginator.firstPage();
      this.updateData();
    }
  }

  updateData() {
    this.customersService.findAllCustomers(this.paginator.pageIndex, this.paginator.pageSize, this.filter.value)
      .subscribe(res => {
        this.data.data = res.elements;
        this.resultsLength = res.totalElements;
      });
  }

  openCreateModal() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(CustomersAddEditComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.alert.info('customers.create-success');
        this.updateData();
      }
    });
  }
}
