import { Injectable } from '@angular/core';
import { HttpResourcesResponse } from '../utils/HttpResourcesResponse.model';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';

export class ItemType {
  id: number;
  reference: string;
  label: string;
}

export class Item {
  itemType: ItemType;
  quantity: number;
}

@Injectable({
  providedIn: 'root'
})
export class ItemTypeService {

  constructor(private http: HttpClient) { }

  findAllItemTypes(page: number, size: number, filter: string): Observable<HttpResourcesResponse<ItemType>> {
    let  p = new HttpParams();
    p = p.append('page', String(page));
    p = p.append('size', String(size));
    p = p.append('filter', filter);
    return this.http.get<HttpResourcesResponse<ItemType>>('warehouse/api/item-types', {params : p});
  }

}
