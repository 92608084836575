import { Injectable } from '@angular/core';
import * as jsPDF from 'jspdf';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { FullOrderView } from '../orders/base-order.model';
import { CustomerView } from '../customers/customer.model';
import { WarehouseService } from '../warehouse/warehouse.service';
const FILE_EXTENSION_PDF = '.pdf';

@Injectable()
export class PdfService {

  private line: number;

  constructor(private translateService: TranslateService, private warehouseService: WarehouseService) { }

  public generatePurchaseOrder(order: FullOrderView): void {
    this.warehouseService.getConfiguration().subscribe(res => {
      if (order.serviceType === 'PICKUP') {
        this.generatePDF(order, order.child, res.customFieldIdForOrderNumber);
       } else {
        this.generatePDF(order.child, order, res.customFieldIdForOrderNumber);
       }
    });
  }

  private generatePDF(pickupOrder: FullOrderView, deliveryOrder: FullOrderView, customFieldIdToAdd: number): void {
    const doc = new jsPDF.jsPDF({ orientation: 'landscape' });
    this.line = 20;
    doc.setFontSize(22);
    doc.text(this.translateService.instant('purchase-order.sender') + ' : ',20, this.line);
    doc.setFontSize(12);
    this.addNumberOfLines(10);
    doc.setFont(doc.getFont().fontName, 'bold');
    const customerSplit = doc.splitTextToSize(this.getCustomerLabel(pickupOrder.customer), 130);
    doc.text(customerSplit,20, this.line );
    this.addNumberOfLines(5 * customerSplit.length);
    doc.setFont(doc.getFont().fontName, 'normal');
    const addressSplit = doc.splitTextToSize(pickupOrder.customer.address, 130);
    doc.text(addressSplit,20, this.line);
    this.addNumberOfLines(5 * addressSplit.length);
    if (pickupOrder.customer.phoneNumber) {
      this.addCustomerData(pickupOrder.customer.phoneNumber, doc, 'purchase-order.phone-number');
    }

    this.line = 80;
    doc.setFontSize(18);
    doc.text(this.translateService.instant('purchase-order.removal-date'),110, this.line );
    this.addNumberOfLines(10);
    const scheduledDate = moment(pickupOrder.scheduledDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    doc.text(scheduledDate, 130, this.line);
    this.addNumberOfLines(20);
    doc.text(this.translateService.instant('purchase-order.delivery-date'),105, this.line);
    this.addNumberOfLines(10);
    doc.text(scheduledDate, 130, this.line);

    this.addNumberOfLines(20);
    doc.setFontSize(22);
    doc.text(this.translateService.instant('purchase-order.customer-address') + ' : ',20, this.line);
    doc.setFontSize(12);
    this.addNumberOfLines(10);
    doc.setFont(doc.getFont().fontName, 'bold');
    doc.text(this.getCustomerLabel(deliveryOrder.customer),20, this.line);
    doc.setFont(doc.getFont().fontName, 'normal');
    this.addNumberOfLines(5);
    doc.text(deliveryOrder.customer.address,20, this.line );
    this.addNumberOfLines(5);
    if (deliveryOrder.customer.phoneNumber) {
      this.addCustomerData(deliveryOrder.customer.phoneNumber, doc, 'purchase-order.phone-number');
    }
    if (deliveryOrder.customer.mobileNumber) {
      this.addCustomerData(deliveryOrder.customer.mobileNumber, doc, 'purchase-order.mobile-number');
    }
    if (deliveryOrder.customer.email) {
      this.addCustomerData(deliveryOrder.customer.email, doc, 'detail.customer.email');
    }
    if (deliveryOrder.customer.comments) {
      this.addCustomerData(deliveryOrder.customer.comments, doc, 'detail.customer.comments');
    }
    this.line = 20;

    doc.setFontSize(20);

    if (customFieldIdToAdd && this.customFieldIdExist(pickupOrder, deliveryOrder, customFieldIdToAdd)) {
      doc.text(doc.splitTextToSize(this.getCustomFieldName(pickupOrder, deliveryOrder, customFieldIdToAdd) + ' : ', 100),155, this.line);
      this.addNumberOfLines(10);
      doc.setFont(doc.getFont().fontName, 'bold');
      doc.setFontSize(20);
      doc.text(this.getCustomFieldValue(pickupOrder, deliveryOrder, customFieldIdToAdd),155, this.line);
    } else {
      this.addNumberOfLines(10);
    }

    doc.setFontSize(12);
    this.addNumberOfLines(15);
    doc.setFont(doc.getFont().fontName, 'normal');
    doc.text(this.translateService.instant('purchase-order.batch-number') + ' : ',155, this.line);


    doc.rect(15, 10, 135, 50);
    doc.rect(150, 10, 135, 50);
    doc.rect(150, 10, 135, 25);
    doc.rect(15, 60, 270, 70);
    doc.rect(15, 130, 270, 70);

    doc.save(this.translateService.instant('purchase-order.file-name') + '-' + scheduledDate + FILE_EXTENSION_PDF);
  }

  private addCustomerData(data: string, doc: jsPDF.jsPDF, translateKey: string): void {
    doc.text(this.translateService.instant(translateKey) + ' : ' + data, 20, this.line);
    this.addNumberOfLines(5);
  }

  private addNumberOfLines(lines: number): void {
    this.line = this.line + lines;
  }

  public getCustomerLabel(customer: CustomerView): string {
    let label = customer.lastName;
    if (customer.firstName) {
      label += ' ' + customer.firstName;
    }
    return label;
  }


  private customFieldIdExist(pickupOrder: FullOrderView, deliveryOrder: FullOrderView, customFieldIdToAdd: number) {
    let parent: FullOrderView;
    if (pickupOrder.child)  {
      parent = pickupOrder;
    } else {
      parent = deliveryOrder;
    }

    return parent.fields.findIndex(f => f.field.id === customFieldIdToAdd) !== -1;
  }

  private getCustomFieldName(pickupOrder: FullOrderView, deliveryOrder: FullOrderView, customFieldIdToAdd: number): string {
    let parent: FullOrderView;
    if (pickupOrder.child)  {
      parent = pickupOrder;
    } else {
      parent = deliveryOrder;
    }

    return parent.fields.find(f => f.field.id === customFieldIdToAdd).field.name;
  }

  private getCustomFieldValue(pickupOrder: FullOrderView, deliveryOrder: FullOrderView, customFieldIdToAdd: number): string {
    let parent: FullOrderView;
    if (pickupOrder.child)  {
      parent = pickupOrder;
    } else {
      parent = deliveryOrder;
    }

    return parent.fields.find(f => f.field.id === customFieldIdToAdd).value;
  }

}
