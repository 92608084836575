import {ServiceType, State} from './light-order.model';
import {CustomerView} from '../customers/customer.model';
import {TimeSlot} from './timeslot.model';
import { Skill } from '../resources/skill.service';
import { FullCapacity, Capacity } from '../resources/capacity-type.service';
import { WarehouseView } from '../warehouse/warehouse.model';
import { OrderType } from '../resources/order-type.service';
import { OrderCustomFields, CustomFieldValue } from '../resources/custom-fields.service';
import { Item } from '../resources/item-types.service';

export class BaseOrder {

  id?: number;

  serviceType: ServiceType;

  comments?: string;

  duration: number;

  customer: CustomerView = new CustomerView();

  theoreticalArrivalTime?: string;

  timeSlot?: TimeSlot;

  scheduledDate?: string;

  terminatedDate?: string;

  openDate?: string;

  dueDate?: string;

  state?: State;

  assignedVehicle?: string;

  assignedAgent?: string;

  fields: CustomFieldValue[] = [];

  items: Item[] = [];

}

export class CustomerReview {
  rating: 0 | 1 | 2 | 3 | 4 | 5;
  comments: string;
  token: string;
}

export class FullOrderView extends BaseOrder {
  skill?: Skill;
  orderType?: OrderType;
  capacities: FullCapacity[];
  customerReview?: CustomerReview;
  child: FullOrderView;
  resolutionComments?: string;
  hasSigning: boolean;
  nbPhoto: number;
}


export class UpdateTransport extends OrderCustomFields {
  customerDuration: number;
  customerTimeSlot: TimeSlot;
  customerTimeSlotId: number;

  warehouseDuration: number;
  warehouseTimeSlot: TimeSlot;
  warehouseTimeSlotId: number;

  comment: string;

  skillId: number;
  skill: Skill;

  capacities: Capacity[];

  openDate?: string;

  dueDate?: string;

  orderTypeId: number;
  orderType: OrderType;

}


export class CreateTransport extends UpdateTransport {
  serviceType: ServiceType;

  customer: CustomerView;
  customerId: number;

  shop: WarehouseView;
  warehouseId: number;
  items: Item[];
}

export type InfeasibleReason =
  'NOT_YET_OPTIMIZED' |
  'TIME_CONSTRAINT' |
  'SKILL_CONSTRAINT' |
  'CAPACITY_CONSTRAINT' |
  'TRAFFIC_CONSTRAINT' |
  'PLANNING_LOCKED' |
  'DISTANCE_CONSTRAINT' |
  'END_WORKING_TIME_CONSTRAINT' |
  'AGENT_CONSTRAINT' |
  'UNKNOWN';

export class InsertionStatus {
  date: Date;
  timeSlot: TimeSlot;
  insertionCost: number;
  reason: InfeasibleReason;

  //only for display
  selected: boolean;
}

export class ScoreOrderReport {
  uniqueOrderScoreIdentifier: string;
  timeoutTimestamp: number;
  status: InsertionStatus[];
}

export class ScoreOrderValidation {
  uniqueOrderScoreIdentifier: string;
  date: Date;
  timeSlot: TimeSlot;
  orderId: number;
}
