import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/utils/alert.service';
import { OrderService } from 'src/app/shared/orders/orders.service';
import { CreateTransport } from 'src/app/shared/orders/base-order.model';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import * as _moment from 'moment';
import { TimeSlotService } from 'src/app/shared/resources/time-slot.service';
import { TimeSlot } from 'src/app/shared/orders/timeslot.model';
import { Router } from '@angular/router';
import { DATE_FORMATS } from 'src/app/app.component';
import { Subscription } from 'rxjs';
import { WarehouseService } from 'src/app/shared/warehouse/warehouse.service';
import { Configuration } from 'src/app/shared/warehouse/configuration.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatSelectChange } from '@angular/material/select';
import { AuthService } from 'src/app/shared/auth/auth.service';

const moment = _moment;

export type Step = 'CONFIRM_DATE' | 'CONFIRM_TIMESLOT' | 'CONFIRM_INFORMATION';
export const CONFIRM_DATE: Step = 'CONFIRM_DATE';
export const CONFIRM_TIMESLOT: Step = 'CONFIRM_TIMESLOT';
export const CONFIRM_INFORMATION: Step = 'CONFIRM_INFORMATION';

export interface TransportToCreate {
  transport: CreateTransport;
}

@Component({
  selector: 'app-save-to-basket',
  templateUrl: './save-to-basket.component.html',
  styleUrls: ['./save-to-basket.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class SaveToBasketComponent implements OnInit, OnDestroy {

  minDate = new UntypedFormControl(moment().add(1, 'days'));
  maxDate = new UntypedFormControl(moment().add(4, 'days'));
  today = moment();

  currentStep: Step = CONFIRM_DATE;

  timeSlots: TimeSlot[] = [];
  selectedTimeSlot: TimeSlot;
  saveTransportSubscription: Subscription;
  configuration: Configuration;
  isMobile = this.deviceDetectorService.isMobile();
  isStoreDefaultLoading = this.authService.currentUserValue.configuration.storeAsDefaultLoading;

  constructor(
    private router: Router, public dialogRef: MatDialogRef<SaveToBasketComponent>, private warehouseService: WarehouseService, private authService: AuthService,
    private timeSlotService: TimeSlotService, private alert: AlertService, private orderService: OrderService, private deviceDetectorService: DeviceDetectorService,
    @Inject(MAT_DIALOG_DATA) public data: TransportToCreate) { }

  ngOnInit() {
      this.warehouseService.getConfiguration().subscribe(configuration => {
        this.configuration = configuration;
        this.minDate.setValue(moment().add(1, 'days'));
        this.maxDate.setValue(moment().add(configuration.basketPeriodInDays, 'days'));
      });
    this.timeSlotService.findTimeSlots().subscribe(res => {
      this.timeSlots = res;
    });
  }

  minDateChange() {
    if (this.minDate.value.isAfter(this.maxDate.value)) {
      this.maxDate.setValue(moment(this.minDate.value).add(this.configuration.basketPeriodInDays -1, 'days'));
    }
  }

  maxDateChange() {
    if (this.maxDate.value.isBefore(this.minDate.value)) {
      this.minDate.setValue(this.maxDate.value);
    }
  }

  confirmDate() {
    this.data.transport.openDate = this.minDate.value.format('YYYY-MM-DD');
    this.data.transport.dueDate = this.maxDate.value.format('YYYY-MM-DD');

    if ( this.data.transport.orderType && this.data.transport.orderType.timeSlotStart && this.data.transport.orderType.timeSlotEnd ) {
      const transiantTimeSlot = new TimeSlot();
      transiantTimeSlot.from = this.data.transport.orderType.timeSlotStart;
      transiantTimeSlot.to = this.data.transport.orderType.timeSlotEnd;
      this.selectedTimeSlot = transiantTimeSlot;
      this.currentStep = CONFIRM_INFORMATION;

    } else {
      this.currentStep = CONFIRM_TIMESLOT;
    }
  }

  chooseTimeSlot(timeSlot: TimeSlot) {
    this.timeSlots.forEach(t => t.selected = false);
    this.timeSlots.find(t => t.id === timeSlot.id).selected = true;
    this.selectedTimeSlot = timeSlot;
  }

  confirmTimeSlot() {
    this.data.transport.customerTimeSlot = this.selectedTimeSlot;
    this.currentStep = CONFIRM_INFORMATION;
  }

  saveTransport() {
    this.saveTransportSubscription = this.orderService.createOrder(this.data.transport).subscribe(res => {
      const location = res.headers.get('Location');
      const id = location.substring(location.lastIndexOf('/') + 1);
      this.alert.info('orders.save-to-basket.success');
      this.dialogRef.close(true);
      this.router.navigate(['/transport']);
    }, err => {
      this.alert.error(err);
    });
  }

  timeSlotSelected(selected: MatSelectChange) {
    console.log(selected);
    this.selectedTimeSlot = this.timeSlots.find(t => t.id === Number(selected.value));
  }

  ngOnDestroy(): void {
    if (this.saveTransportSubscription) {
      this.saveTransportSubscription.unsubscribe();
    }
  }
}
