import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './shared/auth/auth.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfigurationModalComponent } from './shared/configuration-modal/configuration-modal.component';

export const DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent  implements OnInit {
  constructor(private translateService: TranslateService, private auth: AuthService, private dialog: MatDialog) {
    translateService.addLangs(['fr', 'en']);
    translateService.setDefaultLang('fr');
    translateService.use('fr');
  }

  ngOnInit() {
    this.translateService.addLangs(['fr', 'en']);
    this.translateService.setDefaultLang('fr');
    this.translateService.use('fr');
    const user = this.auth.currentUserValue;
    if (user.configuration.terminology) {
      this.translateService.use(user.configuration.terminology + "-" + user.language);
    } else {
      this.translateService.use('fr');
    }

  }
}
