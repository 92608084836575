import {Component, Input, OnInit} from '@angular/core';
import {FullOrderView} from '../orders/base-order.model';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit {

  @Input() order: FullOrderView;
  mobile: boolean = this.mobileDetector.isMobile();

  stepper = {
    created: true,
    planned: false,
    progress: false,
    confirmed: false,
    canceled: false,
    done: false,
  };

  constructor(private mobileDetector: DeviceDetectorService) {
  }

  ngOnInit() {
    console.log(this.mobileDetector.isMobile());
    this.mobile = this.mobileDetector.isMobile();
    this.configureStepper();
  }

  currentColor(): string {
    switch (this.order.state) {
      case 'CANCELED':
        return 'red ' + (this.mobile ? ' mobile' : '');
     default:
        return 'green' + (this.mobile ? ' mobile' : '');
    }
  }

  configureStepper(): void {
    this.stepper.created = true;
    this.stepper.planned = true;
    switch (this.order.state) {
      case 'CREATED':
      case 'DEPROGRAMMED':
        this.stepper.planned = false;
        break;
      case 'CANCELED':
        this.stepper.canceled = true;
        break;
      case 'PLANNED' :
        if (this.isInProgress()) {
          this.stepper.confirmed = true;
          this.stepper.progress = true;
        }
        break;
      case 'CONFIRMED':
        this.stepper.confirmed = true;
        this.stepper.progress = this.isInProgress();
        break;
      case 'PENDING':
      case 'REPROGRAMMED':
      case 'UNASSIGNED': {
        this.stepper.progress = this.isInProgress();
        break;
      }
      case 'DONE':
        this.stepper.confirmed = true;
        this.stepper.progress = true;
        this.stepper.done = true;
        break;
    }
  }

  isInProgress() {
    const s = this.order.state;
    const childState = this.order.child.state;
    return (moment()
        .startOf('d')
        .isSame(moment(this.order.scheduledDate, 'YYYY-MM-DD')) &&
      (s === 'PLANNED' || s === 'CONFIRMED' || s === 'PENDING')) || (childState === 'DONE');
  }
}
