import { CustomDisplayService } from "./../utils/custom-display.service";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { OrderService } from "../orders/orders.service";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import * as _moment from "moment";
import { FullOrderView, UpdateTransport } from "../orders/base-order.model";
import { ResourcesService } from "../resources/resources.service";
import { OrderType } from "../resources/order-type.service";
import { Skill } from "../resources/skill.service";
import { CapacityType, Capacity } from "../resources/capacity-type.service";
import { TimeSlot } from "../resources/time-slot.service";
import { CREATED } from "../orders/light-order.model";
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { MatDialog } from "@angular/material/dialog";
import { MatSelectChange } from "@angular/material/select";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DATE_FORMATS } from "src/app/app.component";
import { AlertService } from "../utils/alert.service";
import { DomSanitizer } from "@angular/platform-browser";
import { ImageModalComponent } from "../image-modal/image-modal.component";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import {
  CustomField,
  CustomFieldType,
  OrderCustomFields,
  CustomFieldsService,
} from "../resources/custom-fields.service";
import { DisplayField } from "../warehouse/configuration.model";
import { RequiredOrderTypeValidator } from "../utils/required-order-type.validator";
import { Subscription } from "rxjs";
import { AuthService } from "../auth/auth.service";

const moment = _moment;

enum PossibleState {
  DONE = "DONE",
  CREATED = "CREATED",
  PLANNED = "PLANNED",
  CANCELED = "CANCELED",
}

export class UpdateOrderFields extends OrderCustomFields {}

@Component({
  selector: "app-details",
  templateUrl: "./details.component.html",
  styleUrls: ["./details.component.scss"],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: "fr" },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class DetailsComponent implements OnInit, OnDestroy {
  orderId: number;

  minDate = new UntypedFormControl(moment());
  maxDate = new UntypedFormControl(moment());

  isEditable = false;
  appearance = "outline";

  orderDetailsFormGroup: UntypedFormGroup;
  orderSaved: FullOrderView;
  isLoading = true;
  isDone = false;
  currentDate = moment(new Date());

  orderTypes: OrderType[];
  skills: Skill[];
  capacityTypes: CapacityType[];
  timeSlots: TimeSlot[];
  orderFields: CustomField[];
  capacities: Capacity[] = [];
  updatedOrderFields: UpdateOrderFields;

  signingUrl: any;
  photosUrl: any[] = [];
  transportSubscription: Subscription;
  isStoreAsDefaultLoading = this.authService.isStoreAsDefaultLoading();

  CustomFieldType = CustomFieldType;

  public DisplayField = DisplayField;

  constructor(
    private orderService: OrderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private resourcesService: ResourcesService,
    private alertService: AlertService,
    private sanitizer: DomSanitizer,
    public dialog: MatDialog,
    public translate: TranslateService,
    public customFieldsService: CustomFieldsService,
    private customDisplayService: CustomDisplayService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.orderId = Number(this.activatedRoute.snapshot.paramMap.get("orderId"));
    this.resourcesService.getAllResources().subscribe((results) => {
      this.orderTypes = results[0];
      this.skills = results[1];
      this.capacityTypes = results[2];
      this.timeSlots = results[3];
      const customFieldsCategories = results[4];
      this.orderFields = [];
      customFieldsCategories.forEach((category) => {
        this.orderFields.push(...category.customFields);
      });

      this.orderService.getById(this.orderId).subscribe((order) => {
        this.orderSaved = order;
        this.updatedOrderFields = new UpdateOrderFields();
        this.updatedOrderFields.fields = order.fields;
        this.minDate.setValue(moment(this.orderSaved.openDate, "YYYY-MM-DD"));
        this.maxDate.setValue(moment(this.orderSaved.dueDate, "YYYY-MM-DD"));

        if (this.orderSaved.capacities) {
          this.orderSaved.capacities.forEach((fullCap) => {
            const capacity = new Capacity();
            capacity.value = fullCap.value;
            capacity.capacityTypeId = fullCap.capacityType.id;
            this.capacities.push(capacity);
          });
        }

        if (this.orderSaved.hasSigning) {
          this.orderService.getSigning(this.orderId).subscribe((signing) => {
            const blob = new Blob([signing], { type: "image/jpeg" });
            const unsafeImageUrl = URL.createObjectURL(blob);
            this.signingUrl =
              this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl);
          });
        }

        if (this.orderSaved.nbPhoto > 0) {
          Array.from(Array(this.orderSaved.nbPhoto).keys()).forEach((index) => {
            this.orderService
              .getPhoto(this.orderId, index)
              .subscribe((signing) => {
                const blob = new Blob([signing], { type: "image/jpeg" });
                const unsafeImageUrl = URL.createObjectURL(blob);
                this.photosUrl.push(
                  this.sanitizer.bypassSecurityTrustUrl(unsafeImageUrl)
                );
              });
          });
        }
        this.orderSaved.state === PossibleState.DONE
          ? (this.isDone = true)
          : (this.isDone = false);
        if (order.state === CREATED) {
          this.isEditable = true;
          this.appearance = "legacy";
        }
        this.buildForm();
        this.addCapacityFields();
        this.addCustomFields();
        this.isLoading = false;
      });
    });
  }

  buildForm(): void {
    this.orderDetailsFormGroup = this.formBuilder.group({
      shopName: new UntypedFormControl({
        value: this.orderSaved.child.customer.lastName,
        disabled: true,
      }),
      shopAddress: new UntypedFormControl({
        value: this.orderSaved.child.customer.address,
        disabled: true,
      }),
      warehouseTimeSlotId: new UntypedFormControl({
        value: String(this.orderSaved.child.timeSlot.id),
      }),
      warehouseDuration: new UntypedFormControl(
        Number(this.orderSaved.child.duration).toString()
      ),
      customerName: new UntypedFormControl({
        value:
          this.orderSaved.customer.lastName +
          " " +
          this.orderSaved.customer.firstName,
        disabled: true,
      }),
      customerAddress: new UntypedFormControl({
        value: this.orderSaved.customer.address,
        disabled: true,
      }),
      customerMobilePhone: new UntypedFormControl({
        value: this.orderSaved.customer.mobileNumber,
        disabled: true,
      }),
      customerMail: new UntypedFormControl({
        value: this.orderSaved.customer.email,
        disabled: true,
      }),
      customerTimeSlotId: new UntypedFormControl({
        value: String(this.orderSaved.timeSlot.id),
      }),
      customerComment: new UntypedFormControl({
        value: this.orderSaved.customer.comments,
        disabled: true,
      }),
      customerDuration: new UntypedFormControl(this.orderSaved.duration),
      comment: new UntypedFormControl(this.orderSaved.comments),
      skillId: new UntypedFormControl(),
      orderTypeId: new UntypedFormControl(
        null,
        this.isRequired(DisplayField.ORDER_TYPE)
          ? RequiredOrderTypeValidator
          : null
      ),
      scheduledDate: new UntypedFormControl(moment(this.orderSaved.scheduledDate)),

      reportScheduledTime: new UntypedFormControl(
        moment(this.orderSaved.theoreticalArrivalTime, "HH:mm:ss").format(
          "HH:mm"
        )
      ),
      reportArrivalTime: new UntypedFormControl(
        moment(this.orderSaved.terminatedDate).format("HH:mm")
      ),
      reportAgent: new UntypedFormControl(this.orderSaved.assignedAgent),
      reportVehicle: new UntypedFormControl(this.orderSaved.assignedVehicle),
      reportComment: new UntypedFormControl(this.orderSaved.resolutionComments),
      reportCustomerFeedback: new UntypedFormControl(""),
    });
    this.orderDetailsFormGroup.get("scheduledDate").disable();
    this.orderDetailsFormGroup.get("reportScheduledTime").disable();
    this.orderDetailsFormGroup.get("reportArrivalTime").disable();
    this.orderDetailsFormGroup.get("reportAgent").disable();
    this.orderDetailsFormGroup.get("reportVehicle").disable();
    this.orderDetailsFormGroup.get("reportComment").disable();
    this.orderDetailsFormGroup.get("reportCustomerFeedback").disable();

    if (!this.isStoreAsDefaultLoading) {
      this.orderDetailsFormGroup.get("shopName").setValue(this.orderSaved.customer.lastName);
      this.orderDetailsFormGroup.get("shopAddress").setValue(this.orderSaved.customer.address);
      this.orderDetailsFormGroup.get("warehouseTimeSlotId").setValue(String(this.orderSaved.timeSlot.id));
      this.orderDetailsFormGroup.get("warehouseDuration").setValue(this.orderSaved.duration);

      this.orderDetailsFormGroup.get("customerName").setValue(this.orderSaved.child.customer.lastName);
      this.orderDetailsFormGroup.get("customerAddress").setValue(this.orderSaved.child.customer.address);
      this.orderDetailsFormGroup.get("customerMobilePhone").setValue(this.orderSaved.child.customer.mobileNumber);
      this.orderDetailsFormGroup.get("customerMail").setValue(this.orderSaved.child.customer.email);
      this.orderDetailsFormGroup.get("customerComment").setValue(this.orderSaved.child.customer.comments);
      this.orderDetailsFormGroup.get("customerDuration").setValue(this.orderSaved.child.duration);
      this.orderDetailsFormGroup.get("customerTimeSlotId").setValue(String(this.orderSaved.child.timeSlot.id));

    }

    // select fields initialization
    this.orderDetailsFormGroup.get("skillId").setValue("-1");
    if (this.orderSaved.skill) {
      this.orderDetailsFormGroup
        .get("skillId")
        .setValue(String(this.orderSaved.skill.id));
    }

    this.orderDetailsFormGroup.get("orderTypeId").setValue("-1");
    if (this.orderSaved.orderType) {
      this.orderDetailsFormGroup
        .get("orderTypeId")
        .setValue(String(this.orderSaved.orderType.id));
      this.orderDetailsFormGroup.get("customerDuration").disable();
      this.orderDetailsFormGroup.get("skillId").disable();
      if (
        this.orderSaved.orderType.timeSlotStart &&
        this.orderSaved.orderType.timeSlotEnd
      ) {
        this.orderDetailsFormGroup.get("customerTimeSlotId").disable();
      } else {
        this.orderDetailsFormGroup.get("customerTimeSlotId").enable();
      }
    }

    if (this.orderSaved.child.timeSlot) {
      this.orderDetailsFormGroup
        .get("warehouseTimeSlotId")
        .setValue(String(this.orderSaved.child.timeSlot.id));
    }
    if (this.orderSaved.timeSlot) {
      this.orderDetailsFormGroup
        .get("customerTimeSlotId")
        .setValue(String(this.orderSaved.timeSlot.id));
    }

    if (this.orderSaved.customerReview) {
      this.orderDetailsFormGroup
        .get("reportCustomerFeedback")
        .setValue(this.orderSaved.customerReview.comments);
    }

    if (!this.isEditable) {
      this.orderDetailsFormGroup.get("warehouseTimeSlotId").disable();
      this.orderDetailsFormGroup.get("warehouseDuration").disable();

      this.orderDetailsFormGroup.get("customerTimeSlotId").disable();
      this.orderDetailsFormGroup.get("customerDuration").disable();
      this.orderDetailsFormGroup.get("comment").disable();
      this.orderDetailsFormGroup.get("skillId").disable();
      this.orderDetailsFormGroup.get("orderTypeId").disable();
    }

    this.setupOnChangeOrderType();
  }

  private setupOnChangeOrderType() {
    this.orderDetailsFormGroup
      .get("orderTypeId")
      .valueChanges.subscribe((orderTypeSelected) => {
        if (Number(orderTypeSelected) === -1) {
          this.orderDetailsFormGroup.get("customerDuration").enable();
          this.orderDetailsFormGroup.get("skillId").enable();
          this.orderDetailsFormGroup.get("customerTimeSlotId").enable();
        } else {
          const orderType = this.orderTypes.find(
            (s) => s.id === Number(orderTypeSelected)
          );
          this.orderDetailsFormGroup.get("customerDuration").disable();
          this.orderDetailsFormGroup
            .get("customerDuration")
            .setValue(orderType.duration);
          this.orderDetailsFormGroup.get("skillId").disable();
          if (orderType.skill) {
            this.orderDetailsFormGroup
              .get("skillId")
              .setValue(String(orderType.skill.id));
          }
          if (orderType.timeSlotStart && orderType.timeSlotEnd) {
            const timeSlot = this.timeSlots.find(
              (t) =>
                t.from === orderType.timeSlotStart &&
                t.to === orderType.timeSlotEnd
            );
            this.orderDetailsFormGroup
              .get("customerTimeSlotId")
              .setValue(String(timeSlot.id));
            this.orderDetailsFormGroup.get("customerTimeSlotId").disable();
          } else {
            this.orderDetailsFormGroup.get("customerTimeSlotId").enable();
          }
        }
      });
  }

  private addCapacityFields() {
    this.capacityTypes.forEach((capacityType) => {
      let value = "0";
      if (
        this.capacities.findIndex(
          (c) => c.capacityTypeId === capacityType.id
        ) !== -1
      ) {
        value = this.capacities
          .find((c) => c.capacityTypeId === capacityType.id)
          .value.toString();
      }
      const control = new UntypedFormControl(value);
      if (!this.isEditable) {
        control.disable();
      }
      control.valueChanges.subscribe((x) => {
        if (!isNaN(x) && x !== "") {
          if (
            this.capacities.findIndex(
              (c) => c.capacityTypeId === capacityType.id
            ) !== -1
          ) {
            this.capacities.find(
              (c) => c.capacityTypeId === capacityType.id
            ).value = x;
          } else {
            const capacity = new Capacity();
            capacity.capacityTypeId = capacityType.id;
            capacity.value = x;
            this.capacities.push(capacity);
          }
        } else {
          const index = this.capacities.findIndex(
            (c) => c.capacityTypeId === capacityType.id
          );
          if (index > -1) {
            this.capacities.splice(index, 1);
          }
        }
      });
      this.orderDetailsFormGroup.addControl("cap" + capacityType.id, control);
    });
  }

  private addCustomFields() {
    this.orderFields.forEach((orderField) => {
      const control = new UntypedFormControl("");
      this.orderDetailsFormGroup.addControl("field" + orderField.id, control);
      if (!this.isEditable) {
        control.disable();
      }

      if (
        this.orderSaved.fields.findIndex(
          (f) => f.field.id === orderField.id
        ) !== -1
      ) {
        if (orderField.type === CustomFieldType.DATE) {
          const stringValue = this.orderSaved.fields.find(
            (f) => f.field.id === orderField.id
          ).value;
          this.orderDetailsFormGroup
            .get("field" + orderField.id)
            .setValue(moment(stringValue, "YYYY-MM-DD"));
        } else {
          this.orderDetailsFormGroup
            .get("field" + orderField.id)
            .setValue(
              this.orderSaved.fields.find((f) => f.field.id === orderField.id)
                .value
            );
        }
      }
    });
  }

  onChangeStringOrderField(orderField: CustomField, event): void {
    this.customFieldsService.onChangeOrderField(
      this.updatedOrderFields,
      orderField,
      event.target.value
    );
  }

  onChangeDateOrderField(orderField: CustomField, event): void {
    let selectedDate;
    if (event.target.value) {
      selectedDate = event.target.value.toDate();
    }
    this.customFieldsService.onChangeOrderField(
      this.updatedOrderFields,
      orderField,
      selectedDate
    );
  }

  onChangeBooleanOrderField(orderField: CustomField, event: any) {
    this.customFieldsService.onChangeBooleanOrderField(
      this.updatedOrderFields,
      orderField,
      event.checked
    );
  }

  onChangeSelectOrderField(
    orderField: CustomField,
    event: MatSelectChange
  ): void {
    this.customFieldsService.onChangeOrderField(
      this.updatedOrderFields,
      orderField,
      event.value
    );
  }

  minDateChange() {
    if (this.minDate.value.isAfter(this.maxDate.value)) {
      this.maxDate.setValue(moment(this.minDate.value).add(3, "days"));
    }
  }

  maxDateChange() {
    if (this.maxDate.value.isBefore(this.minDate.value)) {
      this.minDate.setValue(this.maxDate.value);
    }
  }

  goBack() {
    const customerId = Number(
      this.activatedRoute.snapshot.paramMap.get("customerId")
    );
    if (this.router.url.indexOf("reports") > 0) {
      this.router.navigate(["/reports"]);
    } else if (this.router.url.indexOf("transport") > 0) {
      this.router.navigate(["/transport"]);
    } else if (this.router.url.indexOf("customers") > 0) {
      this.router.navigate(["/customers/" + customerId]);
    }
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.orderDetailsFormGroup.controls[controlName].hasError(errorName);
  };

  updateTransport(value: UpdateTransport) {
    value.openDate = this.minDate.value.format("YYYY-MM-DD");
    value.dueDate = this.maxDate.value.format("YYYY-MM-DD");

    value.warehouseTimeSlot = this.timeSlots.find(
      (t) => t.id === Number(value.warehouseTimeSlotId)
    );
    value.customerTimeSlot = this.timeSlots.find(
      (t) => t.id === Number(value.customerTimeSlotId)
    );

    if (value.skillId === -1) {
      value.skill = undefined;
    } else {
      value.skill = this.skills.find((s) => s.id === Number(value.skillId));
    }

    if (value.orderTypeId === -1) {
      value.orderType = undefined;
    } else {
      value.orderType = this.orderTypes.find(
        (s) => s.id === Number(value.orderTypeId)
      );
    }

    value.capacities = this.capacities;
    value.fields = this.updatedOrderFields.fields;

    this.transportSubscription = this.orderService
      .updateOrder(this.orderId, value)
      .subscribe(
        (res) => {
          this.alertService.info("orders.updated");
        },
        (err) => {
          this.alertService.error(err);
          this.router.navigate(["/transport/" + this.orderId]);
        }
      );
  }

  deleteTransport() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "350px",
      data: this.translate.instant("orders.confirm-deletion"),
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.orderService.deleteOrder(this.orderId).subscribe(
          () => {
            this.alertService.info("orders.deleted");
            this.router.navigate([
              "/customers/" + this.orderSaved.customer.customerId,
            ]);
          },
          (err) => {
            this.alertService.error(err);
            this.router.navigate(["/transport/" + this.orderId]);
          }
        );
      }
    });
  }

  openImage(url: string) {
    const dialogRef = this.dialog.open(ImageModalComponent, {
      data: url,
    });
  }

  private displayField(displayField: DisplayField) {
    return this.customDisplayService.display(displayField);
  }

  private isRequired(displayField: DisplayField) {
    return this.customDisplayService.isRequired(displayField);
  }

  ngOnDestroy(): void {
    if (this.transportSubscription) {
      this.transportSubscription.unsubscribe();
    }
  }
}
