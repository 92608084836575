import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {CustomersService} from 'src/app/shared/customers/customers.service';
import { MatDialogRef } from '@angular/material/dialog';
import {AlertService} from 'src/app/shared/utils/alert.service';
import { ItemType } from 'src/app/shared/resources/item-types.service';

@Component({
  selector: 'app-add-item',
  templateUrl: './add-item.component.html',
  styleUrls: ['./add-item.component.scss']
})
export class AddItemComponent implements OnInit {

  itemType: ItemType;
  quantityFormGroup: UntypedFormGroup;
  quantity: number = 0;

  constructor(private formBuilder: UntypedFormBuilder,
              private customersService: CustomersService,
              public dialogRef: MatDialogRef<AddItemComponent>,
              private alert: AlertService) {
  }

  ngOnInit() {
    this.quantityFormGroup = this.formBuilder.group({
      quantity: new UntypedFormControl(this.quantity, [Validators.required]),
    });
  }

  save(value: any) {
    this.dialogRef.close(value.quantity)
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.quantityFormGroup.controls[controlName].hasError(errorName);
  }
}
