import {
  DisplaySetting,
  UpdateConfiguration,
  UpdateDisplaySetting,
  DisplayField,
  TRANSPORT_PROCESS,
} from "./../../../shared/warehouse/configuration.model";
import { Component, OnInit, OnDestroy } from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormArray,
  Validators,
} from "@angular/forms";
import { AlertService } from "src/app/shared/utils/alert.service";
import { WarehouseService } from "src/app/shared/warehouse/warehouse.service";
import { Configuration } from "../../../shared/warehouse/configuration.model";
import {
  CustomFieldsService,
  CustomField,
  CustomFieldType,
} from "src/app/shared/resources/custom-fields.service";
import {
  OrderTypeService,
  OrderType,
} from "./../../../shared/resources/order-type.service";
import { CustomDisplayService } from "src/app/shared/utils/custom-display.service";
import { Subscription } from "rxjs";

@Component({
  selector: "app-configuration",
  templateUrl: "./configuration.component.html",
  styleUrls: ["./configuration.component.scss"],
})
export class ConfigurationComponent implements OnInit, OnDestroy {
  settingsOrder = [
    DisplayField.WAREHOUSE_ADDRESS,
    DisplayField.WAREHOUSE_TIMESLOT,
    DisplayField.WAREHOUSE_DURATION,
    DisplayField.CUSTOMER_DELIVERY_DURATION,
    DisplayField.CUSTOMER_PICKUP_DURATION,
    DisplayField.ORDER_TYPE,
  ];

  configuration: UntypedFormGroup;
  settings: UntypedFormGroup;
  customFields: CustomField[];
  orderTypes: OrderType[] = [];
  displaySettings: DisplaySetting[] = [];
  configSubscription: Subscription;

  process: TRANSPORT_PROCESS;

  startDaysArray = [1, 2, 3];
  ndDaysArray = [3, 4, 5];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private alert: AlertService,
    private customFieldService: CustomFieldsService,
    private warehouseService: WarehouseService,
    private orderTypeService: OrderTypeService,
    private customDisplayService: CustomDisplayService
  ) {}

  ngOnInit() {
    this.findOrderTypes();
    this.buildForm();
    this.customFieldService.findCustomFields().subscribe((categories) => {
      this.customFields = [];
      categories.forEach((category) => {
        // custom field PHOTO not yet managed in warehouse
        const fields = category.customFields.filter(
          (field) => field.type !== CustomFieldType.PHOTO
        );
        this.customFields.push(...fields);
      });
    });
  }

  private findOrderTypes() {
    this.orderTypeService.findOrderTypes().subscribe((result) => {
      this.orderTypes = result;
    });
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.configuration.controls[controlName].hasError(errorName);
  };

  public fieldError = (control: any, errorName: string) => {
    return control.hasError(errorName);
  };

  save(configuration: Configuration) {
    const updateConfiguration = new UpdateConfiguration();
    updateConfiguration.displaySettings = [];

    updateConfiguration.iconUrl = configuration.iconUrl;
    updateConfiguration.scoringBeginDay = configuration.scoringBeginDay;
    updateConfiguration.scoringNbDayToScore = configuration.scoringNbDayToScore;
    updateConfiguration.scoringMinimumScore = configuration.scoringMinimumScore;

    if (Number(configuration.customFieldIdForOrderNumber) === -1) {
      updateConfiguration.customFieldIdForOrderNumber = undefined;
    } else {
      updateConfiguration.customFieldIdForOrderNumber = Number(
        configuration.customFieldIdForOrderNumber
      );
    }

    this.displaySettings.forEach((d) => {
      const updateDisplaySetting = new UpdateDisplaySetting();
      updateDisplaySetting.field = d.field;
      updateDisplaySetting.visible = d.visible.value;
      updateDisplaySetting.required = d.required.value;
      updateDisplaySetting.defaultValue = d.defaultValue.value;

      updateConfiguration.displaySettings.push(updateDisplaySetting);
    });

    this.configSubscription = this.warehouseService
      .setConfiguration(updateConfiguration)
      .subscribe(
        () => {
          this.alert.info("settings.configuration.update-success");
          this.customDisplayService.loadDisplaySettings();
        },
        (error) => this.alert.error(error)
      );
  }

  get arrayRequired(): UntypedFormArray {
    return this.configuration.get("arrayRequired") as UntypedFormArray;
  }

  get arrayVisible(): UntypedFormArray {
    return this.configuration.get("arrayVisible") as UntypedFormArray;
  }

  get arrayDefaultValue(): UntypedFormArray {
    return this.configuration.get("arrayDefaultValue") as UntypedFormArray;
  }

  private buildForm() {
    this.configuration = this.formBuilder.group(
      {
        iconUrl: new UntypedFormControl(""),
        customFieldIdForOrderNumber: new UntypedFormControl(String(-1)),
        arrayVisible: new UntypedFormArray([]),
        arrayRequired: new UntypedFormArray([]),
        arrayDefaultValue: new UntypedFormArray([]),
        scoringBeginDay: new UntypedFormControl(1),
        scoringNbDayToScore: new UntypedFormControl(3),
        scoringMinimumScore: new UntypedFormControl(0, [
          Validators.required,
          Validators.min(0),
          Validators.max(100),
        ]),
      },
      {}
    );

    this.warehouseService.getConfiguration().subscribe((config) => {
      this.process = config.process;

      this.displaySettings = config.displaySettings.sort((a, b) => {
        const aIndex = Number(
          this.settingsOrder.findIndex(
            (c) => c.toString() === a.field.toString()
          )
        );
        const bIndex = Number(
          this.settingsOrder.findIndex(
            (c) => c.toString() === b.field.toString()
          )
        );
        if (aIndex < bIndex) {
          return -1;
        } else {
          return 1;
        }
      });
      this.configuration
        .get("scoringBeginDay")
        .setValue(config.scoringBeginDay);
      this.configuration
        .get("scoringNbDayToScore")
        .setValue(config.scoringNbDayToScore);
      this.configuration
        .get("scoringMinimumScore")
        .setValue(config.scoringMinimumScore);

      this.configuration.get("iconUrl").setValue(config.iconUrl);
      if (config.customFieldIdForOrderNumber) {
        this.configuration
          .get("customFieldIdForOrderNumber")
          .setValue(String(config.customFieldIdForOrderNumber));
      }
      this.configuration.setControl(
        "arrayVisible",
        this.formBuilder.array(
          config.displaySettings.map(
            (c) =>
              new UntypedFormControl({
                value: c.visible.value,
                disabled: !c.visible.editable,
              })
          )
        )
      );
      this.configuration.setControl(
        "arrayRequired",
        this.formBuilder.array(
          config.displaySettings.map(
            (c) =>
              new UntypedFormControl({
                value: c.required.value,
                disabled: !c.required.editable,
              })
          )
        )
      );
      this.configuration.setControl(
        "arrayDefaultValue",
        this.formBuilder.array(
          config.displaySettings.map(
            (c) =>
              new UntypedFormControl({
                value: c.defaultValue.value,
                disabled: !c.defaultValue.editable,
              })
          )
        )
      );
    });
  }

  getDisplaySettings(field: DisplayField): DisplaySetting {
    return this.displaySettings.find((d) => d.field === field);
  }

  changeVisibility(field: DisplayField, event: any) {
    this.getDisplaySettings(field).visible.value = event.checked;
  }

  changeRequired(field: DisplayField, event: any) {
    this.getDisplaySettings(field).required.value = event.checked;
    if (event.checked) {
      const test = this.configuration.get("arrayDefaultValue") as UntypedFormArray;
      test.controls.forEach((c) => {
        c.markAsTouched();
      });
    }
  }

  changeDefaultValue(field: DisplayField, event: any) {
    const update = this.getDisplaySettings(field);
    if (event.target) {
      update.defaultValue.value = event.target.value;
    } else if (event.value) {
      update.defaultValue.value = event.value;
    }
  }

  ngOnDestroy(): void {
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
  }
}
