import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DATE_LOCALE, DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/shared/utils/alert.service';
import { OrderService } from 'src/app/shared/orders/orders.service';
import { CreateTransport, InsertionStatus, ScoreOrderValidation } from 'src/app/shared/orders/base-order.model';
import { MomentDateAdapter } from '@angular/material-moment-adapter';

import * as _moment from 'moment';
import { Router } from '@angular/router';
import { DATE_FORMATS } from 'src/app/app.component';
import { WarehouseService } from 'src/app/shared/warehouse/warehouse.service';
import { Configuration } from 'src/app/shared/warehouse/configuration.model';
import { Subscription } from 'rxjs';
const moment = _moment;

export type Step = 'CHOOSE_SCORE' | 'SCORING_IN_PROGRESS';
export const CHOOSE_SCORE: Step = 'CHOOSE_SCORE';
export const SCORING_IN_PROGRESS: Step = 'SCORING_IN_PROGRESS';

export interface TransportToCreate {
  transport: CreateTransport;
}

@Component({
  selector: 'app-score-and-save',
  templateUrl: './score-and-save.component.html',
  styleUrls: ['./score-and-save.component.scss'],
  providers: [
    // The locale would typically be provided on the root module of your application. We do it at
    // the component level here, due to limitations of our example generation script.
    { provide: MAT_DATE_LOCALE, useValue: 'fr' },

    // `MomentDateAdapter` and `MAT_MOMENT_DATE_FORMATS` can be automatically provided by importing
    // `MatMomentDateModule` in your applications root module. We provide it at the component level
    // here, due to limitations of our example generation script.
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
  ]
})
export class ScoreAndSaveComponent implements OnInit, OnDestroy {
  objectKeys = Object.keys;

  from = moment().add(1, 'days');
  to = moment().add(3, 'days');
  page = 0;

  currentStep: Step = SCORING_IN_PROGRESS;

  scores: {[date: string]: InsertionStatus[]} = {};
  scoresKey = [];
  selectedScore: InsertionStatus;

  uniqueOrderScoreIdentifier: string;
  countdownConfig = {};

  configuration: Configuration;
  saveTransportSubscription: Subscription;

  constructor(
    private router: Router, public dialogRef: MatDialogRef<ScoreAndSaveComponent>, private alert: AlertService,
    private orderService: OrderService, @Inject(MAT_DIALOG_DATA) public data: TransportToCreate,
    private warehouseService: WarehouseService) { }

  ngOnInit() {
    this.warehouseService.getConfiguration().subscribe(configuration => {
      this.configuration = configuration;
      this.from = moment().add(configuration.scoringBeginDay, 'days');
      this.to = moment().add( ( configuration.scoringBeginDay + configuration.scoringNbDayToScore - 1), 'days');
      this.score();
    });

  }

  score() {
    this.currentStep = SCORING_IN_PROGRESS;
    const unorderedScore = {};
    for (let i = 0; i < this.configuration.scoringNbDayToScore; i++) {
      const shift = (this.page * this.configuration.scoringNbDayToScore) + i;
      const date = moment().add( this.configuration.scoringBeginDay +  shift, 'days');
      unorderedScore[date.locale('fr').format('ll')] = [];
    }
    this.orderService.scoreOrder(this.data.transport, this.from.format('YYYY-MM-DD'), this.to.format('YYYY-MM-DD')).subscribe(res => {
      this.uniqueOrderScoreIdentifier = res.uniqueOrderScoreIdentifier;
      const countdownConfig = {template: '$!m!:$!s!', stopTime: res.timeoutTimestamp };
      this.countdownConfig = countdownConfig;

      res.status.forEach(score => {
        if (! score.reason) {
          const scoreDate = moment(score.date).locale('fr').format('ll');
          unorderedScore[scoreDate].push(score);
        }
      });
      const orderedKey = {};
      Object.keys(unorderedScore).sort((a, b) => {
        if (moment(a, 'll').isBefore(moment(b, 'll')) ) {
          return -1;
        } else if (moment(a, 'll').isAfter(moment(b, 'll')) ) {
          return 1;
        } else {
          return 0;
        }
      }).forEach(key => {
        orderedKey[key] = unorderedScore[key];
        orderedKey[key].sort((a, b) => {
          if (a.timeSlot.from === b.timeSlot.from) {
            return a.timeSlot.to.localeCompare(b.timeSlot.to);
          } else {
            return a.timeSlot.from.localeCompare(b.timeSlot.from);
          }
        });
      });
      this.scores = orderedKey;
      this.scoresKey = Object.keys(this.scores);
      this.currentStep = CHOOSE_SCORE;
    });
  }

  nextPage() {
    this.page = this.page + 1;
    this.from = moment().add(this.configuration.scoringBeginDay + (this.page * this.configuration.scoringNbDayToScore), 'days');
    this.to = moment().add((this.configuration.scoringBeginDay + this.configuration.scoringNbDayToScore) + (this.page * this.configuration.scoringNbDayToScore) - 1, 'days');
    if (this.uniqueOrderScoreIdentifier) {
      this.orderService.cancelScoreOrder(this.uniqueOrderScoreIdentifier).subscribe();
    }
    this.selectedScore = undefined;
    this.score();
  }

  previousPage() {
    if (this.page > 0) {
      this.page = this.page - 1;
      this.from = moment().add(this.configuration.scoringBeginDay + (this.page * this.configuration.scoringNbDayToScore), 'days');
      this.to = moment().add((this.configuration.scoringBeginDay + this.configuration.scoringNbDayToScore) + (this.page * this.configuration.scoringNbDayToScore) - 1, 'days');
      if (this.uniqueOrderScoreIdentifier) {
        this.orderService.cancelScoreOrder(this.uniqueOrderScoreIdentifier).subscribe();
      }
      this.selectedScore = undefined;
      this.score();
    }
  }

  selectScore(score: InsertionStatus) {
    Object.keys(this.scores).forEach(key => {
      this.scores[key].forEach(val => val.selected = false);
    });
    this.selectedScore = score;
    this.selectedScore.selected = true;
  }

  saveTransport() {
    const validation = new ScoreOrderValidation();
    validation.uniqueOrderScoreIdentifier = this.uniqueOrderScoreIdentifier;
    validation.date = this.selectedScore.date;
    validation.timeSlot = this.selectedScore.timeSlot;

    this.saveTransportSubscription = this.orderService.validateScoreOrder(validation).subscribe(res => {
      this.alert.info('orders.save-to-basket.success');
      this.dialogRef.close(true);
      this.router.navigate(['/customers/' + this.data.transport.customerId]);
    });
  }

  discardScoreOrder() {
    if (this.uniqueOrderScoreIdentifier) {
      this.orderService.cancelScoreOrder(this.uniqueOrderScoreIdentifier).subscribe(res => {
        this.score();
      },
      err => {
        this.alert.error(err);
      });

    }
  }

  close() {
    if (this.uniqueOrderScoreIdentifier) {
      this.orderService.cancelScoreOrder(this.uniqueOrderScoreIdentifier).subscribe(res => {
        this.dialogRef.close();
      }, err => {
        this.alert.error(err);
        this.dialogRef.close();
      });
    } else {
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    if (this.saveTransportSubscription) {
      this.saveTransportSubscription.unsubscribe();
    }
  }

}
