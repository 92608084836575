import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

export class Skill {
  id: number;
  name: string;
}

@Injectable({
  providedIn: 'root'
})
export class SkillService {

  constructor(private http: HttpClient) {
  }

  public findSkills(): Observable<Array<Skill>> {
    return this.http.get<Array<Skill>>('warehouse/api/skills');
  }
}
