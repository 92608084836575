<div class="padding-1">
  <div class="containerX">
    <div fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxFlex fxLayoutGap="1em"
      class="coloredContainerX box">
      <div fxFlex="250px" class="mat-elevation-z8 settings-menu-container">
        <div fxLayout="column" fxLayoutAlign="stretch" class="settings-menu-item">
          <div fxFlex class="settings-menu">
            <a routerLink="/settings/my-account" routerLinkActive="settings-menu-active">
              <mat-icon class="vertical-middle margin-right-2 icon">person</mat-icon>
              <span class="vertical-middle text">{{'settings.my-account.title' | translate}}</span>
            </a>
          </div>
          <div fxFlex class="settings-menu" *ngIf="isAdmin">
            <a routerLink="/settings/users" routerLinkActive="settings-menu-active">
              <mat-icon class="vertical-middle margin-right-2 icon">people</mat-icon>
              <span class="vertical-middle text">{{'settings.users.title' | translate}}</span>
            </a>
          </div>
          <div fxFlex class="settings-menu" *ngIf="isAdmin">
            <a routerLink="/settings/shops" routerLinkActive="settings-menu-active">
              <mat-icon class="vertical-middle margin-right-2 icon">business</mat-icon>
              <span class="vertical-middle text">{{'settings.shops.title' | translate}}</span>
            </a>
          </div>
          <div fxFlex class="settings-menu" *ngIf="isAdmin">
            <a routerLink="/settings/configuration" routerLinkActive="settings-menu-active">
              <mat-icon class="vertical-middle margin-right-2 icon">settings_application</mat-icon>
              <span class="vertical-middle text">{{'settings.configuration.title' | translate}}</span>
            </a>
          </div>
        </div>
      </div>
      <div fxFlex>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
