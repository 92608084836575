import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/auth/auth.service';
import { User } from 'src/app/shared/auth/user';
import { Router } from '@angular/router';
import { UserService } from '../../shared/auth/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  loggedUser: User;
  icon: string;

  constructor(private authService: AuthService, private router: Router, public userService: UserService) { }

  ngOnInit() {
    this.loggedUser = this.authService.currentUserValue;
    this.icon = this.authService.getIcon();
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
