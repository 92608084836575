import {Injectable} from '@angular/core';
import {HttpClient, HttpParams, HttpResponse, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {HttpResourcesResponse} from '../utils/HttpResourcesResponse.model';
import {CreateWarehouse, WarehouseView, UpdateWarehouse} from './warehouse.model';
import {map} from 'rxjs/operators';
import { Configuration, UpdateConfiguration } from './configuration.model';

@Injectable()
export class WarehouseService {

  constructor(private http: HttpClient) {
  }

  createWarehouse(createWarehouse: CreateWarehouse): Observable<void> {
    return this.http.post<void>('warehouse/api/warehouses', createWarehouse);
  }

  updateWarehouse(id: number, updateWarehouse: UpdateWarehouse): Observable<void> {
    return this.http.put<void>('warehouse/api/warehouses/' + String(id), updateWarehouse);
  }

  findWarehouse(warehouseId: number): Observable<WarehouseView> {
    return this.http.get<WarehouseView>('warehouse/api/warehouses/' + String(warehouseId));
  }

  deleteWarehouse(warehouseId: number): Observable<void> {
    return this.http.delete<void>('warehouse/api/warehouses/' + String(warehouseId));
  }

  findWarehouses(page: number, size: number): Observable<HttpResourcesResponse<WarehouseView>> {
    let p = new HttpParams();
    p = p.append('page', String(page));
    p = p.append('size', String(size));
    return this.http.get<HttpResourcesResponse<WarehouseView>>('warehouse/api/warehouses', { params: p });
  }

  getConfiguration(): Observable<Configuration> {
    return this.http.get<Configuration>('warehouse/api/configuration');
  }

  setConfiguration(configuration: UpdateConfiguration): Observable<void> {
    return this.http.put<void>('warehouse/api/configuration', configuration);
  }
}
