import {Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export class Chart {
  title: string;
  type: string;
  query: any;
}

@Injectable()
export class ChartService {

  constructor(private http: HttpClient) {
  }

  getCubeJsToken(): Observable<string> {
    return this.http.get('warehouse/api/configuration/cubejs', { responseType: 'text' });
  }

  getPlannedOrderByDateAndState(from: string, to: string, customerIds: string[]): Chart {
    console.log(from, to);
    const chart = new Chart();
    chart.title = 'reports.chart.nb-order-per-day';
    chart.type = 'stackedBar';
    chart.query = {
      filters: [
        { dimension: 'Vehicle.registrationNumber', operator: 'set' },
        { dimension: 'Order.parent', operator: 'notSet' },
        { dimension: 'ChildOrder.destinationId', operator: 'equals', values: customerIds }
      ],
      measures: [ 'Order.count'],
      dimensions: [ 'Order.liveState'],
      timeDimensions: [
        {
          dimension: 'Order.scheduledDate',
          dateRange: [from, to],
          granularity: 'day'
        }
      ],
      segments: [ 'Order.doneOrder' ]
    };
    return chart;
  }

  getTotalNumberOfDoneOrder(from: string, to: string, customerIds: string[]): Chart {
    const chart = new Chart();
    chart.type = 'singleValue';
    chart.title = 'reports.chart.nb-order-done';
    chart.query = {
      filters: [
        { dimension: 'Order.route', operator: 'set' },
        { dimension: 'Order.parent', operator: 'notSet' },
        { dimension: 'Order.scheduledDate', operator: 'inDateRange', values: [from, to]},
        { dimension: 'ChildOrder.destinationId', operator: 'equals', values: customerIds}
      ],
      measures: [ 'Order.count' ],
      timeDimensions: [ { dimension: 'Order.scheduledDate' } ],
      segments: [ 'Order.doneOrder' ]
    };
    return chart;
  }

}
