<div class="content-header" fxLayout="row" fxLayoutAlign="space-between center" style="margin-top: 2em;margin-bottom: 1em;">
  <div>
    <h1 style="margin: Opx !important;">{{'settings.users.title' | translate}}</h1>
  </div>
  <div>
    <button mat-raised-button class="button-blue" (click)="openCreateModal()">
      <mat-icon class="button-icon">add</mat-icon>{{'settings.users.add' | translate}}
    </button>
  </div>
</div>
<div style="clear:both;"></div>
<mat-divider></mat-divider>
<div class="padding-1">

  <table mat-table [dataSource]="data" class="mat-elevation-z8 full-width">
    <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->
    <!-- Position Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{ 'model.user.name' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{element.lastName}} {{element.firstName}} </td>
    </ng-container>

    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef> {{ 'model.user.role' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{'model.user.' + element.role | translate}} </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef> {{ 'model.user.email' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{element.email}} </td>
    </ng-container>

    <ng-container matColumnDef="phone">
      <th mat-header-cell *matHeaderCellDef> {{ 'model.user.phone-number' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
    </ng-container>

    <ng-container matColumnDef="shops">
        <th mat-header-cell *matHeaderCellDef> {{ 'model.user.shops' | translate | uppercase }} </th>
        <td mat-cell *matCellDef="let element">
            <span *ngIf="element.role === 'ADMIN'">{{ 'common.all' | translate | uppercase}}</span>
            <ng-container *ngIf="element.role === 'USER'" >
              <span *ngFor="let warehouseId of element.warehouses">{{getWarehouseName(warehouseId.id)}}<br></span>
            </ng-container>
        </td>
      </ng-container>


    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef> {{ 'common.table.action' | translate | uppercase }} </th>
      <td mat-cell *matCellDef="let element">

        <button mat-mini-fab class="button-blue margin-right-2" (click)="openUpdateModal(element.email)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-mini-fab class="button-red" (click)="deleteUser(element)">
              <mat-icon>delete</mat-icon>
            </button>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator class="mat-elevation-z8" [length]="resultsLength" [pageSize]="5" [pageSizeOptions]="[5, 10, 20]"
    showFirstLastButtons></mat-paginator>


</div>
