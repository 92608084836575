<div class="content-header">
  <div class="title">
    <h1>{{ 'reports.title' | translate}}</h1>
  </div>
  <div class="actions">
      <button mat-raised-button class="button-blue" routerLink="/transport-add"><mat-icon class="button-icon">calendar_today</mat-icon>{{ 'orders.book-transport' | translate }}</button>
  </div>
</div>
<div style="clear:both;"></div>
<mat-divider></mat-divider>
<div class="padding-1">
    <mat-form-field class="margin-right-2">
        <input matInput [matDatepicker]="min" placeholder="{{ 'common.from' | translate }}" [formControl]="minDate" (dateChange)="minDateChange()">
        <mat-datepicker-toggle matSuffix [for]="min"></mat-datepicker-toggle>
        <mat-datepicker #min></mat-datepicker>
    </mat-form-field>
    <mat-form-field>
        <input matInput [matDatepicker]="max" placeholder="{{ 'common.to' | translate }}" [formControl]="maxDate" (dateChange)="maxDateChange()">
        <mat-datepicker-toggle matSuffix [for]="max"></mat-datepicker-toggle>
        <mat-datepicker #max></mat-datepicker>
    </mat-form-field>
    <mat-card class="total-orders-card">
      <div *ngFor="let numberChart of numberCharts" [ngClass]="{
        'col-lg-3': numberChart.type === 'singleValue',
        'col-lg-6': numberChart.type !== 'singleValue'}">
        <ar-chart *ngIf="!loadingChart" [cubeJsToken]="cubeJsToken" [chartType]="numberChart.type" [query]="numberChart.query" [title]="numberChart.title"
          [isPickupDelivery]="true"></ar-chart>
      </div>
    </mat-card>
    <mat-card class="chart-orders-done-card">
      <div *ngFor="let chart of charts" [ngClass]="{
        'col-lg-3': chart.type === 'singleValue',
        'col-lg-6': chart.type !== 'singleValue'}">
        <ar-chart *ngIf="!loadingChart" [cubeJsToken]="cubeJsToken" [chartType]="chart.type" [query]="chart.query" [title]="chart.title"
          [isPickupDelivery]="true"></ar-chart>
      </div>
    </mat-card>
    <table mat-table [dataSource]="data" class="mat-elevation-z8">
      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> {{ 'common.table.date' | translate | uppercase }} </th>
        <td mat-cell *matCellDef="let element"> {{element.terminatedDate | date: 'dd/MM/yyyy' }} </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef> {{ 'common.table.type' | translate | uppercase }} </th>
        <td mat-cell *matCellDef="let element"> {{ 'common.table.' + element.serviceType.toLowerCase() | translate | uppercase }}</td>
      </ng-container>

      <ng-container matColumnDef="customer">
        <th mat-header-cell *matHeaderCellDef> {{ 'common.table.customer' | translate | uppercase }} </th>
        <td mat-cell *matCellDef="let element"> {{ element.customer.firstName }} {{ element.customer.lastName }} </td>
      </ng-container>

      <ng-container matColumnDef="address">
        <th mat-header-cell *matHeaderCellDef> {{ 'common.table.address' | translate | uppercase }} </th>
        <td mat-cell *matCellDef="let element"> {{element.customer.address}} </td>
      </ng-container>

      <ng-container matColumnDef="satisfaction">
        <th mat-header-cell *matHeaderCellDef> {{ 'common.table.satisfaction' | translate | uppercase }} </th>
        <td mat-cell *matCellDef="let element">
          <star-rating *ngIf="element.customerReview" [starType]="'svg'" [readOnly]="true" [rating]="element.customerReview.rating"></star-rating></td>
      </ng-container>

      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef> {{ 'common.table.time' | translate | uppercase }} </th>
        <td mat-cell *matCellDef="let element"> {{ 'model.order.terminated-date' | translate }}: {{element.terminatedDate | date:'HH\'h\'mm'}} </td>
      </ng-container>

      <ng-container *ngFor="let customField of customFields" matColumnDef="{{customField.name}}">
          <th mat-header-cell *matHeaderCellDef> {{ customField.name | uppercase }} </th>
          <td mat-cell *matCellDef="let element">
              {{getFieldValue(element, customField)}}
          </td>
      </ng-container>


      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'common.table.action' | translate | uppercase }}
          <button mat-icon-button (click)="configureTable()">
              <mat-icon>settings_applications</mat-icon>
          </button>
        </th>
        <td mat-cell *matCellDef="let element">
          <button mat-mini-fab class="button-blue" (click)="goToDetails(element)"><mat-icon>visibility</mat-icon></button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goToDetails(row)"></tr>
    </table>
      <mat-paginator class="mat-elevation-z8"  [length]="resultsLength"  [pageSize]="10" [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>
