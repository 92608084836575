<div class="customer-details">

    <div fxLayout="column">
      <div fxFlex>
        <span *ngIf="order.serviceType !== serviceType" class="name"> {{customer.lastName}} {{customer.firstName}}</span>
        <span *ngIf="order.serviceType === serviceType" class="name-clickable" [routerLink]="['/customers/', customer.customerId]"> {{customer.lastName}} {{customer.firstName}}</span>
      </div>
      <div fxFlex fxHide.sm>
          <span class="phone">{{ getPhoneNumber() }}</span><br>
          <span class="address" *ngIf="displayAddress()"> {{customer.address}}</span>
        </div>
    </div>




</div>
