import { Component, OnInit, Input } from '@angular/core';
import { CubejsClient, CubejsClientModule } from '@cubejs-client/ngx';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ChartService } from './chart.services';
import { ResultSet } from '@cubejs-client/core';

const cubejsOptions = {
  token:
    '',
  options: {
    apiUrl: '/cubejs-api/v1'
  }
};

@Component({
  selector: 'ar-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  @Input() chartType;
  @Input() query;
  @Input() title;
  @Input() isPickupDelivery: boolean;
  @Input() cubeJsToken: string;

  private cubejs: CubejsClient;

  constructor(private translateService: TranslateService, private chartService: ChartService) {
  }

  public chartData;
  public chartLabels;
  public chartOptions: any = {
    responsive: true,
    maintainAspectRatio: false
  };
  ready = false;
  showChart = false;

  public chartColors;
  public lineChartColors = [
    { borderColor: '#7DB3FF', backgroundColor: '#7DB3FF' },
    { borderColor: 'rgba(200,230,201,0.9)', backgroundColor: 'rgba(200,230,201,0.9)' }];

  public pieChartColors = [
    {
      backgroundColor: [
        '#7DB3FF',
        '#49457B',
        '#FF7C78',
        '#FED3D0',
        '#6F76D9',
        '#9ADFB4',
        '#2E7987'
      ]
    }
  ];

  private querySubject;

  private numberFormatter(x: number): string {
    if (x === null) {
      return '0';
    }
    return x.toLocaleString('fr-FR', {maximumFractionDigits: 1});
  }
  private dateFormatter = ({ x }) => moment(x).format('DD/MM');


  private round(x: number): number {
    if (!x || x === null) {
      return 0;
    }
    return Number(x.toFixed(1));
  }

  commonSetup(resultSet: ResultSet) {
    this.chartLabels = resultSet.chartPivot().map(this.dateFormatter);
    this.chartData = resultSet.seriesNames().map(({ key, title }) => ({
      data: resultSet.chartPivot().map(element => this.round(element[key])),
      label: this.getLabel(title.split(',')[0])
    }));
  }

  setLineChartData() {
    this.chartOptions = {
      ...this.chartOptions,
      scales: {
        xAxes: [
          {
            ticks: {
              maxTicksLimit: 4,
              maxRotation: 0
            }
          }
        ]
      },
      legend: {
        position: 'top'
      },
      elements: {
        line: {
          fill: false
        }
      }
    };
    this.chartColors = this.lineChartColors;
  }

  setPieChartData() {
    this.chartColors = this.pieChartColors;
  }

  setStackedBarChartData(resultSet: ResultSet) {
    this.chartType = 'bar';
    const colors = [];
    resultSet.seriesNames().map(({ key, title }) => title.split(',')[0]).forEach(l => {
      colors.push({ backgroundColor: this.getColor(l) });
    });
    this.chartColors = colors;
    this.chartOptions = {
      ...this.chartOptions,
      scales: {
        xAxes: [
          {
            stacked: true
          }
        ],
        yAxes: [{
          ticks: {
            precision:0
          },
          stacked: true
        }],
      },
      legend: {
        position: 'top'
      }
    };
  }

  resultChanged(resultSet: ResultSet) {
    this.commonSetup(resultSet);
    if (this.chartType === 'line') {
      this.setLineChartData();
    } else if (this.chartType === 'pie') {
      this.setPieChartData();
    } else if (this.chartType === 'stackedBar') {
      this.setStackedBarChartData(resultSet);
    } else if (this.chartType === 'singleValue') {
      if ( resultSet.chartPivot()[0][resultSet.seriesNames()[0].key] !== undefined) {
        this.chartData = this.numberFormatter(
          resultSet.chartPivot()[0][resultSet.seriesNames()[0].key]
        );
      }
    }
    this.ready = true;
  }

  ngOnInit() {
    cubejsOptions.token = this.cubeJsToken;
    CubejsClientModule.forRoot(cubejsOptions);
    this.cubejs = new CubejsClient(cubejsOptions);

    this.showChart = this.chartType !== 'singleValue';
    this.querySubject = new Subject();
    this.resultChanged = this.resultChanged.bind(this);

    this.cubejs
      .watch(this.querySubject)
      .subscribe(this.resultChanged, err => console.log(err));
    this.querySubject.next(this.query);
  }

  getColor(stackLabel: string): string {
    if (stackLabel === 'DN') {
      return 'rgba(200,230,201,0.9)';
    } else if (stackLabel === 'PL') {
      return 'rgba(187,222,251,0.9)';
    } else if (stackLabel === 'CL') {
      return 'rgba(255,205,210,0.9)';
    } else {
      return 'rgba(187,222,251,0.9)';
    }
  }

  getTitle(): string {
    if (this.isPickupDelivery) {
      const key = this.title + '.pickup';
      const translation = this.translateService.instant(key);
      if (key !== translation) {
        return translation;
      }
    }
    return this.translateService.instant(this.title);
  }

  getLabel(label: string): string {
    if (label.includes('#')) {
      const keyLabel = label.substring(label.indexOf('#') + 1, label.lastIndexOf('#'));
      return this.translateService.instant('reports.chart.label.' + keyLabel);

    } else {
      return this.translateService.instant('reports.chart.label.' + label);
    }
  }
}
