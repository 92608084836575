import { Injectable } from '@angular/core';
import { HttpResourcesResponse } from '../utils/HttpResourcesResponse.model';
import { Observable } from 'rxjs';
import { CustomerView } from './customer.model';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { LightOrder } from '../orders/light-order.model';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  findAllCustomers(page: number, size: number, filter: string): Observable<HttpResourcesResponse<CustomerView>> {
    let  p = new HttpParams();
    p = p.append('page', String(page));
    p = p.append('size', String(size));
    p = p.append('filter', filter);
    return this.http.get<HttpResourcesResponse<CustomerView>>('warehouse/api/customers', {params : p});
  }

  findCustomer(customerId: number): Observable<CustomerView> {
    return this.http.get<CustomerView>('warehouse/api/customers/' + String(customerId));
  }

  findAllLightOrdersForACustomer(customerId: number, page: number, size: number): Observable<HttpResourcesResponse<LightOrder>> {
    let  p = new HttpParams();
    p = p.append('page', String(page));
    p = p.append('size', String(size));
    return this.http.get<HttpResourcesResponse<LightOrder>>('warehouse/api/customers/' + String(customerId) + '/orders', {params : p});
  }

  addCustomer(customer: CustomerView): Observable<HttpResponse<void>> {
    return this.http.post<void>('warehouse/api/customers/', customer,  {observe: 'response'});
  }

  editCustomer(customer: CustomerView) {
    return this.http.put('warehouse/api/customers/' + customer.customerId, customer);
  }

}
