import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginLayoutComponent} from './login/login-layout.component';
import {LoginComponent} from './login/login.component';
import {HomeLayoutComponent} from './warehouse/home-layout.component';
import {AuthGuard} from './shared/auth/auth.guard';
import {TransportComponent} from './warehouse/transport/transport.component';
import {SettingsComponent} from './warehouse/settings/settings.component';
import {CustomersComponent} from './warehouse/customers/customers.component';
import {UsersComponent} from './warehouse/settings/users/users.component';
import {ShopsComponent} from './warehouse/settings/shops/shops/shops.component';
import {CustomersDetailsComponent} from './warehouse/customers/customers-details/customers-details.component';
import {MyAccountComponent} from './warehouse/settings/my-account/my-account.component';
import {ReportsComponent} from './warehouse/reports/reports.component';
import {DetailsComponent} from './shared/details/details.component';
import {TransportAddEditComponent} from './warehouse/transport/transport-add-edit/transport-add-edit.component';
import { ConfigurationComponent } from './warehouse/settings/configuration/configuration.component';

const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'transport',
        component: TransportComponent
      },
      {
        path: 'transport/:orderId',
        component: DetailsComponent
      },
      {
        path: 'transport/:orderId/edit',
        component: TransportAddEditComponent
      },
      {
        path: 'transport-add',
        component: TransportAddEditComponent
      },
      {
        path: 'customers',
        component: CustomersComponent
      },
      {
        path: 'customers/:customerId',
        component: CustomersDetailsComponent
      },
      {
        path: 'customers/:customerId/order/:orderId',
        component: DetailsComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'reports/:orderId',
        component: DetailsComponent
      },
      {
        path: '',
        component: SettingsComponent,
        children: [
          {
            path: 'settings/my-account',
            component: MyAccountComponent
          },
          {
            path: 'settings/users',
            component: UsersComponent
          },
          {
            path: 'settings/shops',
            component: ShopsComponent
          },
          {
            path: 'settings/configuration',
            component: ConfigurationComponent
          }
        ]
      }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
