export type TRANSPORT_PROCESS = 'BUCKET' | 'SCORING';
export const BUCKET: TRANSPORT_PROCESS = 'BUCKET';
export const SCORING: TRANSPORT_PROCESS = 'SCORING';

export enum DisplayField {
  WAREHOUSE_ADDRESS = 'WAREHOUSE_ADDRESS',
  WAREHOUSE_TIMESLOT = 'WAREHOUSE_TIMESLOT',
  WAREHOUSE_DURATION = 'WAREHOUSE_DURATION',
  CUSTOMER_DELIVERY_DURATION = 'CUSTOMER_DELIVERY_DURATION',
  CUSTOMER_PICKUP_DURATION = 'CUSTOMER_PICKUP_DURATION',
  ORDER_TYPE = 'ORDER_TYPE'
}

export class Configuration {
  iconUrl: string;
  customFieldIdForOrderNumber: number;
  process: TRANSPORT_PROCESS;
  displaySettings: DisplaySetting[];
  scoringNbDayToScore: number;
  scoringBeginDay: number;
  scoringMinimumScore: number;
  terminology: string;
  storeAsDefaultLoading: boolean;
  enableItems: boolean;
  basketPeriodInDays: number;
}

export class UpdateConfiguration {
  iconUrl: string;
  customFieldIdForOrderNumber: number;
  displaySettings: UpdateDisplaySetting[];
  scoringNbDayToScore: number;
  scoringBeginDay: number;
  scoringMinimumScore: number;
}

export class UpdateDisplaySetting {
  field: DisplayField;
  visible: boolean;
  required: boolean;
  defaultValue: string;
}

export class BooleanDisplayRule {
  value: boolean;
  editable: boolean;
}

export class StringDisplayRule {
  value: string;
  editable: boolean;
}

export class DisplaySetting {
  field: DisplayField;
  visible: BooleanDisplayRule;
  required: BooleanDisplayRule;
  defaultValue: StringDisplayRule;
}
